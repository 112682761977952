<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: "TextContent"
})
</script>

<template>
  <div class="text-content">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.text-content {
  padding: 20px;
  border: 1px solid var(--dark7);
  background-color: var(--dark5);
  border-radius: 8px;
  color: var(--gray5);
  font-size: 14px;
  font-weight: 500;
  line-height: 130%;
  text-align: left;
  box-shadow: 0px 8px 12px 0px rgba(20, 21, 24, 0.15);

  @include media-lg() {
    padding: var(--mobile-content-padding);
  }

  &:deep {
    h2 {
      color: var(--white);
      font-family: var(--secondary-font);
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 12px;
    }
    & > div{
      padding-left: 15px;
      padding-bottom: 10px;
    }
    strong{
      color: var(--white);
      padding-right: 5px;
    }
    ul {
      list-style-type: square;
      margin-top: 0;
      padding-left: 20px;
    }
  }
}
</style>
