<script>
export default {
  name: "SearchLoader",
  props: {
    text: String
  }
}
</script>

<template>
  <div class="search-result">
    <div class="search-loader">{{ text }} <span>.</span><span>.</span><span>.</span></div>
  </div>
</template>


<style lang="scss" scoped>
.search-result {
  width: 100%;
  text-align: left;
}
.search-loader {
  font-size: 18px;
  font-weight: bold;
  padding-left: 20px;

  > span {
    font-size: 50px;
    line-height: 18px;
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    &:nth-child(2) {
      animation-delay: .2s;
    }
    &:nth-child(3) {
      animation-delay: .4s;
    }
  }
  @keyframes blink {
    0% { opacity: .2; }
    20% { opacity: 1; }
    100% { opacity: .2; }
  }
}
</style>
