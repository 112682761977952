<script>
export default {
  name: "SocialLinks",
}
</script>

<template>
  <div class="social">
    <a class="social__link" title="Telegram" href="https://t.me/windice" target="_blank">
      <SvgIcon name="telegram" size="20" class="social__icon" />
    </a>
    <a class="social__link" title="Twitter" href="https://twitter.com/windiceio" target="_blank">
      <SvgIcon name="twitter" size="20" class="social__icon" />
    </a>
    <a class="social__link" title="VK" href="https://vk.com/windiceio" target="_blank">
      <SvgIcon name="vk" size="20" class="social__icon" />
    </a>
    <a class="social__link" title="Github" href="https://github.com/windiceio" target="_blank">
      <SvgIcon name="github" size="20" class="social__icon" />
    </a>
  </div>
</template>

<style lang="scss" scoped>
.social {
  --size: 36px;

  display: flex;
  flex-wrap: wrap;
  padding-left: 4px;
}
.social__link {
  color: var(--gray4);
  border-radius: 8px;
  background: var(--gray);
  display: flex;
  width: var(--size);
  height: var(--size);
  justify-content: center;
  align-items: center;
  margin-right: 8px;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    opacity: 0.8;
  }
}
.social__icon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
</style>
