<script>
import TourneyCard from './TourneyCard.vue'

export default {
  name: "Tourneys",
  components: {
    TourneyCard,
  },
  data() {
    return {
      ticker: 0,
    }
  },
  computed: {
    contestBalanceDay: function() {
      return this.$store.getters.getContestBalanceDay
    },
    contestWageringDay: function() {
      return this.$store.getters.getContestWageringDay
    },
    contestWageringWeek: function() {
      return this.$store.getters.getContestWageringWeek
    },

    contestBalanceDayPrize: function() {
      if (this.contestBalanceDay !== null) {
        return parseFloat((this.contestBalanceDay.prize / 1000).toFixed(5))
      }
      return 0
    },
    contestWageringDayPrize: function() {
      if (this.contestWageringDay !== null) {
        return parseFloat((this.contestWageringDay.prize / 1000).toFixed(5))
      }
      return 0
    },
    contestWageringWeekPrize: function() {
      if (this.contestWageringWeek !== null) {
        return parseFloat((this.contestWageringWeek.prize / 1000).toFixed(5))
      }
      return 0
    },

    timerBalanceDay: function() {
      if (this.contestBalanceDay !== null) {
        return this.contestBalanceDay.time_end
      }
      return 0
    },
    timerWageringDay: function() {
      if (this.contestWageringDay !== null) {
        return this.contestWageringDay.time_end
      }
      return 0
    },
    timerWageringWeek: function() {
      if (this.contestWageringWeek !== null) {
        return this.contestWageringWeek.time_end
      }
      return 0
    },

    timerBalanceDayPrint: function() {
      if (this.timerBalanceDay > 0) {
        return this.getTimerPrint(this.timerBalanceDay, this.ticker)
      }
      return '00:00:00'
    },
    timerWageringDayPrint: function() {
      if (this.timerWageringDay > 0) {
        return this.getTimerPrint(this.timerWageringDay, this.ticker)
      }
      return '00:00:00'
    },
    timerWageringWeekPrint: function() {
      if (this.timerWageringWeek > 0) {
        return this.getTimerPrint(this.timerWageringWeek, this.ticker)
      }
      return '00:00:00'
    },
    // Winners
    winnersDailyDraw() {
      return [
        { id: 1, photo: 'https://i.ibb.co/1QNcxLv/avatar.png', level: 33, name: 'Arlene McCoy', rating: 2.7182 },
        { id: 2, photo: 'https://i.ibb.co/1QNcxLv/avatar.png', level: 33, name: 'Arlene McCoy', rating: 2.7182 },
        { id: 3, photo: 'https://i.ibb.co/1QNcxLv/avatar.png', level: 33, name: 'Arlene McCoy', rating: 2.7182 }
      ]
    }
  },
  created() {
    this.ticker = Math.round((new Date()).getTime() / 1000)

    setInterval(() => {
      this.ticker = Math.round((new Date()).getTime() / 1000)
    }, 1000)
  },
  methods: {
    getTimerPrint: function(timer, ticker) {
      let time = timer - ticker

      if (time <= 0) {
        return '00:00:00'
      }

      let ret = ''
      const days = Math.floor(time / (24 * 60 * 60))
      let hours = Math.floor((time - (days * 24 * 60 * 60)) / (60 * 60))
      let minutes = Math.floor((time - (days * 24 * 60 * 60) - (hours * 60 * 60)) / 60)
      let seconds = time - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60)

      if (hours < 10) {
        hours = "0" + hours
      }
      if (minutes < 10) {
        minutes = "0" + minutes
      }
      if (seconds < 10) {
        seconds = "0" + seconds
      }

      if (days > 0) {
        ret += days + ' '
      }
      ret += hours + '<span>:</span>'
      ret += minutes + '<span>:</span>'
      ret += seconds

      return ret
    },
  }
}
</script>

<template>
<section class="tourneys">
  <header class="tourneys__header">
    <h2 class="tourneys__title">{{ $t('home.tourneys') }}</h2>
  </header>

  <div class="tourneys__scroll">
    <div class="tourneys__scroll-content">
      <TourneyCard
          v-if="contestWageringDay"
          to="/contest/daily"
          :title="$t('contest.wager_daily')"
          :prize="contestWageringDayPrize"
          :currency="contestWageringDay?.curr"
          :winners="winnersDailyDraw"
          class="tourneys__card tourneys__card--daily"
      >
        <template #timer>
          <div v-html="timerWageringDayPrint" />
        </template>
      </TourneyCard>

      <TourneyCard
          v-if="contestWageringWeek"
          to="/contest/weekly"
          :title="$t('contest.wager_weekly')"
          :prize="contestWageringWeekPrize"
          :currency="contestWageringWeek?.curr"
          :winners="winnersDailyDraw"
          class="tourneys__card tourneys__card--weekly"
      >
        <template #timer>
          <div v-html="timerWageringWeekPrint" />
        </template>
      </TourneyCard>

      <TourneyCard
          v-if="contestBalanceDay"
          to="/contest/daily-win"
          :title="$t('contest.wincoin_daily')"
          :prize="contestBalanceDayPrize"
          :currency="contestBalanceDay?.curr"
          class="tourneys__card tourneys__card--wincoin"
      >
        <template #timer>
          <div v-html="timerBalanceDayPrint" />
        </template>
      </TourneyCard>
    </div>
  </div>
</section>
</template>

<style lang="scss" scoped>
$offset: 12px;
$card-height-mobile: 129px;

.tourneys__header {
  text-align: left;
  margin-bottom: 20px;

  @include media-lg() {
    padding-left: var(--mobile-content-padding);
    padding-right: var(--mobile-content-padding);
  }
}
.tourneys__title {
  color: var(--white);
  font-family: var(--secondary-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;


  @include media-lg() {
    font-size: 14px;
  }
}

.tourneys__scroll {
  @include media-lg() {
    margin-bottom: 24px;
    overflow: hidden;
    min-height: $card-height-mobile;
  }
}
.tourneys__scroll-content {
  display: grid;
  gap: $offset;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-content: center;

  @include media-lg() {
    text-align: left;
    display: inline-block;
    white-space: nowrap;
    overflow-x: scroll;
    vertical-align: top;
    width: 100vw;
    padding: 0 var(--mobile-content-padding);
    margin-bottom: 0;
    padding-bottom: var(--mobile-content-padding);
  }
}

.tourneys__card {
  width: 100%;

  @include media-lg() {
    width: 324px;
    min-height: 129px;
    margin-right: 8px;
    margin-bottom: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
}
.tourneys__card--daily {
  background-image: url("@/assets/home/tourneys/daily.svg");
  background-color: #1357A8;

  @include media-md() {
    background-image: url("@/assets/home/tourneys/daily_mobile.svg");
    background-position: right top;
  }

  background-position: right bottom;
  //background-size: contain;
  background-repeat: no-repeat;

}
.tourneys__card--weekly {
  background: #E5608D;
  background-image: url("@/assets/home/tourneys/weekly.svg");
  background-position: right 0 top 20px;
  background-size: auto;
  background-repeat: no-repeat;

  @include media-xl() {
    background-position: right -30px top 20px;
  }
}
.tourneys__card--wincoin {
  background: #65479F;
  background-image: url("@/assets/home/tourneys/wincoin.svg");
  background-position: right 12px bottom 13px;
  background-size: 235px 141px;
  background-repeat: no-repeat;

  @include media-xl() {
    background-position: right -16px bottom 0;
    background-size: 186px 126px;
  }
}
</style>
