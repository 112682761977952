const moduleShop = {
  state: {
    shop: {
      painter: "",
      decor: "",
      gift: "",
      booster_exp: 0,
      booster_faucet: 0,
    },
    shopItems: [],
    shopCategoryNames: {
      'coins':      {icon: 'coins'},
      'exp':        {icon: 'crown'},
      'other':      {icon: 'user-tag'},
      'decors':     {icon: 'user-circle'},
      'gifts':      {icon: 'gift'},
      'painters':   {icon: 'user-edit'},
      'exp_boost':  {icon: 'fighter-jet'},
      'faucet':     {icon: 'flask'}
    },
    shopItemNames: {
      'coin0':  {icon: 'shop_coin0',  name: 'SMALL',  cat: 'WINCOIN'},
      'coin1':  {icon: 'shop_coin1',  name: 'MEDIUM', cat: 'WINCOIN'},
      'coin2':  {icon: 'shop_coin2',  name: 'BIG',    cat: 'WINCOIN'},
      'coin3':  {icon: 'shop_coin3',  name: 'EXTRA',  cat: 'WINCOIN'},
      'exp0':   {icon: 'shop_exp0',   name: 'SMALL',  cat: 'EXP'},
      'exp1':   {icon: 'shop_exp1',   name: 'MEDIUM', cat: 'EXP'},
      'exp2':   {icon: 'shop_exp2',   name: 'BIG',    cat: 'EXP'},
      'exp3':   {icon: 'shop_exp3',   name: 'EXTRA',  cat: 'EXP'},
      'nick':   {icon: 'shop_nick',   name: 'CHANGE', cat: 'NICKNAME'},

      'santa':    {icon: 'shop_avatar_santa',     name: 'SANTA',    cat: 'STYLE'},
      'beard':    {icon: 'shop_avatar_beard',     name: 'BEARD',    cat: 'STYLE'},
      'indigo':   {icon: 'shop_avatar_indigo',    name: 'INDIGO',   cat: 'STYLE'},
      'royal':    {icon: 'shop_avatar_royal',     name: 'ROYAL',    cat: 'STYLE'},
      'devil':    {icon: 'shop_avatar_devil',     name: 'DEVIL',    cat: 'STYLE'},
      'angel':    {icon: 'shop_avatar_angel',     name: 'ANGEL',    cat: 'STYLE'},
      'richy':    {icon: 'shop_avatar_richy',     name: 'RICHY',    cat: 'STYLE'},
      'flu_mask': {icon: 'shop_avatar_flu_mask',  name: 'FLU MASK', cat: 'STYLE'},
      'pirate':   {icon: 'shop_avatar_pirate',    name: 'PIRATE',   cat: 'STYLE'},
      'military': {icon: 'shop_avatar_military',  name: 'MILITARY', cat: 'STYLE'},

      'cigarette':  {icon: 'shop_gift_cigarette',   name: 'CIGARETTE',  cat: 'GIFT'},
      'donut':      {icon: 'shop_gift_donut',       name: 'DONUT',      cat: 'GIFT'},
      'candy':      {icon: 'shop_gift_candy',       name: 'CANDY',      cat: 'GIFT'},
      'gold':       {icon: 'shop_gift_gold',        name: 'GOLD',       cat: 'GIFT'},
      'duck':       {icon: 'shop_gift_duck',        name: 'DUCK',       cat: 'GIFT'},
      'beer':       {icon: 'shop_gift_beer',        name: 'BEER',       cat: 'GIFT'},
      'sunglasses': {icon: 'shop_gift_sunglasses',  name: 'SUNGLASSES', cat: 'GIFT'},
      'cash':       {icon: 'shop_gift_cash',        name: 'CASH',       cat: 'GIFT'},
      'shoe':       {icon: 'shop_gift_shoe',        name: 'SHOE',       cat: 'GIFT'},

      'violet': {icon: 'shop_color_violet', name: 'VIOLET', cat: 'NICKNAME'},
      'mint':   {icon: 'shop_color_mint',   name: 'MINT',   cat: 'NICKNAME'},
      'orange': {icon: 'shop_color_orange', name: 'ORANGE', cat: 'NICKNAME'},
      'rose':   {icon: 'shop_color_rose',   name: 'ROSE',   cat: 'NICKNAME'},
      'red':    {icon: 'shop_color_red',    name: 'RED',    cat: 'NICKNAME'},
      'lime':   {icon: 'shop_color_lime',   name: 'LIME',   cat: 'NICKNAME'},
      'yellow': {icon: 'shop_color_yellow', name: 'YELLOW', cat: 'NICKNAME'},
      'blue':   {icon: 'shop_color_blue',   name: 'BLUE',   cat: 'NICKNAME'},
      'creme':  {icon: 'shop_color_creme',  name: 'CREME',  cat: 'NICKNAME'},
      'coral':  {icon: 'shop_color_coral',  name: 'CORAL',  cat: 'NICKNAME'},

      'boost0':   {icon: 'shop_bomb',       name: 'HAND BOMB',    cat: 'EXP BOOST'},
      'boost1':   {icon: 'shop_boot',       name: 'FLYING SHOES', cat: 'EXP BOOST'},
      'boost2':   {icon: 'shop_lightning',  name: 'LIGHTNING',    cat: 'EXP BOOST'},
      'boost3':   {icon: 'shop_horseshoe',  name: 'HORSESHOES',   cat: 'EXP BOOST'},
      'faucet0':  {icon: 'shop_candy',      name: 'SWEET CANDY',  cat: 'FAUCET BOOST'},
      'faucet1':  {icon: 'shop_bottle',     name: 'SPEED POTION', cat: 'FAUCET BOOST'},
    },
  },
  getters: {
    getShop: state => state.shop,
    getShopItems: state => state.shopItems,
    getShopDecor: state => state.shop.decor,
    getShopGift: state => state.shop.gift,
    getBoosterFaucet: state => state.shop.booster_faucet,
    getShopCategoryNames: state => state.shopCategoryNames,
    getShopItemNames: state => state.shopItemNames,
  },
  mutations: {
    setShop: (state, data) => {
      state.shop = data
    },
    setShopItems: (state, data) => {
      state.shopItems = data
    }
  },
  actions: {
    socket_shop: (context, data) => {
      //console.log('shop_load', data)
      context.commit('setShop', data.data)
      context.commit('setShopItems', data.items)
    }
  }
}

export default moduleShop
