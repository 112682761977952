<script>
import WinCard from './WinCard.vue'
import TabNav from './TabNav.vue'
import WinStats from './WinStats.vue'
import SectionSlider from "@/components/pages/home_redesign/SectionSlider.vue";
import {tabs, defaultActiveTab} from "@/components/pages/home_redesign/last-wins/tabs";

export default {
  name: "LastWins",
  components: {
    SectionSlider,
    WinCard,
    TabNav,
    WinStats,
  },
  data() {
    return {
      tab: defaultActiveTab,

      // TODO change to real data
      FAKE_LIST: Array(10).fill(0),
      FAKE_LIST2: Array(3).fill(0),
    }
  },
  computed: {
    list() {
      return this.tab === tabs.top ? this.FAKE_LIST : this.FAKE_LIST2
    }
  },
  methods: {
    changeTab(tab) {
      this.tab = tab
    }
  }
}
</script>

<template>
  <SectionSlider
      :list="list"
      class="last-wins"
  >
    <template #headerLeft>
      <TabNav @change="changeTab" :activeTab="tab" />
    </template>

    <template #headerCenter>
      <WinStats class="last-wins__stats-desktop" />
    </template>

    <template #default="{ item }">
      <div>
        <WinCard
          fullGameName="Fake data"
          username="Fake data"
          amount="100"
        />
      </div>
    </template>

    <template #footer>
      <div class="show-on-mobile">
        <div class="last-wins__scroll">
          <div class="last-wins__scroll-content">
            <WinStats class="last-wins__stats-mobile" />
          </div>
        </div>
      </div>
    </template>
  </SectionSlider>
</template>

<style lang="scss" scoped>
.last-wins {
  &:deep {
    .swiper-slide {
      margin-right: 12px;
    }
  }
}
.last-wins__stats-desktop {
  margin-left: auto;
  margin-right: 10px;

  @include media-lg() {
    display: none;
  }
}
.show-on-mobile {
  display: none;

  @include media-lg() {
    display: block;
  }
}
.last-wins__scroll {
  @include media-lg() {
    overflow: hidden;
    height: 20px;
    margin-top: 18px;
  }
}
.last-wins__scroll-content {
  @include media-lg() {
    text-align: left;
    display: inline-block;
    white-space: nowrap;
    overflow-x: scroll;
    vertical-align: top;
    width: 100vw;
    padding: 0 var(--mobile-content-padding);
    margin-bottom: 0;
    padding-bottom: var(--mobile-content-padding);
  }
}
.last-wins__stats-mobile {
  display: block;

  &:deep {
    .stats__block {
      &:last-of-type {
        margin-right: var(--mobile-content-padding);
      }
    }
  }
}
</style>
