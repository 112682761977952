<script>
import GameCard from "@/components/casino_redesign/game-card/GameCard.vue";
import SearchLoader from '../SearchLoader.vue'

export default {
  name: "EmptySearch",
  components: {GameCard, SearchLoader},
  props: ["searchHistory", "top",
    'isLoadingPopularSearch',
    'popularSearchList',
    'popularSearchError',
    'isLoadingTopGame',
    'topGameList',
    'topGameError',
  ],
  emits: ['text'],
  methods: {
    enterText(text) {
      this.$emit('text', text)
    },

  }
}
</script>

<template>
  <div class="search-suggestions">
    <div v-if="searchHistory.length || top.length" class="search-suggestions__links search__result-category">
      <div v-if="searchHistory.length > 0" class="search-suggestions__history">
        <div class="search__result-title">{{ $t('casino.search_recent') }}</div>
        <ul class="search-suggestions__list">
          <li v-for="(item, key) in searchHistory" :key="key">
            <a @click.prevent="enterText(item)" class="search-link"><font-awesome-icon icon="history"/> {{ item }}</a>
          </li>
        </ul>
      </div>
      <div v-if="top.length > 0"  class="search-suggestions__popular">
        <div class="search__result-title">{{ $t('casino.search_popular') }}</div>
        <ul class="search-suggestions__list">
          <li v-for="(item, key) in top" :key="key">
            <a @click.prevent="enterText(item)" class="search-link"><font-awesome-icon icon="fire"/> {{ item }}</a>
          </li>
        </ul>
      </div>
    </div>

    <!-- 2. popular_searches -->
    <div class="search__result-category">
      <div class="search__result-title-wp">
        <localized-link class="search__result-title" :to="{name: 'CasinoCategory', params: {category: 'popular'}}">
          {{ $t(`casino.search_popular`) }}
        </localized-link>
      </div>

      <SearchLoader v-if="isLoadingPopularSearch" :text="$t('casino.loading')" />
      <div v-else-if="!popularSearchError" class="search-cards" >
        <div v-for="(game, key) in popularSearchList" :key="key">
          <GameCard :game="game" />
        </div>
      </div>
    </div>

    <!-- 3. top games -->
    <div class="search__result-category">
      <div class="search__result-title-wp">
        <localized-link class="search__result-title" :to="{name: 'CasinoCategory', params: {category: 'popular'}}">
          {{ $t(`casino.top_games`) }}
        </localized-link>
      </div>

      <div class="search-cards" >
        <div v-for="(game, key) in topGameList" :key="key">
          <GameCard :game="game" />
        </div>
      </div>
    </div>

  </div>
</template>



<style lang="scss" scoped>
.search-suggestions {
  width: 100%;
}

.search-suggestions__list {
  columns: 2;
  column-gap: 6px;

  @include media-lg() {
    columns: 1;
  }
}

.search-suggestions__links {
  display: flex;

  > div {
    text-align: left;
    font-weight: bold;
    &:first-of-type {
      margin-right: 40px;
    }
  }
}
.search-link {
  padding-left: 5px;
  color: var(--primary-light);
  transition: .3s ease;
  &:hover {
    color: var(--white);
  }
}
.search__result-title-wp {
  text-align: left;
}
.search__result-category {
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }
}
</style>
