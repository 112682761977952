const moduleVip = {
  state: {
    levelsName: ['Newbie', "Copper", "Bronze", "Silver", "Gold", "Platinum", "Diamond", "Diamond I", "Diamond II", "Diamond III", "Diamond IV"],
    levelsMedal: ['newbie', "copper", "bronze", "silver", "gold", "platinum", "diamond", "diamond", "diamond", "diamond", "diamond"],
    levelsUp: [1, 1000, 30000, 100000, 500000, 2300000, 5300000, 8300000, 11300000, 14300000],

    levels: [
      0, // newbie
      1, 5, 25, 75, 100, 200, 300, 400, 500, 600, // copper 1-10
      1000, 2000, 3000, 5000, 7000, 10000, 13000, 16000, 19000, 22000, // bronze 11-20
      30000, 35000, 40000, 45000, 50000, 55000, 60000, 65000, 70000, 75000, // silver + vip tag 21-30
      100000, 125000, 150000, 175000, 200000, 225000, 250000, 275000, 300000, 350000, // gold 31-40
      500000, 600000, 700000, 800000, 900000, 1000000, 1100000, 1200000, 1300000, 1500000, // platinum 41-50
      2300000, 2600000, 2900000, 3200000, 3500000, 3800000, 4100000, 4400000, 4700000, 5000000, // diamond 51-60

      // 61-70
      5300000, 5600000, 5900000, 6200000, 6500000, 6800000, 7100000, 7400000, 7700000, 8000000,
      // 71-80
      8300000, 8600000, 8900000, 9200000, 9500000, 9800000, 10100000, 10400000, 10700000, 11000000,
      // 81-90
      11300000, 11600000, 11900000, 12200000, 12500000, 12800000, 13100000, 13400000, 13700000, 14000000,
      // 91-100
      14300000, 14600000, 14900000, 15200000, 15500000, 15800000, 16100000, 16400000, 16700000, 17000000,
    ],
    bonusDaily: false,
    bonusDailyPrize: [],
    bonusDailyDay: 0,
  },
  getters: {
    vipLevelsName: state => state.levelsName,
    vipLevelsMedal: state => state.levelsMedal,
    vipLevelsUp: state => state.levelsUp,
    vipLevels: state => state.levels,
    isBonusDaily: state => state.bonusDaily,
    getBonusDailyPrize: state => state.bonusDailyPrize,
    getBonusDailyDay: state => state.bonusDailyDay,
    isFaucetRealActive: (state, rootGetters) => {
      return rootGetters.getUserXP >= 30000
    }
  },
  mutations: {
    getLevelByXP: (state, xp) => {
      for (let i=0; i<state.levels.length; i++) {
        if (xp < state.levels[i]) return i-1
      }
      return 0
    },
    bonusDaily: (state, data) => {
      state.bonusDaily = true
      state.bonusDailyPrize = data.prize
      state.bonusDailyDay = data.day
    },
    bonusDailyClose: state => state.bonusDaily = false,
  },
  actions: {
    bonusDailyClose: (context) => {
      //console.log(context)
      context.commit('bonusDailyClose')

      if (context.getters.isFirstLogin) {
        context.commit('tutorialOpen')
      }
    },
    socket_xp_plus: (context, data) => {

      context.commit('plusUserXP', data)
    },
    socket_xp_minus: (context, data) => {

      context.commit('minusUserXP', data)
    },
    socket_level_up: (context, data) => {

      const text = {isMessage: true, isLevel: true, level: data}
      context.dispatch('addNotif', {type: 'level', text: text, isClose: false, timer: 3000}).then()
    },
    socket_level_down: (context, data) => {

      const text = {isMessage: true, isLevel: true, level: data}
      context.dispatch('addNotif', {type: 'level', text: text, isClose: false, timer: 3000}).then()
    },
    socket_bonus_daily: (context, data) => {
      // console.log('daily', data)

      context.commit('bonusDaily', data)
    }

  }
}

export default moduleVip
