const Win = {
  symbol: 'exp',
  color: '#000',
  plainIcon: c => {
    return `<g>
      <circle cx="16" cy="16" r="16" style="fill:#798281"/>
      <circle cx="16" cy="16" r="13.02" style="fill:#b0b9b8"/>
      <path d="M11,17.43H7.73v2.14H11.6V21.1H5.77V12.51H11.6V14H7.71v1.85H11Zm4.53-2,1.38-2.9h2.25l-2.4,4.26,2.59,4.32H17l-1.42-3-1.43,3H11.91l2.46-4.32L12,12.51H14.2ZM22,18.15v3H20V12.51H23.4a3.37,3.37,0,0,1,2.36.78,2.61,2.61,0,0,1,.87,2,2.62,2.62,0,0,1-.87,2,3.41,3.41,0,0,1-2.36.77Zm0-1.53H23.4a1.26,1.26,0,0,0,1-.36,1.24,1.24,0,0,0,.33-.91,1.32,1.32,0,0,0-.33-.95,1.22,1.22,0,0,0-1-.36H22Z" style="fill:#1c7a6c"/>
      <path d="M11,16.62H7.73v2.15H11.6v1.54H5.77V11.7H11.6v1.53H7.71v1.85H11Zm4.53-2,1.38-2.9h2.25L16.78,16l2.58,4.35H17l-1.42-3-1.43,3H11.91L14.37,16,12,11.7H14.2ZM22,17.36v2.95H20V11.7H23.4a3.37,3.37,0,0,1,2.36.78,2.62,2.62,0,0,1,.87,2,2.62,2.62,0,0,1-.87,2,3.47,3.47,0,0,1-2.36.78Zm0-1.54H23.4a1.19,1.19,0,0,0,1.29-1.28,1.31,1.31,0,0,0-.33-.94,1.23,1.23,0,0,0-1-.37H22Z" style="fill:#15f2ea"/>
      <path d="M3,16a12.54,12.54,0,0,1,.84-5A13.15,13.15,0,0,1,6.63,6.63,13,13,0,0,1,21.12,3.7a13.16,13.16,0,0,1,4.29,2.93A13.31,13.31,0,0,1,28.16,11,12.72,12.72,0,0,1,29,16a13.28,13.28,0,0,0-4-9A13,13,0,0,0,7,7,13.28,13.28,0,0,0,3,16Z" style="fill:#596261"/>
    </g>`;
  },
  colorIcon() {
    return `
    <g>
      <circle cx="16" cy="16" r="16" style="fill:#798281"/>
      <circle cx="16" cy="16" r="13.02" style="fill:#b0b9b8"/>
      <path d="M11,17.43H7.73v2.14H11.6V21.1H5.77V12.51H11.6V14H7.71v1.85H11Zm4.53-2,1.38-2.9h2.25l-2.4,4.26,2.59,4.32H17l-1.42-3-1.43,3H11.91l2.46-4.32L12,12.51H14.2ZM22,18.15v3H20V12.51H23.4a3.37,3.37,0,0,1,2.36.78,2.61,2.61,0,0,1,.87,2,2.62,2.62,0,0,1-.87,2,3.41,3.41,0,0,1-2.36.77Zm0-1.53H23.4a1.26,1.26,0,0,0,1-.36,1.24,1.24,0,0,0,.33-.91,1.32,1.32,0,0,0-.33-.95,1.22,1.22,0,0,0-1-.36H22Z" style="fill:#1c7a6c"/>
      <path d="M11,16.62H7.73v2.15H11.6v1.54H5.77V11.7H11.6v1.53H7.71v1.85H11Zm4.53-2,1.38-2.9h2.25L16.78,16l2.58,4.35H17l-1.42-3-1.43,3H11.91L14.37,16,12,11.7H14.2ZM22,17.36v2.95H20V11.7H23.4a3.37,3.37,0,0,1,2.36.78,2.62,2.62,0,0,1,.87,2,2.62,2.62,0,0,1-.87,2,3.47,3.47,0,0,1-2.36.78Zm0-1.54H23.4a1.19,1.19,0,0,0,1.29-1.28,1.31,1.31,0,0,0-.33-.94,1.23,1.23,0,0,0-1-.37H22Z" style="fill:#15f2ea"/>
      <path d="M3,16a12.54,12.54,0,0,1,.84-5A13.15,13.15,0,0,1,6.63,6.63,13,13,0,0,1,21.12,3.7a13.16,13.16,0,0,1,4.29,2.93A13.31,13.31,0,0,1,28.16,11,12.72,12.72,0,0,1,29,16a13.28,13.28,0,0,0-4-9A13,13,0,0,0,7,7,13.28,13.28,0,0,0,3,16Z" style="fill:#596261"/>
    </g>`;
  }
};
export default Win;
