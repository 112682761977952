export default {
  methods: {
    getLevel: function (xp) {
      const levels = this.$store.getters.vipLevels
      let level = 0



      for (let i=0; i<levels.length; i++) {
        if (xp >= levels[i]) {
          level = i
        }
        //if (xp < levels[i]) return i-1
      }
      return level
    },
    getLevelName: function(xp) {
      const names = this.$store.getters.vipLevelsName
      const up = this.$store.getters.vipLevelsUp

      let name = 0
      for (let i=0; i<up.length; i++) {
        if (xp >= up[i]) name = i+1
        else break
      }

      return names[name]
    },
    getLevelMedal: function(xp) {
      const names = this.$store.getters.vipLevelsMedal
      const up = this.$store.getters.vipLevelsUp

      let name = 0
      for (let i=0; i<up.length; i++) {
        if (xp >= up[i]) name = i+1
        else break
      }

      return names[name]
    },
    getLevelNameByLevel: function(level) {
      const levels = this.$store.getters.vipLevels

      return this.getLevelName(levels[level])
    },
    getLevelMedalByLevel: function(level) {
      const levels = this.$store.getters.vipLevels

      return this.getLevelMedal(levels[level])
    },
    getXpByLevel: function(level) {
      const levels = this.$store.getters.vipLevels

      for (let i=0; i<levels.length; i++) {
        if (level <= i) return levels[i]
      }
      return 0
    },
    getLevelStartXp: function(xp) {
      const levels = this.$store.getters.vipLevels

      let startXp = 0
      for (let i=0; i<levels.length; i++) {
        if (xp >= levels[i]) startXp = levels[i]
        else return startXp
      }
      return startXp
    },
    getLevelNextXp: function(xp) {
      const levels = this.$store.getters.vipLevels

      for (let i=0; i<levels.length; i++) {
        if (xp < levels[i]) return levels[i]
      }
      return 0
    },
    getWidthBar: function(xp) {
      const start = this.getLevelStartXp(xp)
      const next = this.getLevelNextXp(xp)

      const ret = Math.floor(((xp - start) / (next - start) * 100) * 100) / 100
      if (ret < 1) return 1
      if (ret > 100) return 100
      return ret.toString()
    }
  }
};
