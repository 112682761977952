<script>
import BalanceWrapper from "@/components/navbars_redesign/balances/BalanceWrapper.vue";
import NavUserWrap from "@/components/navbars_redesign/user/NavUserWrap.vue";
import SearchGamesWrapper from "@/components/navbars_redesign/search/SearchGamesWrapper.vue";

export default {
  name: "NavHeader",
  components: { BalanceWrapper, SearchGamesWrapper, NavUserWrap },
  props: {
    open: Boolean,
  },
  methods: {
    openBurgerMenu() {
      this.$emit("burgerMenuClick", true);
    },
  }
}
</script>

<template>
<header class="app-header">
  <div class="app-header__inner" :class="{ open }">
    <button class="app-header__mobile-menu-button" @click.prevent="openBurgerMenu">
      <SvgIcon  name="burger_menu" size="20" class="app-header__mobile-menu-img" />
    </button>

    <SearchGamesWrapper class="app-header__search" hideOnOutsideClick />

    <BalanceWrapper class="app-header__deposit" />

    <div class="app-header__user-wp">
      <NavUserWrap class="app-header__user"/>
    </div>
  </div>
</header>
</template>

<style lang="scss" scoped>
.app-header__mobile-menu-button {
  display: none;
  @include media-lg() {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background: var(--gray);
    border: none;
    flex-shrink: 0;
    cursor: pointer;
    margin-right: 24px;
  }
}
.app-header__mobile-menu-img {
  --color: var(--gray4);
  flex-shrink: 0;
}
.app-header {
  background-color: var(--dark4);
}
.app-header__inner {
  --pl: 48px;
  $padding: 20px;

  display: flex;
  align-items: center;
  min-height: 80px;
  max-width: calc(1170px + $padding + $padding);
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: calc(#{$padding} + var(--pl));
  padding-right: $padding;
  transition: padding-left .3s;

  &.open {
    --pl: 0px;
  }

  @include media-xl() {
    --pl: 0px;
  }
  @include media-lg() {
    padding: 6px 16px;
    min-height: 60px;
  }
}
.app-header__search {
  width: 100%;
  max-width: 300px;
  margin-right: 10px;

  flex: 1;
  max-width: 300px;

  @include media-lg() {
    display: none;
  }
}
.app-header__deposit {
  margin-left: auto;
  margin-right: auto;
}
.app-header__user-wp {
  margin-left: 10px;
  display: flex;
  justify-content: flex-end;

  flex: 1;
  max-width: 300px;

  @include media-xl() {
    flex: initial;
  }
}
</style>
