<script lang="ts">
import LevelIcon from "@/components/user_redesign/LevelIcon.vue";
import {PropType} from "vue";

type TypeLevel = 'newbie' | 'copper' | 'bronze' | 'silver' | 'gold' | 'platinum' | 'diamond';

export default {
  name: "UserLevel",
  components: {LevelIcon},
  props: {
    width: { type: String, default: '22px' },
    height: { type: String, default: '24px' },
    levelName: { type: String as PropType<TypeLevel>, required: true },
    leveNumber: { type: [String, Number] },
  }
}
</script>

<template>
  <div
      class="level"
      :style="{ width, height }"
  >
    <LevelIcon class="level-icon" :level="levelName" :style="{ width, height }" />
    <div class="level-text">{{ leveNumber }}</div>
  </div>
</template>

<style lang="scss" scoped>
.level {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.level-text {
  font-family: var(--secondary-font);
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: var(--white);
  font-size: 6px;
  font-style: normal;
  font-weight: 900;
  line-height: 1em;
}
</style>
