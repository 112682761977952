<script>

export default {
  name: "SearchData",
}
</script>

<template>
  <div class="casino-search-data casino-search-result">
    <div class="search-result__scroll">
      <slot></slot>
    </div>

    <img src="/src/assets/search_result_decorate.svg" class="search-result__decorate" alt="">
  </div>
</template>



<style lang="scss" scoped>
  .casino-search-result {
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    display: flex;
    justify-content: space-between;
    visibility: visible;
    opacity: 1;
    border-radius: 8px;
    background-color: var(--dark6);
    box-shadow: 0px 12px 12px 0px rgba(9, 9, 10, 0.10);
  }

  .search-result__scroll {
    max-height: min(650px, 100vh - 80px);
    overflow-y: auto;
    overflow-x: visible;
    width: 100%;
    padding: 20px;
  }

  .search-result__decorate {
    position: absolute;
    top: -7px;
    left: 20px;
    display: block;

    @include media-md() {
      display: none;
    }
  }
</style>
