<script>
export default {
  name: "TabItem",
  emits: ['onClick'],
  props: {
    to: { type: [String, Object] },
    active: { type: Boolean, default: false },
    disable: { type: Boolean, default: false },

    // change link styles by router-link-exact-active class
    // if true props 'active' will be ignored
    // https://router.vuejs.org/guide/essentials/navigation.html#router-link-exact-active
    detectExtractActive: { type: Boolean, default: false }
  }
}
</script>

<template>
  <li class="tab-item" :class="{ active, disable }">
    <div v-if="disable" class="tab-item__link" :class="{ disable }" >
      <slot />
    </div>

    <localized-link
        v-else-if="to"
        :to="to"
        class="tab-item__link"
        :class="{ active, 'is-exact-active': detectExtractActive }"
    >
      <slot />
    </localized-link>

    <button
        v-else
        class="tab-item__link"
        :class="{ active, 'is-exact-active': detectExtractActive }"
        @click.prevent="$emit('onClick')"
    >
      <slot />
    </button>
  </li>
</template>

<style scoped lang="scss">
.tab-item {
  --padding-y: 4px;
  --padding-x: 4px;
  --gap: 4px;
  --font-size: 16px;

  @include media-lg() {
    --font-size: 14px;
  }

  display: inline-block;
  margin-right: var(--gap);

  &:last-of-type {
    margin-right: 0;
  }
}
.tab-item__link {
  width: 100%;
  font-family: var(--secondary-font);
  color: var(--gray4);
  text-align: center;
  font-size: var(--font-size);
  font-weight: 600;
  line-height: normal;
  transition: border-color .3s ease-in-out, color .3s ease-in-out;
  padding: var(--padding-y) var(--padding-x);
  display: block;
  background: transparent;
  border: none;
  border-bottom: 1px solid transparent;
  cursor: default;
  user-select: none;

  &:not(.disable) {
    cursor: pointer;

    &:hover {
      color: var(--white);
    }
  }

  &.active,
  &.is-exact-active.router-link-exact-active {
    color: var(--white);
    border-color: var(--primary);
  }
}
</style>
