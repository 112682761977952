<script>
export default {
  name: "BaseTabs"
}
</script>

<template>
  <ul class="base-tabs">
    <slot />
  </ul>
</template>

<style scoped lang="scss">
.base-tabs {
  text-align: left;
  white-space:nowrap;
}
</style>
