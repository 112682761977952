export default {
  en: {
    name: 'English',
    load: () => { return import('./en.json') }
  },
  ru: {
    name: 'Russian',
    load: () => { return import('./ru.json') }
  },
  es: {
    name: 'Español',
    load: () => { return import('./es.json') }
  },
  pt: {
    name: 'Português',
    load: () => { return import('./pt.json') }
  },
  de: {
    name: 'Deutsch',
    load: () => { return import('./de.json') }
  }
}
