const moduleFriends = {
  state: {
    requestsCount: 0
  },
  getters: {
    getFriendsRequests: state => state.requestsCount,

  },
  mutations: {
    friendsRequestsMinus: state => state.requestsCount--,

    // приходит сообщение о количестве заявок в друзья
    SOCKET_friends_requests: (state, count) => state.requestsCount = count

  },
  actions: {

    socket_friends_notification: (context, data) => {

      context.dispatch('addNotif', {type: 'info', text: data, isClose: true, timer: 10000}).then()
    },


  }
}

export default moduleFriends
