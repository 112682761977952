<script>
import BonusCard from './BonusCard.vue'
export default {
  name: "Bonuses",
  components: {
    BonusCard,
  }
}
</script>

<template>
  <section class="bonuses">
    <header class="bonuses__header">
      <h2 class="bonuses__title">❤️ {{ $t('home.bonuses') }}</h2>
    </header>

    <div class="bonuses__list">
      <BonusCard
          :title="$t('bonuses.vip_title')"
          :text="$t('bonuses.vip_text')"
          link="/cabinet/vip"
          class="bonuses__card bonuses__card-vip"
      />
      <BonusCard
        :title="$t('bonuses.partnership_title')"
        :text="$t('bonuses.partnership_text')"
        link="/cabinet/partner"
        class="bonuses__card bonuses__card-partnership"
      />
    </div>
  </section>
</template>

<style lang="scss" scoped>
$offset: 12px;

.bonuses {
  @include media-lg() {
    padding-left: var(--mobile-content-padding);
    padding-right: var(--mobile-content-padding);
  }
}

.bonuses__header {
  text-align: left;
  margin-bottom: 18px;
  @include media-lg() {
    margin-bottom: 16px;
  }
}
.bonuses__title {
  font-family: var(--secondary-font);
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  @include media-lg() {
    font-size: 14px;
  }
}
.bonuses__list {
  display: flex;

  @include media-md() {
    display: block;
  }
}
.bonuses__card {
  width: calc((100% - #{$offset}) / 2 );
  margin-right: $offset;

  @include media-md() {
    width: 100%;
    margin-right: 0;
    margin-bottom: 8px;
  }

  &:last-of-type {
    margin-right: 0;
    margin-bottom: 0;
  }
}
.bonuses__card-vip {
  background:
      url("@/assets/home/bonuses/vip.svg"),
      radial-gradient(147.8% 60.33% at 77.12% 46.11%, #8F56ED 0%, #5725AB 100%);
  background-position: right bottom;
  background-size: contain;
  background-repeat: no-repeat;
}
.bonuses__card-partnership {
  background:
      url("@/assets/home/bonuses/partnership.svg"),
      radial-gradient(147.8% 60.33% at 77.12% 46.11%, #66D05D 0%, #228C19 100%);
  background-position: right bottom;
  background-size: contain;
  background-repeat: no-repeat;
}
</style>
