<script>
import NavUser from "@/components/navbars_redesign/user/NavUser.vue";

export default {
  name: "NavUserWrap",
  components: {NavUser},
  computed: {
    authStatus() {
      return this.$store.getters.authStatus
    },
    isLoadingStatus() {
      return this.authStatus === 'loading'
    },
    isUserAuthorized() {
      return this.authStatus === 'success'
    },
    isUserNotAuthorized() {
      return this.authStatus.length === 0
    }
  },
  methods: {
    logInClick() {
      this.$store.commit('openModalAuth', 'login')
    },
    signUpClick() {
      this.$store.commit('openModalAuth', 'reg')
    },
  }
}
</script>

<template>
  <!--  1. Loading-->
  <div class="navbar-item" v-if="isLoadingStatus">
    <span class="navbar-loading">loading...</span>
  </div>

  <!-- 2. User authorized -->
  <NavUser v-else-if="isUserAuthorized"  />

  <!-- 3. User not authorized -->
  <div v-else-if="isUserNotAuthorized" class="auth-links">
    <a class="auth-link" @click.prevent="logInClick">{{ $t('menu.login') }}</a>
    <a class="auth-link" @click.prevent="signUpClick">{{ $t('menu.signup') }}</a>
  </div>
</template>

<style lang="scss" scoped>
.auth-links {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  text-align: right;
  justify-content: flex-end;
}
.auth-link {
  color: var(--white);
  font-weight: 900;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    color: var(--primary-light);
  }

  & + & {
    margin-left: 10px;
  }
}
</style>
