<script>
import GameCard from "@/components/casino_redesign/game-card/GameCard.vue"
import GameSort from "@/components/casino_redesign/GameSort.vue"
import GamePaginator from "@/components/casino_redesign/GamePaginator.vue"

export default {
  name: "SearchResults",
  components: {GamePaginator, GameSort, GameCard},
  emits: ['changeSort', 'openNextSearchPage'],
  props: [
    'loaderPage',
    'sortLoader',
    'searchGames',
    'searchPages',
    'searchPerPage',
    'searchCurrentPage',
  ],
  methods: {
    changeSort: function(type) {
      this.$emit("changeSort", type)
    },
    openNextSearchPage: function() {
      this.$emit("openNextSearchPage")
    },
  }
}
</script>



<template>
  <div class="casino-game-list casino-game-search-result">
    <GameSort @sorting="changeSort" />

    <div class="casino-game-category">
      <transition-group tag="div" name="games-pool-row" mode="ease-in-out" class="search-cards">
        <div v-for="game in searchGames" :key="game.identifier">
          <GameCard :game="game" />
        </div>
      </transition-group>

      <GamePaginator
          v-if="searchPages > 1 && searchCurrentPage < searchPages"
          :loader="loaderPage"
          :current-page="searchCurrentPage"
          :total-pages="searchPages"
          :per-page="searchPerPage"
          @next="openNextSearchPage"
      />
    </div>

    <div v-if="sortLoader" class="casino-search-loader"><Logo /></div>
  </div>
</template>


<style lang="scss" scoped>
</style>
