<template>
  <div class="text-block-data">

    <TextDiceRu v-if="textName === 'Dice' && language === 'ru'" />
    <TextDiceEs v-else-if="textName === 'Dice' && language === 'es'" />
    <TextDicePt v-else-if="textName === 'Dice' && language === 'pt'" />
    <TextDiceDe v-else-if="textName === 'Dice' && language === 'de'" />
    <TextDice   v-else-if="textName === 'Dice'" />

    <TextCrashRu v-if="textName === 'Crash' && language === 'ru'" />
    <TextCrashEs v-else-if="textName === 'Crash' && language === 'es'" />
    <TextCrashPt v-else-if="textName === 'Crash' && language === 'pt'" />
    <TextCrashDe v-else-if="textName === 'Crash' && language === 'de'" />
    <TextCrash   v-else-if="textName === 'Crash'" />

    <TextRouletteRu v-if="textName === 'Roulette' && language === 'ru'" />
    <TextRouletteEs v-else-if="textName === 'Roulette' && language === 'es'" />
    <TextRoulettePt v-else-if="textName === 'Roulette' && language === 'pt'" />
    <TextRouletteDe v-else-if="textName === 'Roulette' && language === 'de'" />
    <TextRoulette   v-else-if="textName === 'Roulette'" />

    <TextWheelRu v-if="textName === 'Wheel' && language === 'ru'" />
    <TextWheelEs v-else-if="textName === 'Wheel' && language === 'es'" />
    <TextWheelPt v-else-if="textName === 'Wheel' && language === 'pt'" />
    <TextWheelDe v-else-if="textName === 'Wheel' && language === 'de'" />
    <TextWheel   v-else-if="textName === 'Wheel'" />

    <TextPlinkoRu v-if="textName === 'Plinko' && language === 'ru'" />
    <TextPlinkoEs v-else-if="textName === 'Plinko' && language === 'es'" />
    <TextPlinkoPt v-else-if="textName === 'Plinko' && language === 'pt'" />
    <TextPlinkoDe v-else-if="textName === 'Plinko' && language === 'de'" />
    <TextPlinko   v-else-if="textName === 'Plinko'" />

    <TextBonusesRu v-if="textName === 'Bonuses' && language === 'ru'" />
    <TextBonusesEs v-else-if="textName === 'Bonuses' && language === 'es'" />
    <TextBonusesPt v-else-if="textName === 'Bonuses' && language === 'pt'" />
    <TextBonusesDe v-else-if="textName === 'Bonuses' && language === 'de'" />
    <TextBonuses   v-else-if="textName === 'Bonuses'" />

    <TextMainRu v-if="textName === 'Home' && language === 'ru'" />
    <TextMainEs v-else-if="textName === 'Home' && language === 'es'" />
    <TextMainPt v-else-if="textName === 'Home' && language === 'pt'" />
    <TextMainDe v-else-if="textName === 'Home' && language === 'de'" />
    <TextMain   v-else-if="textName === 'Home'" />

  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
const TextRoulette = defineAsyncComponent(() => import("@/components/texts/en/TextRoulette.vue"))
const TextPlinko = defineAsyncComponent(() => import("@/components/texts/en/TextPlinko.vue"))
const TextWheel = defineAsyncComponent(() => import("@/components/texts/en/TextWheel.vue"))
const TextMain = defineAsyncComponent(() => import("@/components/texts/en/TextMain.vue"))
const TextCrash = defineAsyncComponent(() => import("@/components/texts/en/TextCrash.vue"))
const TextBonuses = defineAsyncComponent(() => import("@/components/texts/en/TextBonuses.vue"))
const TextDice = defineAsyncComponent(() => import("@/components/texts/en/TextDice.vue"))

const TextRouletteRu = defineAsyncComponent(() => import("@/components/texts/ru/TextRoulette.vue"))
const TextPlinkoRu = defineAsyncComponent(() => import("@/components/texts/ru/TextPlinko.vue"))
const TextWheelRu = defineAsyncComponent(() => import("@/components/texts/ru/TextWheel.vue"))
const TextMainRu = defineAsyncComponent(() => import("@/components/texts/ru/TextMain.vue"))
const TextCrashRu = defineAsyncComponent(() => import("@/components/texts/ru/TextCrash.vue"))
const TextBonusesRu = defineAsyncComponent(() => import("@/components/texts/ru/TextBonuses.vue"))
const TextDiceRu = defineAsyncComponent(() => import("@/components/texts/ru/TextDice.vue"))

const TextRouletteEs = defineAsyncComponent(() => import("@/components/texts/es/TextRoulette.vue"))
const TextPlinkoEs = defineAsyncComponent(() => import("@/components/texts/es/TextPlinko.vue"))
const TextWheelEs = defineAsyncComponent(() => import("@/components/texts/es/TextWheel.vue"))
const TextMainEs = defineAsyncComponent(() => import("@/components/texts/es/TextMain.vue"))
const TextCrashEs = defineAsyncComponent(() => import("@/components/texts/es/TextCrash.vue"))
const TextBonusesEs = defineAsyncComponent(() => import("@/components/texts/es/TextBonuses.vue"))
const TextDiceEs = defineAsyncComponent(() => import("@/components/texts/es/TextDice.vue"))

const TextRoulettePt = defineAsyncComponent(() => import("@/components/texts/pt/TextRoulette.vue"))
const TextPlinkoPt = defineAsyncComponent(() => import("@/components/texts/pt/TextPlinko.vue"))
const TextWheelPt = defineAsyncComponent(() => import("@/components/texts/pt/TextWheel.vue"))
const TextMainPt = defineAsyncComponent(() => import("@/components/texts/pt/TextMain.vue"))
const TextCrashPt = defineAsyncComponent(() => import("@/components/texts/pt/TextCrash.vue"))
const TextBonusesPt = defineAsyncComponent(() => import("@/components/texts/pt/TextBonuses.vue"))
const TextDicePt = defineAsyncComponent(() => import("@/components/texts/pt/TextDice.vue"))

const TextDiceDe = defineAsyncComponent(() => import("@/components/texts/de/TextDice.vue"))
const TextCrashDe = defineAsyncComponent(() => import("@/components/texts/de/TextCrash.vue"))
const TextRouletteDe = defineAsyncComponent(() => import("@/components/texts/de/TextRoulette.vue"))
const TextWheelDe = defineAsyncComponent(() => import("@/components/texts/de/TextWheel.vue"))
const TextPlinkoDe = defineAsyncComponent(() => import("@/components/texts/de/TextPlinko.vue"))
const TextBonusesDe = defineAsyncComponent(() => import("@/components/texts/de/TextBonuses.vue"))
const TextMainDe = defineAsyncComponent(() => import("@/components/texts/de/TextMain.vue"))

export default {
  name: "TextLayout",
  components: {
    TextRoulette, TextPlinko, TextWheel, TextMain, TextBonuses, TextCrash, TextDice,
    TextRouletteRu, TextPlinkoRu, TextWheelRu, TextMainRu, TextBonusesRu, TextCrashRu, TextDiceRu,
    TextRouletteEs, TextPlinkoEs, TextWheelEs, TextMainEs, TextBonusesEs, TextCrashEs, TextDiceEs,
    TextRoulettePt, TextPlinkoPt, TextWheelPt, TextMainPt, TextBonusesPt, TextCrashPt, TextDicePt,
    TextRouletteDe, TextPlinkoDe, TextWheelDe, TextMainDe, TextBonusesDe, TextCrashDe, TextDiceDe
  },
  computed: {
    language: function() {
      return this.$i18n.locale
    },
    textName: function() {
      return this.$route.name
    }
  }
}
</script>

<style lang="scss">
.text-block-data {
  margin: 20px auto 40px auto;
  padding: 40px 60px;
  text-align: left;
  background-color: var(--dark5);
  border-radius: 8px;
  .text-logo {
    height: 28px;
    margin-right: 10px;
  }
  hr {
    background-color: var(--primary);
    margin: 10px 0 20px 0;
  }
  h1 {
    font-size: 24px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
  h2 {
    margin-top: 10px;
    font-weight: bold;
  }
  h3 {
    font-weight: bold;
  }
  p {
    margin-left: 10px;
    margin-top: 5px;
  }
  ul {
    list-style-type: circle;
    li {
      margin-left: 20px;
    }
  }
  div {
    margin-left: 10px;
  }

  strong {
    color: var(--white);
  }

  @media (max-width: 767px) {
    margin: 0 5px 20px 5px;
    width: calc(100% - 10px);
    padding: 10px;

    p {
      font-size: 14px;
    }
    div {
      margin-left: 0;
    }
  }
}
</style>
