const BREAKPOINTS = {
  xs: 400,
  sm: 600,
  md: 768,
  lg: 1087,
  xl: 1200,
  xxl: 1920,
} as const

const DEVICES = {
  xs: `(max-width: ${BREAKPOINTS.xs}px)`,
  sm: `(min-width: ${BREAKPOINTS.xs + 1}px) and (max-width: ${BREAKPOINTS.sm}px)`,
  md: `(min-width: ${BREAKPOINTS.sm + 1}px) and (max-width: ${BREAKPOINTS.md}px)`,
  lg: `(min-width: ${BREAKPOINTS.md + 1}px) and (max-width: ${BREAKPOINTS.lg}px)`,
  xl: `(min-width: ${BREAKPOINTS.lg + 1}px) and (max-width: ${BREAKPOINTS.xl}px)`,
  xxl: `(min-width: ${BREAKPOINTS.xl + 1}px)`,
} as const

const VIEWPORT = {
  mobile: `(max-width: ${BREAKPOINTS.md}px)`,
  desktop: `(min-width: ${BREAKPOINTS.md + 1}px)`,
} as const

export {
  BREAKPOINTS,
  DEVICES,
  VIEWPORT
}