<script>
import GameImage from "@/components/casino_redesign/game-card/GameImage.vue";
import Avatar from "@/components/user/Avatar.vue";

export default {
  name: "WinCard",
  components: {Avatar, GameImage},
  emits: ['onClickUsername'],
  props: {
    provider: { type: String },
    gameName: { type: String },
    avatar: { type: String },
    username: { type: String },
    fullGameName: { type: String },
    amount: { type: [String, Number] },
  },
}
</script>

<template>
  <div class="win-card">
    <GameImage
        :file-name="`/static/games/${provider}/${gameName}.png`"
        :game-name="gameName"
        width="74"
        height="74"
        class="win-card__game-image"
    />

    <div class="win-card__content">
      <div class="win-card__user">
        <Avatar :avatar="avatar" size="18" class="win-card__user-avatar" :showLevel="false" />
        <a
            class="win-card__username ellipsis-text"
            @click.prevent="$emit('onClickUsername')"
            :title="username"
        >
          {{ username }}
        </a>
      </div>
      <p class="win-card__amount">
        <span class="win-card__amount-prefix">$</span> <span>{{ amount }}</span>
      </p>
      <p class="win-card__game-name ellipsis-text">{{ fullGameName }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.win-card {
  box-sizing: border-box;
  width: 250px;
  height: 98px;
  border-radius: 8px;
  background: #23252D;
  position: relative;
  overflow: hidden;
  display: flex;
  padding: 12px;

  &:before, &:after {
    content: '';
    width: 12px;
    height: 12px;
    display: block;
    border-radius: 50%;
    background-color: var(--dark2);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  &:before {
    left: -6px;
  }
  &:after {
    right: -6px;
  }

  @include media-lg() {
    width: 220px;
  }
}

.win-card__game-image {
  flex-shrink: 0;
  border-radius: 6px;
  overflow: hidden;
  margin-right: 12px;
  height: 74px;
  width: 74px;
}
.win-card__content {
  flex: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.win-card__user {
  display: flex;
  font-family: var(--main-font);
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
}
.win-card__username {
  color: currentColor;
  cursor: pointer;
  transition: color 0.3s ease;
  font-size: 12px;
  max-width: 110px;

  @include media-lg() {
    max-width: 80px;
  }

  &:hover {
    color: var(--primary);
  }
}
.win-card__user-avatar {
  margin-left: 0;
  margin-right: 8px;
  border-radius: 50%;
  border: 1px solid var(--gray);
  flex-shrink: 0;
}

.win-card__amount {
  color: var(--white);
  font-family: var(--secondary-font);
  font-size: 16px;
  font-weight: 600;
}
.win-card__amount-prefix {
  color: var(--gold);
}
.win-card__game-name {
  color: var(--gray4);
  font-size: 12px;
  font-weight: 700;
  opacity: 0.5;
  margin-top: auto;

  max-width: 140px;

  @include media-lg() {
    max-width: 114px;
  }
}
.ellipsis-text {
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: visible;
  text-overflow: ellipsis;
}
</style>
