<script>
import Vip from "@/components/vip/vip";
import NavLinks from './NavLinks.vue'
import UserData from "@/components/navbars_redesign/user/include/UserData.vue";
import BaseButton from '@/components/base/BaseButton.vue';

import {printTime} from "@/utils/filters";

export default {
  name: "UserDropdown",
  components: {UserData, NavLinks, BaseButton },
  mixins: [Vip],
  emits: ['closeUserDropdown', 'logout'],
  computed: {
    regTime() {
      return this.$store.getters.getAuthData.reg_time
    }
  },
  methods: {
    printTime: function (valTime) {
      const time = new Date(valTime * 1000)
      return this.addZero(time.getDate()) + '/' + this.addZero(time.getMonth()+1) + '/' + time.getFullYear()
    },
    addZero: function (i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
    onClickDropdownButton(modalName) {
      this.$emit('closeUserDropdown')
      if (modalName) this.$store.commit(modalName)
    },
  }
}
</script>

<template>
  <div class="user-dropdown">
    <div class="user-dropdown__header">
      <UserData :avatarSize="90" class="user-dropdown__data">
        <template #firstData>
          <div class="user-dropdown__registration">
            {{ $t('modals.login.registration') }}: {{ printTime(regTime) }}
          </div>
        </template>
      </UserData>
      <button class="user-dropdown__close-btn" @click="$emit('closeUserDropdown')">
        <SvgIcon name="shape" size="20" />
      </button>
    </div>

    <BaseButton variant="gray" class="user-dropdown__btn" @click="onClickDropdownButton('openModalPayout')">
      {{ $t('menu.withdraw') }}
    </BaseButton>
    <BaseButton variant="gray" class="user-dropdown__btn" @click="onClickDropdownButton('openModalCoupon')">
      {{ $t('menu.coupon') }}
    </BaseButton>
    <BaseButton variant="gray" class="user-dropdown__btn" @click="onClickDropdownButton('openModalExchange')">
      {{ $t('menu.exchange') }}
    </BaseButton>

    <div class="user-dropdown__footer">
      <nav class="user-dropdown__navigation">
        <NavLinks @logout="$emit('logout')" @close="$emit('closeUserDropdown')" />
      </nav>

      <div class="user-dropdown__decoration">
        <div class="user-dropdown__decoration-child" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.user-dropdown {
  width: 208px;
  background-color: var(--dark5);
  text-align: left;
  border-radius: 8px;
  padding: 20px 8px 8px 8px;
  border: 1px solid var(--dark7);
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  z-index: 35;

  @include media-md() {
    top: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    flex-direction: column;
    display: flex;
    padding: 16px;
  }
}

.user-dropdown__decoration {
  position: absolute;
  height: 7px;
  width: 16px;
  overflow: hidden;
  right: 20px;
  top: -3px;
  transform: translateY(-50%);
  display: flex;
  align-items: start;
  justify-content: center;

  @include media-md() {
    display: none;
  }
}
.user-dropdown__decoration-child {
  width: 12px;
  height: 12px;
  transform: rotate(-45deg);
  background-color: var(--dark5);
  border-radius: 2px;
  border: 1px solid var(--dark7);
  margin-top: 2px;
}

.user-dropdown__btn {
  width: 100%;
  color: var(--gray5);
  font-size: 14px;
  padding: 12px 24px;
  margin-bottom: 12px;
  min-height: 40px;
}

.user-dropdown__footer {
  margin-top: 4px;

  @include media-md() {
    flex-direction: column;
    display: flex;
    flex: 1;
  }
}
.user-dropdown__navigation {
  @include media-md() {
    flex-direction: column;
    display: flex;
    flex: 1;

    &:deep {
      .user-navigation-bar-divider {
        display: none;
      }
      .user-navigation-bar-item--exit {
        margin-top: auto;
      }
    }
  }
}

.user-dropdown__header {
  display: none;

  @include media-md() {
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;
    padding-right: 32px;
  }
}

.user-dropdown__data {
  &:deep {
    @include media-md() {
      display: flex;
      justify-content: flex-start;

      .user__data {
        display: block;
      }
      .user__avatar {
        margin-right: 20px;
      }
      .user__name {
        font-family: var(--secondary-font);
        font-size: 16px;
        line-height: normal;
        margin-bottom: 0px;
      }
      .user__exp {
        color: var(--gray5);
        font-family: var(--secondary-font);
        font-size: 12px;
      }
      .user__stars {
        margin-top: 9px;
        margin-bottom: 11px;
      }
      .user__star {
        width: 18px;
        height: 18px;
      }
      .level-text {
        font-size: 12px;
      }
    }
  }
}
.user-dropdown__close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 0;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--gray);
  box-shadow: 0px 3px 4px 0px rgba(16, 16, 20, 0.15);
  border: none;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  position: fixed;
  top: 16px;
  right: 16px;

  &:hover {
    background: var(--gray2);
  }
}
.user-dropdown__registration {
  color: var(--gray4);
  font-family: var(--main-font);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  margin-top: 5px;
  margin-bottom: 2px;
}
</style>
