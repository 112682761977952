<script>
import Vip from "@/components/vip/vip";
import {onClickOutside} from "@vueuse/core";
import UserData from './include/UserData.vue'
import ModalLogout from './modal/ModalLogout.vue'
import UserDropdown from './include/UserDropdown.vue'


export default {
  name: "NavUser",
  components: { UserData, ModalLogout, UserDropdown },
  mixins: [Vip],
  data () {
    return {
      isOpenUserDropdown: false,
      isModalLogout: false,
      passwordNewText: '',
      passwdNewPasswordError: '',
      passwordConfirmText: '',
      passwdConfirmPasswordError: '',
      passwordLoader: false,
      passwordSuccess: false,
    }
  },
  mounted() {
    const dropdown = this.$refs.dropdown
    onClickOutside(dropdown, () => this.closeUserDropdown())
  },
  computed: {
    isPassword: function () {
      return this.$store.getters.getAuthData.is_password
    },
    userXP: function() {
      return this.$store.getters.getAuthData.xp
    }
  },
  methods: {
    logoutClick: function () {
      this.closeUserDropdown()
      if (!this.isPassword) {
        this.$store.commit('changeScroll', false)
        this.isModalLogout = true
        return
      }
      this.logoutSend()
    },
    closeModalLogout() {
      this.isModalLogout = false
    },
    logoutSend: function () {
      this.$store.commit('changeScroll', true)

      this.axios.get('/auth/logout?' + new Date().getTime())
          .then(response => {
            if (response.data.status === 'error') {
              this.$store.dispatch('addNotif', {type: 'danger', text: response.data.message, isClose: false, timer: 2000}).then()
            } else {
              this.isModalLogout = false
              this.$store.commit('AUTH_LOGOUT')

              this.$store.commit('tourney_team', -1)

              this.$store.dispatch('clearDatabaseAll').then()

              this.$store.commit('socketAuthStatus', false)
              this.$store.commit('chatLoadStatus', false)

              this.$router.push('/')
            }
          })
          .catch(() => {
            this.$store.dispatch('addNotif', {type: 'danger', text: 'Server error', isClose: false, timer: 2000}).then()
          })
    },
    closeUserDropdown() {
      this.isOpenUserDropdown = false
    },
    toggleUserDropdown() {
      this.isOpenUserDropdown = !this.isOpenUserDropdown
    },
  }
}
</script>

<template>
  <div ref="dropdown">
    <div class="user-wrap">
      <UserData @onClick="toggleUserDropdown" />

      <transition name="fade-dropdown">
        <UserDropdown
            v-if="isOpenUserDropdown"
            clas="user-navigation-bar-dropdown"
            @closeUserDropdown="closeUserDropdown"
            @logout="logoutClick"
        />
      </transition>
    </div>

    <ModalLogout
        v-if="isModalLogout"
        @close="closeModalLogout"
        @logoutSend="logoutSend"
    />
  </div>
</template>

<style lang="scss" scoped>
.fade-dropdown-enter-active,
.fade-dropdown-leave-active {
  transition: all .5s ease;
}
.fade-dropdown-enter-from,
.fade-dropdown-leave-to {
  transform: translateY(40px);
  opacity: 0;
}

.user-wrap {
  position: relative;
  overflow: visible;
  @include media-lg() {
    width: auto;
  }
}

.user-navigation-bar-dropdown {
  //width: 208px;
  //position: absolute;
  //top: calc(100% + 10px);
  //right: 0;
  //z-index: 2;
}
</style>
