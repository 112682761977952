<script>
import SectionSlider from '@/components/pages/home_redesign/SectionSlider.vue'
import ProviderCard from '@/components/pages/home_redesign/providers/ProviderCard.vue'

export default {
  name: "Providers",
  components: {
    SectionSlider,
    ProviderCard,
  },
  computed: {
    providers: function() {
      return this.$store.getters.getProviders
    }
  },
}
</script>

<template>
  <SectionSlider
      :title="$t('home.providers')"
      :viewAllLink="{name: 'CasinoProviders'}"
      :list="providers"
      class="providers"
  >
    <template #default="{ item }">
      <ProviderCard :name="item" />
    </template>

  </SectionSlider>
</template>

<style lang="scss" scoped>
.providers {
  &:deep {
    .swiper-slide {
      width: 224px;
      margin-right: 12px;

      @include media-lg() {
        width: 154px;
      }
    }
  }
}
</style>
