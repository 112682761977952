<script>
import BaseButton from "@/components/base/BaseButton.vue";

export default {
  name: "Deposit",
  components: {BaseButton},
  computed: {
    depositAnimation() {
      return this.$store.getters.getDepositAnimate
    },
  },
  methods: {
    openDeposit() {
      this.$store.commit('openModalDeposit')
    },
  }
}
</script>

<template>
  <BaseButton variant="primary" class="deposit" @click="openDeposit" :class="{'pulse-deposit': depositAnimation}">
    <SvgIcon name="wallet" class="deposit__mobile-img" size="20" />
    <span class="deposit__desktop-text">{{ $t('menu.deposit') }}</span>
  </BaseButton>
</template>

<style lang="scss" scoped>
.deposit {
  font-size: 14px;
  padding: 12px 24px;
  height: 40px;
  width: 130px;

  @include media-lg() {
    width: 32px;
    height: 32px;
    padding: 4px;
  }
}
.deposit__mobile-img {
  --color: currentColor;
  display: none;
  @include media-lg() {
    display: block;
  }
}
.deposit__desktop-text {
  @include media-lg() {
    display: none;
  }
}


.pulse-deposit {
  animation-name: pulse_deposit;
  animation-duration: 500ms;
  animation-iteration-count: 6;
  animation-timing-function: ease;
  color: var(--white);
}

@keyframes pulse_deposit {
  0%   { color: #ff1e00; }
  25%  { color: #ffdd47; }
  50%  { color: #ff1e00; }
  75%  { color: #ffdd47; }
  100% { color: #ff1e00; }
}
</style>
