<script>
export default {
  name: "AvatarProgressBar",
  props: {
    value: { type: Number, default: 0 }
  },
  data() {
    return {
      strokeDashoffset: 0,
    }
  },
  watch: {
    value() {
      this.setCount()
    }
  },
  mounted() {
    this.setCount()
  },
  methods: {
    setCount() {
      const path = this.$refs.meter
      if (!path) return;

      const length = path.getTotalLength();
      const value = parseInt(this.value);
      const to = length * ((100 - value) / 100);

      // Trigger Layout in Safari hack
      // https://jakearchibald.com/2013/animated-line-drawing-svg/
      path.getBoundingClientRect();

      this.strokeDashoffset = Math.max(0, to)
    }
  }
}
</script>

<template>
  <svg class="progress-bar" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
    <path
        ref="meter"
        class="progress-bar__meter"
        d="M5,50a45,45 0 1,0 90,0a45,45 0 1,0 -90,0"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dashoffset="282.78302001953125"
        stroke-dasharray="282.78302001953125"
        :style="{ strokeDashoffset }"
    />
  </svg>
</template>

<style lang="scss" scoped>
.progress-bar {
  --color: var(--orange);

  display: inline-flex;
  vertical-align: bottom;
  width: 52px;
  height: 52px;
  transform: rotate(150deg);

  &.newbie { --color: #393939; }
  &.copper { --color: #A1704F; }
  &.bronze { --color: #DE6128; }
  &.silver { --color: #464646; }
  &.gold { --color: #CB9424; }
  &.platinum { --color: #515C80; }
  &.diamond { --color: #0074BF; }

  &__meter {
    stroke: var(--color);
  }
}

.progress-bar__meter {
  stroke-width: 4px;
  fill: none;
  transition: stroke-dashoffset 1s cubic-bezier(0.43, 0.41, 0.22, 0.91);
  transform-origin: center center;
  transform: rotate(-90deg) scaleX(-1);
}
</style>
