<script>
import BaseButton from "@/components/base/BaseButton.vue";

export default {
  name: "GamePaginator",
  components: {BaseButton},
  props: ['loader', 'currentPage', 'totalPages', 'perPage'],
  emits: ['next'],
  computed: {
    percentage: function() {
      let data = Math.round(this.currentPage / this.totalPages * 100)
      return data >= 100 ? 100 : data
    }
  },
  methods: {
    nextPage: function() {
      this.$emit('next')
    }
  }
}
</script>


<template>
  <div class="casino-game-paginator">
    <div class="casino-game-paginator-line"><div :style="{ width: `${percentage}%`}"></div></div>
    <div class="casino-game-paginator-text">{{ $tc('casino.pagination', [currentPage * perPage, totalPages * perPage]) }}</div>
    <div>
      <BaseButton variant="outline-primary" class="casino-game-paginator-button" :class="{ 'is-loading': loader }" @click="nextPage">
        {{ $t('casino.pagination_more') }}
      </BaseButton>
    </div>
  </div>
</template>


<style lang="scss" scoped>
  .casino-game-paginator {
    padding: 20px 0;
    display: inline-flex;
    flex-direction: column;
    &-line {
      overflow: hidden;
      display: flex;
      height: 6px;
      width: 100%;
      background-color: rgba(#3F7FFA, 0.5);
      border-radius: 8px;
      > div {
        background-color: var(--primary);
      }
    }
    &-text {
      padding: 5px 0;
    }
    &-button {
      font-size: 16px;
      height: 36px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
</style>
