// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import './assets/styles/style_load.scss'
import './assets/styles/style.scss'
import './assets/styles/history.scss'

import {createApp} from "vue"
import App from './App.vue'
import store from './store/index'
import router from './router/index'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'virtual:svg-icons-register'

import * as Sentry from "@sentry/vue"

import VueProgressBar from "@aacassandra/vue3-progressbar"

import VueIntercom from 'vue3-intercom'
//import VueNativeSock from 'vue-native-websocket-vue3'
import VueNativeSock from '@/components/elements/socket/socket'

//import VueQriously from 'vue-qriously'
import VueCookie from 'vue3-cookies'
import Logo from './components/Logo.vue'
import SvgIcon from './components/base/SvgIcon.vue'

import VueGtag from 'vue-gtag'

import {printTime, printTimeFull, printAvatar, printWalletAddress, printTXID } from "@/utils/filters"

import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import './utils/icons'

//import Cryptoicon from 'vue-cryptoicon'
import Cryptoicon from "@/components/elements/cryptoicon"
import { Btc, Eth, Ltc, Doge, Bch, Bnb, Trx, Usdt } from 'vue-cryptoicon/src/icons'

import Win from './utils/icon_win'
import Exp from './utils/icon_exp'
import Xrp from './utils/icon_xrp'
import { i18n } from 'vue-lang-router'

const app = createApp(App).use(i18n)


Cryptoicon.add([Win, Exp, Btc, Eth, Ltc, Doge, Bch, Xrp, Bnb, Trx, Usdt])
app.use(Cryptoicon)


//app.use(VueQriously)
app.use(VueCookie)

app.use(VueIntercom, {appId: 'nku7j19u'})

app.component('SvgIcon', SvgIcon)
app.component('Logo', Logo)
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('font-awesome-layers', FontAwesomeLayers)


//Vue.use(VueNativeSock, import.meta.env.VITE_APP_WS + "?t=" + new Date().getTime(), {
app.use(VueNativeSock, store.getters.getDomainWs + "?t=" + new Date().getTime(), {
  store: store,
  format: 'json',
  //reconnection: true,
  //reconnectionAttempts: Infinity,
  //reconnectionDelay: 3000,
  connectManually: true,
  passToStoreHandler: function (eventName, event) {
    //console.log(event)
    if (!eventName.startsWith('SOCKET_')) { return }
    let target = eventName.toUpperCase()
    let msg = event

    if (this.format === 'json' && event.data) {
      msg = JSON.parse(event.data)
      if (msg.action) {

        if ('socket_'+msg.action in this.store._actions) {
          this.store.dispatch('socket_'+msg.action, msg.data).then()
          return
        }

        if ('SOCKET_'+msg.action in this.store._mutations) {
          this.store.commit('SOCKET_'+msg.action, msg.data)
          return
        }
      }
    }

    this.store['commit'](target, msg)
  }
})

if (import.meta.env.VITE_APP_SENTRY !== 'false') {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_APP_SENTRY,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    tracePropagationTargets: store.getters.getDomains,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.05,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.1,

    ignoreErrors: [
      'Network request failed',
      'Failed to fetch',
      'NetworkError',
      'Network Error',
      'Request aborted',
      'Load failed',
      'firefoxSample',
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
    ],
  })
}

//Vue.config.productionTip = true
//Vue.config.devtools = true

if (import.meta.env.VITE_APP_DEBUG === true) {
  app.config.devtools = true
  app.config.performance = true
}

axios.defaults.baseURL = store.getters.getDomainApi
axios.defaults.withCredentials = true
axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.getAuthToken

app.use(VueAxios, axios)

if (import.meta.env.VITE_APP_GOOGLE !== 'false') {
  app.use(VueGtag, {
    pageTrackerExcludedRoutes: ['/admin'],
    config: {id: import.meta.env.VITE_APP_GOOGLE}
  }, router)
  /*
  app.use(VueAnalytics, {
    id: import.meta.env.VITE_APP_GOOGLE,
    linkers: ['windice.org', 'windice.link', 'windice1.io'],
    router,
    ignoreRoutes: ['/admin'],
    ecommerce: {
      enabled: true
    },
    debug: {
      sendHitTask: import.meta.env.NODE_ENV === 'production'
    }
  })
  */
}


/*
if (import.meta.env.VITE_APP_YANDEX !== 'false') {
  const VueYandexMetrika = require('vue-yandex-metrika').default
  Vue.use(VueYandexMetrika, {
    id: import.meta.env.VITE_APP_YANDEX,
    router: router,
    env: import.meta.env.NODE_ENV
  })
}
*/

const options = {
  color: '#00aaff',
  failedColor: '#874b4b',
  thickness: '5px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
}

app.use(VueProgressBar, options)

// filters
app.config.globalProperties.$filters = {
  printTime: printTime,
  printTimeFull: printTimeFull,
  printAvatar: printAvatar,
  printWalletAddress: printWalletAddress,
  printTXID: printTXID,
}

if (store.getters.getDeviceType === 'widescreen' && localStorage.isChatOpen !== undefined && localStorage.isChatOpen === 'false') {
  store.state.isChatOpen = false
}
if (store.getters.getDeviceType !== 'widescreen') {
  store.commit('chatToggle')
}

if (navigator.userAgent.includes('Prerender')) {
  store.state.isChatOpen = false
}

const canonical = import.meta.env.VITE_APP_CANONICAL

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    //console.log("need auth")
    if (!store.getters.isAuth) {
      next({path: '/', query: { redirect: to.fullPath } })
      return
    } else {
      if (to.matched.some(record => record.meta.requiresModer)) {
        if (!store.getters.isModer && !store.getters.isApprover && !store.getters.isAdmin) {
          next({path: '/', query: {redirect: to.fullPath}})
          return
        }
      }
      if (to.matched.some(record => record.meta.requiresAdmin)) {
        if (!store.getters.isAdmin) {
          next({path: '/', query: {redirect: to.fullPath}})
          return
        }
      }
    }
  }

  if (to.meta.title !== undefined) {
    document.title = to.meta.title
  } else if (document.title !== store.getters.getDefaultTitle) {
    document.title = store.getters.getDefaultTitle
  }

  if (to.meta.description !== undefined) {
    document.querySelector(`meta[name="description"]`).setAttribute('content', to.meta.description)
  } else if (document.querySelector(`meta[name="description"]`).getAttribute('content') !== store.getters.getDefaultDescription) {
    document.querySelector(`meta[name="description"]`).setAttribute('content', store.getters.getDefaultDescription)
  }

  document.querySelector(`link[rel="canonical"]`).setAttribute('href', canonical + to.path)

  next() // make sure to always call next()!
})


app.use(router)
app.use(i18n)
app.use(store)

/*
configureCompat({
  // default everything to Vue 2 behavior
  MODE: 3,
  // opt-in to Vue 3 behavior for non-compiler features
  //RENDER_FUNCTION: false,
  //WATCH_ARRAY: false
})

 */


//app.config.compilerOptions.whitespace = 'preserve'
app.mount("#app")

export default app
