<script>
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'SvgIcon',
  props: {
    prefix: { type: String, default: 'icon' },
    name: { type: String, required: true },
    // ignored when height and width are set
    size: { type: [Number, String], default: 24 },
    // use it when height and width are not equal
    height: { type: [Number, String] },
    width: { type: [Number, String] },
  },
  setup(props) {
    const symbolId = computed(() => `#${props.prefix}-${props.name}`)
    return { symbolId }
  },
  computed: {
    widthValue() {
      return this.width ?? this.size
    },
    heightValue() {
      return this.height ?? this.size
    }
  }
})
</script>

<template>
  <svg aria-hidden="true" class="svg-icon" :width="widthValue" :height="heightValue">
    <use :href="symbolId" fill="currentColor" />
  </svg>
</template>

<style lang="scss">
.svg-icon {
  --color: var(--gray4);
  --fill-opacity: 1;
  display: inline-block;
}
</style>
