<script>
import licenseIcon from "@/assets/license.webp";
import LangSwitcher from "@/components/navbars_redesign/lang-switcher/LangSwitcher.vue";
import BaseButton from "@/components/base/BaseButton.vue";

export default {
  name: 'NavFooter',
  components: {
    BaseButton,
    LangSwitcher
  },
  data () {
    return {
      licenseIcon,
    }
  },
  computed: {
    links() {
      const links = [
        {
          localize: 'menu.casino',
          children: [
            { localize: 'casino.category.slots', to: '/casino/category/slots' },
            { localize: 'casino.category.live', to: '/casino/category/live' },
            { localize: 'casino.category.new', to: '/casino/category/new' },
            { localize: 'casino.category.popular', to: '/casino/category/popular' },
            { localize: 'casino.category.card', to: '/casino/category/card' },
            { localize: 'casino.category.roulette', to: '/casino/category/roulette' },
          ]
        },
        {
          localize: 'menu.games_our',
          children: [
            { text: 'Dice', to: '/dice' },
            { text: 'Crash', to: '/crash' },
            { text: 'Roulette', to: '/roulette' },
            { text: 'Plinko', to: '/plinko' },
            { text: 'Wheel', to: '/wheel' },
          ]
        },
        {
          localize: 'menu.bonuses',
          children: [
            { localize: 'menu.vip', to: '/vip' },
            { localize: 'menu.bonuses', to: '/bonuses' },
            { text: 'Contest', to: '/contest/daily-win', symbol: 'win' },
            { text: 'Contest daily', to: '/contest/daily', symbol: 'btc' },
            { text: 'Contest weekly', to: '/contest/weekly', symbol: 'btc' },
            { localize: 'menu.affiliate', to: '/affiliate' },
          ]
        },
        {
          localize: 'menu.links',
          children: [
            { localize: 'menu.support', click: this.openIntercom },
            { localize: 'menu.forum', href: 'https://forum.windice.io' },
            { localize: 'menu.fair', to: this.$store.getters.isAuth ? '/fair/change' : '/fair' },
            { localize: 'menu.api', to: '/docsapi' },
            { localize: 'menu.terms', to: '/terms' },
            { localize: 'menu.privacy', to: '/privacy' },
          ]
        }
      ]

      return links
    }
  },
  methods: {
    openIntercom: function() {
      this.$store.commit("openIntercom")
    },
    scrollTop: function() {
      let smoothly = setInterval(function(){
        window.scrollTo(0,window.scrollY - 200)
        if (window.scrollY <= 0)
          clearInterval(smoothly)
      }, 5);
    }
  }
}
</script>

<template>
<footer class="app-footer">
  <div class="app-footer__content">
    <section class="app-footer__inner app-footer__inner--first">
      <div class="site-info">
        <div class="site-info__images">
          <localized-link class="logo site-info__logo" to="/">
            <img class="logo__img" src="../../assets/logo_.svg" alt="windice" height="43" width="40">
            <img class="logo__name" src="../../assets/site_logo_name.svg" alt="windice" height="17" width="103">
          </localized-link>
          <img class="age-restriction" src="../../assets/license_18.webp" alt="18+" height="40" width="40" />
        </div>
        <div class="site-info__license">
          <p>Windice is owned and operated by Win Games Group NV, registration number: 160672, registered address: Zuikertuin Tower, Curaçao. Contact us at support@windice.io.</p>
          <p>Windice is authorized and regulated by the Government of Curacao and operates under License No. #365/JAZ issued to Gaming Services Provider NV.</p>
          <p>Windice has passed all compliance and is legally authorized to conduct gaming operations for all games of chance and wagering.</p>
        </div>
      </div>

      <nav class="nav">
        <div v-for="item in links" :key="item.localize"  class="nav__inner">
          <h3 class="nav__title">{{ $t(item.localize) }}</h3>
          <ul class="nav__list">
            <li v-for="link in item.children" :key="link.localize" class="nav__item">
              <localized-link v-if="link.to" class="nav__link" :to="link.to" :title="link.text || $t(link.localize)">
                <span class="nav__text">{{ link.text || $t(link.localize) }}</span>
                <cryptoicon v-if="link.symbol" class="nav__icon" :symbol="link.symbol" size="18"/>
              </localized-link>
              <a v-else :href="link.href" class="nav__link" :title="link.text || $t(link.localize)" target="_blank">
                {{ link.text || $t(link.localize) }}
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <div class="app-footer__actions">
        <LangSwitcher />
        <div>
          <BaseButton variant="gray" class="scroll-top" @click.prevent="scrollTop()">
            <font-awesome-icon icon="arrow-up" class="scroll-top__icon" />
          </BaseButton>
        </div>
      </div>
    </section>

    <section class="app-footer__inner app-footer__inner--last">
      <div class="currencies">
        <ul class="currencies__list">
          <li v-for="(item, key) in $store.getters.getCurrenciesReal" :key="key" class="currencies__item">
            <cryptoicon :symbol="item" size="32" />
          </li>
          <li class="currencies__item">
            <p class="currencies__text">50+ ways to top up your balance</p>
          </li>
        </ul>
      </div>

      <div class="license-icon">
        <a href="https://licensing.gaming-curacao.com/validator/?lh=21dee2b8e16ecd22aa24e41c20f0564f" target="_blank">
          <img :src="licenseIcon" alt="Gaming Curacao" height="40" width="122" />
        </a>
      </div>
    </section>
  </div>
</footer>
</template>

<style lang="scss" scoped>
$offset: 20px;

.app-footer {
  margin: 64px auto 0 auto;
  width: 100%;
  background: var(--black);
  padding-left: $offset;
  padding-right: $offset;
  padding-top: 50px;

  @include media-xl() {
    padding-bottom: $offset;
    padding-top: $offset;
  }
  &__content {
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
  }
  &__inner {
    display: flex;
    @include media-xl() {
      flex-direction: column;
    }
    &--first {
      border-bottom: 2px solid #3e3e42;
      justify-content: space-between;
      padding-bottom: 46px;
      @include media-xl() {
        padding-bottom: 32px;
      }
    }
    &--last {
      padding-top: 30px;
      padding-bottom: 12px;
      justify-content: space-between;
      align-items: center;
      @include media-xl() {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }
  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
    @include media-xl() {
      flex-direction: row;
      align-items: center;
    }
  }
}

.currencies {
  display: flex;
  @include media-xl() {
    margin-bottom: 8px;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-right: $offset;
    @include media-xl() {
      justify-content: center;
      margin-right: 0;
    }
  }
  &__item {
    margin-right: 10px;
    margin-bottom: $offset;
    &:last-of-type {
      margin-right: 0px;
    }
  }
  &__text {
    color: var(--gray4);
    font-size: 14px;
    font-weight: 500;
    max-width: 118px;
    text-align: left;
    line-height: normal;
  }
}

.site-info {
  max-width: 302px;
  line-height: normal;
  margin-right: 65px;
  @include media-xl() {
    margin-right: 20px;
    max-width: 240px;
  }
  @include media-xl() {
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
    margin-bottom: 32px;
  }
  &__images {
    display: flex;
    align-items: center;
    margin-bottom: $offset;
    padding-left: 5px;
    flex-wrap: wrap;
    @include media-xl() {
      margin-bottom: 10px;
    }
    @include media-xl() {
      justify-content: center;
      padding-left: 0;
      margin-bottom: 13px;
    }
  }
  &__logo {
    margin-right: 32px;
    flex-shrink: 0;
    @include media-xl() {
      margin-right: 10px;
      margin-bottom: 10px;
    }
    @include media-xl() {
      margin-right: 0;
    }
  }
  &__license {
    color: var(--gray4);
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    @include media-xl() {
      text-align: center;
    }
  }
}

.logo {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  &__img {
    margin-right: 10px;
    flex-shrink: 0;
    @include media-xl() {
      width: 30px;
      height: 32px;
    }
  }
  &__name {
    flex-shrink: 0;
    @include media-xl(){
      width: 78px;
      height: 13px;
    }
  }
}

.age-restriction {
  flex-shrink: 0;
  @include media-xl() {
    margin-bottom: 10px;
  }
  @include media-xl() {
    display: none;
  }
}

.nav {
  display: flex;
  justify-content: space-around;
  text-align: left;
  flex: 1;
  @include media-xl() {
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
  &__inner {
    margin-right: $offset;
    flex: 1;
    @include media-xl() {
      margin-right: 0;
      margin-bottom: $offset;
      text-align: center;
    }
  }
  &__list {
    @include media-xl() {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  &__item {
    display: flex;
    @include media-xl() {
      margin-left: 12px;
      margin-right: 12px;
    }
  }
  &__title {
    color: var(--gray4);
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;
    @include media-xl() {
      color: var(--white);
      font-size: 18px;
    }
  }
  &__link {
    display: flex;
    align-items: center;
    color: var(--gray5);
    font-size: 14px;
    font-weight: 500;
    line-height: 10px;
    margin-top: $offset;
  }
  &__text {
    white-space: nowrap;
  }
  &__icon {
    margin-left: 4px;
    flex-shrink: 0;
  }
}

.scroll-top {
  width: 40px;
  height: 40px;
  font-size: 16px;
}
.scroll-top__icon {
  font-size: 16px;
  color: var(--gray4);
}

.license-icon {
  margin-bottom: $offset;
  @include media-xl() {
    margin-bottom: 0;
  }
}
</style>
