<script>
export default {
  name: "GameImage",
  props: ["fileName", "gameName"],

  computed: {
    webpSupported: function() {
      return this.$store.getters.getSupportWebP
    }
  },
  methods: {
    transformImgExt (url) {
      // If webp is supported, transform the url
      if (this.webpSupported) {
        return url.replace(/\.\w{1,5}$/, ".webp");
      } else { // Otherwise, just return the original
        return url;
      }
    }
  },
}
</script>

<template>
  <img :src="transformImgExt(fileName)" :alt="gameName" loading=lazy />
</template>

<style scoped>

</style>
