<script>
export default {
  name: "GameList",
  data() {
    return {
      showGameList: false,
    }
  },
  methods: {
    toggleGame() {
      this.showGameList = !this.showGameList
    },
  }
}
</script>

<template>
  <button class="games" :class="{ 'active': showGameList }" @click="toggleGame">
    <SvgIcon name="games" size="24" class="games__icon game-list__icon"/>
    <span class="games__title">{{ $t('menu.games') }}</span>
<!--    <span class="games__count">365</span>-->
    <font-awesome-icon icon="chevron-down" class="games__arrow" :class="{ 'active': showGameList }" />
  </button>
  <transition name="language-fade">
    <div class="game-list" v-show="showGameList">
      <localized-link class="link" to="/">
        <SvgIcon name="seven" size="24" class="link__icon game-list__icon"/>
        Slots</localized-link>
      <localized-link class="link" to="/">
        <SvgIcon name="cards" size="24" class="link__icon game-list__icon"/>
        Table Games</localized-link>
      <localized-link class="link" to="/">
        <SvgIcon name="lightbulb" size="24" class="link__icon game-list__icon"/>
        New games</localized-link>
      <localized-link class="link" to="/">
        <SvgIcon name="dice" size="24" class="link__icon game-list__icon"/>
        Windice original</localized-link>
      <localized-link class="link" to="/">
        <SvgIcon name="crown" size="24" class="link__icon game-list__icon"/>
        Tranding games</localized-link>
      <localized-link class="link" to="/">
        <SvgIcon name="fire" size="24" class="link__icon game-list__icon"/>
        Hot Games</localized-link>
      <localized-link class="link" to="/">
        <SvgIcon name="casino_roulette" size="24" class="link__icon game-list__icon"/>
        Live Casino</localized-link>
      <localized-link class="link" to="/">
        <SvgIcon name="star" size="24" class="link__icon game-list__icon"/>
        Bonus buy</localized-link>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.language-fade-enter-active,
.language-fade-leave-active {
  transition: all .5s ease;
}

.language-fade-enter-from,
.language-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

.games {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  min-height: 40px;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 12px;
  padding-left: 12px;
  padding-right: 15px;
  transition: background-color .3s ease;

  &.active {
    background-color: var(--dark2);
  }
}
.game-list {
  border-radius: 8px;
  background-color: var(--dark2);
  padding-top: 2px;
  padding-bottom: 2px;
}
.game-list__icon {
  --color: var(--primary);
}
.games__icon {
  margin-right: 8px;
}
.games__title {
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
}
.games__count {
  color: var(--gray5);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
  padding: 6px 10px;
  border-radius: 12px;
  background: var(--gray3);
  margin-left: auto;
  margin-right: 13px;
}
.games__arrow {
  color: var(--gray5);
  font-size: 10px;
  transition: 0.3s ease;
  margin-left: auto;
  &.active {
    transform:  rotate(180deg);
  }
}
.link {
  color: var(--white);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
  padding: 12px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 4px;
  transition: background-color .3s ease;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    background-color: var(--dark3);
    color: #FFF;
    .link__icon {
      --color: var(--primary);
    }
  }
}
.link__icon {
  margin-right: 8px;
}
</style>
