<script>
import Deposit from './Deposit.vue'
import Balances from './Balances.vue'

export default {
  name: "Balance",
  components: { Deposit, Balances },
}
</script>

<template>
  <div class="balance">
    <Balances />
    <Deposit />
  </div>
</template>

<style lang="scss" scoped>
.balance {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dark6);
}
</style>
