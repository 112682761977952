<script>
import TextContent from "@/components/base-sections/TextContent.vue";

export default {
  name: "NotFoundView",
  components: {
    TextContent,
  },
  created() {
    const meta = document.createElement('meta')
    meta.name = "prerender-status-code"
    meta.content = "404"
    document.getElementsByTagName('head')[0].appendChild(meta)
  },
  beforeUnmount() {
    document.querySelector("[name='prerender-status-code']").remove()
  }
}
</script>

<template>
  <TextContent class="content">
    <h1 class="title">ERROR 404: page not found</h1>
  </TextContent>
</template>

<style lang="scss" scoped>
.content {
  margin-top: 47px;
  text-align: center;

  @include media-md() {
    margin-top: 20px;
  }
}
.title {
  font-size: 28px;
  font-weight: 700;
  color: var(--white);
  line-height: normal;
}
</style>
