<template>
  <div id="captcha-gee"></div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "GeeCaptcha",
  emits: ["validate"],
  created() {
    window.initGeetest4(
        {
          captchaId: import.meta.env.VITE_APP_GEE_CAPTCHA,
          product: "popup",
        },
        this.captchaHandler
    )
  },
  methods: {
    captchaHandler: function(captchaObj) {
      window.captchaObj = captchaObj
      captchaObj
          .appendTo("#captcha-gee")
          .onError(function (e) {
            console.error("gee-captcha: ", e)
          })
          .onSuccess(() => {
            const result = captchaObj.getValidate()
            this.$emit('validate', result)
          });
    },
    reset: function() {
      if (window.captchaObj === undefined) return
      window.captchaObj.reset()
    }
  }
})
</script>

<style scoped>

</style>
