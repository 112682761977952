<template>
  <div class="modal-auth">

    <div :class="(isModal ? 'modal is-active' : 'auth-page')">
      <div class="modal-background" @click="closeAuth"></div>
      <div class="logo-auth"  @click="closeAuth" :class="{'logo-no-modal': !isModal}">
        <img src="../../assets/logo_.svg" alt="" id="modalLogo" :style="{width: topHeight * 0.5 + 'px', height: topHeight * 0.5 + 'px', 'margin-top': topHeight * 0.25 + 'px'}">
      </div>
      <div class="modal-card" id="authModalCard" :class="{shake: isError}">
        <header class="modal-card-head">
          <div><span class="logo-auth-mini"><img src="../../assets/logo_.svg" alt=""></span><span class="head-title is-uppercase">{{ $t('modals.login.welcome') }}</span></div>
          <button class="delete" aria-label="close" @click="closeAuth" v-if="isModal"></button>
        </header>
        <section class="modal-card-body">

          <div class="tabs is-centered">
            <ul>
              <li :class="authType === 'reg' ? 'is-active' : ''" @click="changeType('reg')"><a class="is-uppercase">{{ $t('modals.login.registration') }}</a></li>
              <li :class="authType === 'login' ? 'is-active' : ''" @click="changeType('login')"><a class="is-uppercase">{{ $t('modals.login.login') }}</a></li>
            </ul>
          </div>

          <div class="card-content">

            <transition name="fade" mode="out-in">
              <div class="content" v-if="authType === 'loginSuccess'" :key="authType">
                <div class="notification is-info">
                  <div>Login was successful</div>
                  <div>Please wait...</div>
                </div>
              </div>

              <div class="content" v-else-if="authType === 'reg'" :key="authType">
                <form @submit.prevent="register">
                  <div class="field-body field-login" :class="{'is-danger': isNickError}">
                    <div class="field is-expanded">
                      <div class="field has-addons">
                        <p class="control">
                          <label @click.prevent="focusInput" class="button input-label is-uppercase">{{ $t('modals.login.username') }}</label>
                        </p>
                        <p class="control">
                          <input class="input" type="text" v-model.trim="generateLogin" :disabled="regLoader">
                        </p>
                      </div>
                      <p class="help is-danger">{{ errorNick }}</p>
                    </div>
                  </div>

                  <div class="field-body field-login" v-show="regCaptchaOpen">
                    <div class="field">
                      <Captcha ref="captchaRegistration" @validate="validateCaptchaRegistration" />
                      <p class="help is-danger">{{regCaptchaError}}</p>
                    </div>
                  </div>

                  <div class="field">
                    <div class="control has-text-centered">
                      <input class="is-checkradio is-small" id="checkRules" type="checkbox" name="checkRules" v-model="checkedRules" :class="{'is-success': checkedRules}">
                      <label for="checkRules" class="rules-label"><span>{{ $t('modals.login.terms[0]') }} <localized-link to="/terms">{{ $t('modals.login.terms[1]') }}</localized-link></span></label>
                    </div>
                    <p class="help is-danger" v-show="isRulesError">{{ $t('modals.login.terms_error') }}</p>
                  </div>

                  <div class="field">
                    <div class="control is-inline-flex">
                      <button class="button is-link is-uppercase" :class="{'is-loading':regLoader || !$store.getters.isAuthSecLoad}">{{ $t('modals.login.start') }}</button>
                    </div>
                  </div>
                </form>
              </div>

              <div class="content" v-else-if="authType === 'login'" :key="authType">
                <form @submit.prevent="login" class="form-login">

                  <div class="field-body field-login" :class="{'is-danger': loginLoginError}">
                    <div class="field is-expanded">
                      <div class="field has-addons">
                        <p class="control">
                          <label @click.prevent="focusInput" class="button input-label is-uppercase">{{ $t('modals.login.login') }}</label>
                        </p>
                        <p class="control">
                          <input class="input" type="text" v-model.trim="loginLoginText" :disabled="loginLoader">
                        </p>

                      </div>
                      <p class="help is-danger">{{loginLoginError}}</p>
                    </div>
                  </div>

                  <div class="field-body field-login" :class="{'is-danger': loginPasswordError}">
                    <div class="field is-expanded">
                      <div class="field has-addons">
                        <p class="control">
                          <label @click.prevent="focusInput" class="button input-label is-uppercase">{{ $t('modals.login.password') }}</label>
                        </p>
                        <p class="control">
                          <input class="input" type="password" v-model.trim="loginPasswordText" :disabled="loginLoader">
                        </p>

                      </div>
                      <p class="help is-danger">{{loginPasswordError}}</p>
                    </div>
                  </div>

                  <div class="field-body field-login" :class="{'is-danger': loginOtpError}" v-show="loginIsOtp">
                    <div class="field is-expanded">
                      <div class="field has-addons">
                        <p class="control">
                          <label @click.prevent="focusInput" class="button input-label is-uppercase">{{ $t('modals.login.two_factor') }}</label>
                        </p>
                        <p class="control">
                          <input class="input" type="text" v-model.trim="loginOtpText" :disabled="loginLoader">
                        </p>

                      </div>
                      <p class="help is-danger">{{loginOtpError}}</p>
                    </div>
                  </div>

                  <div v-show="loginDeviceError || loginCodeError">
                    <div class="notification is-info notif-device">{{loginDeviceError}}</div>
                    <div class="field-body field-login" :class="{'is-danger': loginCodeError}">
                      <div class="field is-expanded">
                        <div class="field has-addons">
                          <p class="control">
                            <label @click.prevent="focusInput" class="button input-label is-uppercase">{{ $t('modals.login.code') }}</label>
                          </p>
                          <p class="control">
                            <input class="input" type="text" v-model.number="loginCodeText" :disabled="loginLoader">
                          </p>

                        </div>
                        <p class="help is-danger">{{loginCodeError}}</p>
                      </div>
                    </div>
                  </div>

                  <div class="field-body field-login" v-show="loginCaptchaOpen">
                    <div class="field">
                      <Captcha ref="captchaLogin" @validate="validateCaptchaLogin" />
                      <p class="help is-danger">{{loginCaptchaError}}</p>
                    </div>
                  </div>

                  <div class="field login-button">
                    <div class="control is-inline-flex">
                      <button class="button is-link is-uppercase" :class="{'is-loading':loginLoader || !$store.getters.isAuthSecLoad}">{{ $t('modals.login.login') }}</button>
                    </div>
                  </div>

                  <div class="field forgot-links">
                    <a @click="changeType('forgotLogin')" class="forgot-link">{{ $t('modals.login.forgot_login') }}</a>
                    <a @click="changeType('forgot')" class="forgot-link">{{ $t('modals.login.forgot_password') }}</a>
                  </div>

                </form>
              </div>

              <div class="content" v-else-if="authType === 'forgot'" :key="authType">
                <form @submit.prevent="onSubmitForgot">

                  <div class="field-body field-login" :class="{'is-danger': forgotError}">
                    <div class="field is-expanded">
                      <div class="field has-addons">
                        <p class="control">
                          <label @click.prevent="focusInput" class="button input-label is-uppercase">{{ $t('modals.login.login_or_email') }}</label>
                        </p>
                        <p class="control">
                          <input class="input" type="text" v-model.trim="forgotText" :disabled="forgotLoader">
                        </p>
                      </div>
                      <p class="help is-danger">{{forgotError}}</p>
                    </div>
                  </div>

                  <div class="field">
                    <Captcha ref="captchaForgot" @validate="validateCaptchaForgot" />
                    <p class="help is-danger">{{forgotCaptchaError}}</p>
                  </div>

                  <div class="field">
                    <div class="control is-inline-flex">
                      <button class="button is-link is-uppercase" :class="{'is-loading':forgotLoader}">{{ $t('modals.login.send') }}</button>
                    </div>
                  </div>

                </form>
              </div>
              <div class="content" v-else-if="authType === 'forgotCode'" :key="authType">
                <form @submit.prevent="onSubmitForgotCode">
                  <div class="form-notif">{{ $t('modals.login.check_email') }}</div>
                  <div class="field-body field-login" :class="{'is-danger': forgotCodeError}">
                    <div class="field is-expanded">
                      <div class="field has-addons">
                        <p class="control">
                          <label @click.prevent="focusInput" class="button input-label is-uppercase">{{ $t('modals.login.code') }}</label>
                        </p>
                        <p class="control">
                          <input class="input" type="text" v-model.trim="forgotCode" :disabled="forgotCodeLoader">
                        </p>

                      </div>
                      <p class="help is-danger">{{forgotCodeError}}</p>
                    </div>
                  </div>

                  <div class="field">
                    <div class="control is-inline-flex">
                      <button class="button is-link" :class="{'is-loading':forgotCodeLoader}">{{ $t('modals.login.send') }}</button>
                    </div>
                  </div>

                </form>
              </div>
              <div class="content" v-else-if="authType === 'forgotPassword'" :key="authType">
                <div v-show="forgotSuccess">
                  <div class="notification is-info">
                    <div>{{ $t('modals.login.password_changed') }}</div>
                  </div>
                </div>
                <div v-show="!forgotSuccess">
                  <form @submit.prevent="onSubmitForgotPassword">
                    <span>{{ $t('modals.login.password_new') }}</span>

                    <div class="field-body field-login field-password" :class="{'is-danger': forgotPasswordError}">
                      <div class="field is-expanded">
                        <div class="field has-addons">
                          <p class="control">
                            <a class="button input-label is-uppercase">{{ $t('modals.login.password') }}</a>
                          </p>
                          <p class="control">
                            <input class="input" type="password" v-model.trim="forgotPassword" :disabled="forgotPasswordLoader">
                          </p>
                        </div>
                        <p class="help is-danger">{{forgotPasswordError}}</p>
                      </div>
                    </div>

                    <div class="field-body field-login field-password" :class="{'is-danger': forgotPasswordTwoError}">
                      <div class="field is-expanded">
                        <div class="field has-addons">
                          <p class="control">
                            <a class="button input-label is-uppercase">{{ $t('modals.login.password_confirm') }}</a>
                          </p>
                          <p class="control">
                            <input class="input" type="password" v-model.trim="forgotPasswordTwo" :disabled="forgotPasswordLoader">
                          </p>
                        </div>
                        <p class="help is-danger">{{forgotPasswordTwoError}}</p>
                      </div>
                    </div>

                    <div class="field">
                      <div class="control is-inline-flex">
                        <button class="button is-link" :class="{'is-loading':forgotPasswordLoader}">SEND</button>
                      </div>
                    </div>

                  </form>
                </div>
              </div>

              <div class="content" v-else-if="authType === 'forgotLogin'" :key="authType">
                <div v-show="forgotLoginSuccess">
                  <div class="notification is-info">
                    <div>{{ $t('modals.login.login_sent') }}</div>
                  </div>
                </div>
                <div v-show="!forgotLoginSuccess">
                  <form @submit.prevent="onSubmitForgotLogin">

                    <div class="field-body field-login" :class="{'is-danger': forgotLoginError}">
                      <div class="field is-expanded">
                        <div class="field has-addons">
                          <p class="control">
                            <label @click.prevent="focusInput" class="button input-label is-uppercase">{{ $t('modals.login.email') }}</label>
                          </p>
                          <p class="control">
                            <input class="input" type="text" v-model.trim="forgotLoginText" :disabled="forgotLoginLoader">
                          </p>
                        </div>
                        <p class="help is-danger">{{forgotLoginError}}</p>
                      </div>
                    </div>

                    <div class="field field-captcha">
                      <Captcha ref="captchaForgotLogin" @validate="validateCaptchaForgotLogin" />
                      <p class="help is-danger">{{forgotLoginCaptchaError}}</p>
                    </div>

                    <div class="field">
                      <div class="control is-inline-flex">
                        <button class="button is-link" :class="{'is-loading':forgotLoginLoader}">{{ $t('modals.login.send') }}</button>
                      </div>
                    </div>

                  </form>
                </div>
              </div>

            </transition>

          </div>
        </section>
      </div>

    </div>
  </div>
</template>

<script>
import GeeCaptcha from "@/components/elements/geecaptcha/GeeCaptcha.vue"
export default {
  components: {'Captcha': GeeCaptcha},
  props: {
    isModal: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      appLang: this.$i18n.locale || "en",
      checkedRules: true,
      generateLogin: '',
      isNickError: false,
      isRulesError: false,
      errorNick: '',
      regLoader: false,
      regCaptcha: '',
      regCaptchaOpen: false,
      regCaptchaError: '',

      loginLoginText: '',
      loginLoginError: '',
      loginPasswordText: '',
      loginPasswordError: '',
      loginOtpText: '',
      loginOtpError: '',
      loginIsOtp: false,
      loginLoader: false,
      loginCaptcha: null,
      loginCaptchaError: '',
      loginCaptchaOpen: false,
      loginDeviceError: '',
      loginCodeError: '',
      loginCodeText: null,
      forgotLoader: false,
      forgotText: '',
      forgotToken: '',
      forgotCaptcha: '',
      forgotError: '',
      forgotCaptchaError: '',
      forgotSuccess: false,
      forgotInputClose: false,
      forgotCodeSend: false,
      forgotCodeError: '',
      forgotCode: '',
      forgotCodeLoader: false,
      forgotPasswordLoader: false,
      forgotPassword: '',
      forgotPasswordTwo: '',
      forgotPasswordError: '',
      forgotPasswordTwoError: '',
      forgotLoginText: '',
      forgotLoginCaptcha: '',
      forgotLoginLoader: false,
      forgotLoginSuccess: false,
      forgotLoginError: '',
      forgotLoginCaptchaError: '',
      topHeight: 120,
      isError: false
    }
  },
  computed: {
    authType: {
      get: function () {
        return this.$store.getters.getModalAuthType
      },
      set: function (data) {
        this.$store.commit('changeModalAuthType', data)
      }
    }
  },
  watch: {
    generateLogin: function () {
      if (this.isNickError === true) {
        this.isNickError = false
      }
    }
  },
  beforeUnmount () {
    if (this.isModal === true) {
      this.$store.commit('changeScroll', true)
    }

    window.removeEventListener('resize', this.handleWindowResize)
  },
  mounted () {
    if (this.isModal === true) {
      this.$store.commit('changeScroll', false)
    }

    this.generateLogin = this.randLogin()
    window.addEventListener('resize', this.handleWindowResize)
    this.handleWindowResize()
  },
  updated () {
    this.$nextTick(function () {
      this.handleWindowResize()
    })
  },
  methods: {
    handleWindowResize: function () {
      const modalElem = document.getElementById('authModalCard')
      let addHeight = 1

      if (modalElem !== null) {
        this.topHeight = modalElem.offsetTop * addHeight
      }

      setTimeout(function () {
        const modalLogo = document.getElementById('modalLogo')
        const modalElem = document.getElementById('authModalCard')
        if (modalLogo !== null && modalElem !== null) {
          modalLogo.style.width = modalElem.offsetTop * 0.5 + 'px'
          modalLogo.style.height = modalElem.offsetTop * 0.5 + 'px'
          modalLogo.style.marginTop = modalElem.offsetTop * 0.25 + 'px'
        }
      }.bind(this), 500)
    },
    randLogin: function () {
      let text = ''
      let possible = ['ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz']

      text += possible[0].charAt(Math.floor(Math.random() * possible[0].length))
      for (let i = 0; i < 6; i++) {
        text += possible[1].charAt(Math.floor(Math.random() * possible[1].length))
      }
      return text + Math.floor((Math.random() * 100) + 100)
    },
    register: function () {
      if (this.$store.getters.isAuthSecLoad === false) {
        return
      }

      this.regLoader = true
      this.isNickError = false
      this.isRulesError = false
      this.isError = false
      if (this.checkedRules === false) {
        this.isRulesError = true
        this.regLoader = false
        return
      }
      const referral = this.$cookies.get('referral') === null ? localStorage.getItem('referral') : this.$cookies.get('referral')
      const link = this.$cookies.get('link') === null ? localStorage.getItem('link') : this.$cookies.get('link')
      const stag = localStorage.getItem('affiliate_tag')

      const data = {
        nickname: this.generateLogin,
        referral: referral,
        link: link,
        affiliate_tag: stag,
        ...(this.regCaptcha && { captcha_gee: this.regCaptcha })
      }

      this.axios.post(`/auth/register?language=${this.appLang}`, data)
        .then(response => {
          this.regLoader = false
          if (response.data.status === 'success') {

            this.authType = 'loginSuccess'
            setTimeout(() => {
              this.$store.commit('AUTH_SUCCESS', response.data)
              this.$store.commit('closeModalAuth')
              this.$store.commit('firstLogin')
              //this.$store.commit('tutorialOpen')

              // send token to Google Analytics (after success login)
              this.$gtag.config({ user_id: response.data.data.hash })

              this.$store.dispatch('createLive').then()
            }, 1000)

            this.$cookies.remove('referral')
            this.$cookies.remove('link')
            localStorage.removeItem('referral')
            localStorage.removeItem('link')
            localStorage.removeItem('affiliate_tag')


          } else if (response.data.status === 'error' && response.data.message !== '') {
            this.$store.dispatch('addNotif', {type: 'danger', text: response.data.message, isClose: false, timer: 2000}).then()
          } else {
            if (response.data.data.login !== undefined) {
              this.isNickError = true
              this.errorNick = response.data.data.login
              this.isError = true
            }

            if (response.data.data.captcha !== undefined) {
              if (this.regCaptchaOpen === true) {
                this.regCaptchaError = response.data.data.captcha
              }
              this.$refs.captchaRegistration.reset()
              this.regCaptchaOpen = true
            } else {
              if (this.regCaptcha !== '') {
                this.$refs.captchaRegistration.reset()
              }
            }
          }
        })
        .catch(() => {
          this.regLoader = false
          this.$store.dispatch('addNotif', {type: 'danger', text: 'Server error', isClose: false, timer: 2000}).then()
        })
    },
    login: function () {
      if (this.$store.getters.isAuthSecLoad === false) {
        return
      }
      this.loginLoader = true
      this.loginLoginError = ''
      this.loginPasswordError = ''
      this.loginOtpError = ''
      this.loginCaptchaError = ''
      this.loginDeviceError = ''
      this.loginCodeError = ''
      this.isError = false

      const isEmail = this.loginLoginText.includes('@')
      const loginParam = isEmail ? 'email' : 'nickname'

      const data = {
        [loginParam]: this.loginLoginText,
        password: this.loginPasswordText,
        otp: this.loginOtpText.toString(),
        code: parseInt(this.loginCodeText),
        ...(this.loginCaptcha && { captcha_gee: this.loginCaptcha })
      }

      this.axios.post(`/auth/login?language=${this.appLang}`, data)
        .then(response => {
          this.loginLoader = false
          if (response.data.status === 'success') {
            this.authType = 'loginSuccess'

            setTimeout(() => {
              this.$store.commit('AUTH_SUCCESS', response.data)
              this.$store.commit('closeModalAuth')

              // send token to Google Analytics (after success login)
              this.$gtag.config({ user_id: response.data.data.hash })

              this.$store.dispatch('createLive').then()
            }, 1000)
          } else if (response.data.status === 'error' && response.data.message !== '') {
            this.$store.dispatch('addNotif', {type: 'danger', text: response.data.message, isClose: false, timer: 2000}).then()
          } else if (response.data.status === 'error') {
            this.isError = true
            if (response.data.data.login !== undefined) {
              this.loginLoginError = response.data.data.login
            }

            if (response.data.data.password !== undefined) {
              this.loginPasswordError = response.data.data.password
            }

            if (response.data.data.otp !== undefined) {
              this.loginIsOtp = true
              this.loginOtpError = response.data.data.otp
            }

            if (response.data.data.device !== undefined) {
              this.loginDeviceError = response.data.data.device
            }

            if (response.data.data.code !== undefined) {
              this.loginCodeError = response.data.data.code
            }

            if (response.data.data.captcha !== undefined) {
              if (this.loginCaptchaOpen === true) {
                this.loginCaptchaError = response.data.data.captcha
              }
              this.$refs.captchaLogin.reset()
              this.loginCaptcha = null
              this.loginCaptchaOpen = true
            } else {
              if (this.loginCaptcha !== null) {
                this.$refs.captchaLogin.reset()
                this.loginCaptcha = null
              }
            }
          }
        })
        .catch(() => {
          this.loginLoader = false
          this.$store.dispatch('addNotif', {type: 'danger', text: 'Server error', isClose: false, timer: 2000}).then()
        })

    },
    forgot: function (e) {
      if (e !== undefined) e.preventDefault()

      this.forgotError = ''
      this.forgotCaptchaError = ''
      this.isError = false

      this.axios.post('/auth/forgot', {value: this.forgotText, captcha_gee: this.forgotCaptcha})
        .then(response => {
          this.forgotLoader = false
          if (response.data.status === 'success') {
            this.authType = 'forgotCode'
            this.forgotText = ''
            this.forgotToken = response.data.data
          } else if (response.data.status === 'error' && response.data.message !== '') {
            this.$store.dispatch('addNotif', {type: 'danger', text: response.data.message, isClose: false, timer: 2000}).then()
          } else if (response.data.status === 'error') {
            this.isError = true
            if (response.data.data.value !== undefined) {
              this.forgotError = response.data.data.value
            }
            if (response.data.data.captcha !== undefined) {
              this.forgotCaptchaError = response.data.data.captcha
            }
          }
          this.$refs.captchaForgot.reset()
        })
        .catch(() => {
          this.forgotLoader = false
          this.$store.dispatch('addNotif', {type: 'danger', text: 'Server error', isClose: false, timer: 2000}).then()
          this.$refs.captchaForgot.reset()
        })
    },
    changeType: function (authType) {
      this.forgotLoader = false
      this.forgotText = ''
      this.forgotCode = ''
      this.forgotToken = ''
      this.forgotSuccess = false
      this.forgotLoginSuccess = false
      this.authType = authType
    },
    onSubmitForgot: function () {
      this.forgotLoader = true
      this.forgot()
    },
    onSubmitForgotCode: function () {
      this.forgotCodeLoader = true
      this.forgotCodeError = ''
      this.isError = false

      this.axios.post('/auth/forgotCode', {code: this.forgotCode, token: this.forgotToken})
        .then(response => {
          this.forgotCodeLoader = false
          if (response.data.status === 'success') {
            this.authType = 'forgotPassword'
          } else if (response.data.status === 'error' && response.data.message !== '') {
            this.$store.dispatch('addNotif', {type: 'danger', text: response.data.message, isClose: false, timer: 2000}).then()
          } else if (response.data.status === 'error') {
            this.isError = true
            if (response.data.data.code !== undefined) {
              this.forgotCodeError = response.data.data.code
            }
          }
        })
        .catch(() => {
          this.forgotCodeLoader = false
          this.$store.dispatch('addNotif', {type: 'danger', text: 'Server error', isClose: false, timer: 2000}).then()
        })
    },
    onSubmitForgotPassword: function () {
      this.forgotPasswordLoader = true
      this.forgotPasswordError = ''
      this.forgotPasswordTwoError = ''
      this.isError = false

      this.axios.post('/auth/forgotPassword', {code: this.forgotCode, token: this.forgotToken, password: this.forgotPassword, password_two: this.forgotPasswordTwo})
        .then(response => {
          this.forgotPasswordLoader = false
          if (response.data.status === 'success') {
            this.forgotSuccess = true
            setTimeout(() => {
              this.changeType('login')
            }, 3000)
          } else if (response.data.status === 'error' && response.data.message !== '') {
            this.$store.dispatch('addNotif', {type: 'danger', text: response.data.message, isClose: false, timer: 2000}).then()
          } else if (response.data.status === 'error') {
            this.isError = true
            if (response.data.data.password !== undefined) {
              this.forgotPasswordError = response.data.data.password
            }
            if (response.data.data.password_two !== undefined) {
              this.forgotPasswordTwoError = response.data.data.password_two
            }
          }
        })
        .catch(() => {
          this.forgotPasswordLoader = false
          this.$store.dispatch('addNotif', {type: 'danger', text: 'Server error', isClose: false, timer: 2000}).then()
        })
    },
    onSubmitForgotLogin: function() {
      this.forgotLoginLoader = true
      this.forgotLoginError = ''
      this.isError = false

      this.axios.post('/auth/forgot/login', {value: this.forgotLoginText, captcha_gee: this.forgotLoginCaptcha})
        .then(response => {
          this.forgotLoginLoader = false
          if (response.data.status === 'success') {
            this.forgotLoginSuccess = true
            setTimeout(() => {
              this.changeType('login')
            }, 3000)
          } else if (response.data.status === 'error' && response.data.message !== '') {
            this.$store.dispatch('addNotif', {type: 'danger', text: response.data.message, isClose: false, timer: 2000}).then()
          } else if (response.data.status === 'error') {
            this.isError = true
            if (response.data.data.value !== undefined) {
              this.forgotLoginError = response.data.data.value
            }
            if (response.data.data.captcha !== undefined) {
              this.forgotLoginCaptchaError = response.data.data.captcha
            }
          }
          this.$refs.captchaForgotLogin.reset()
        })
        .catch(() => {
          this.forgotPasswordLoader = false
          this.$store.dispatch('addNotif', {type: 'danger', text: 'Server error', isClose: false, timer: 2000}).then()
          this.$refs.captchaForgotLogin.reset()
        })
    },
    validateCaptchaForgot: function (data) {
      this.forgotCaptchaError = ''
      this.forgotCaptcha = data
    },
    validateCaptchaLogin: function (data) {
      this.loginCaptchaError = ''
      this.loginCaptcha = data
    },
    validateCaptchaRegistration: function (data) {
      this.regCaptchaError = ''
      this.regCaptcha = data
    },
    validateCaptchaForgotLogin: function (data) {
      this.forgotLoginCaptchaError = ''
      this.forgotLoginCaptcha = data
    },
    focusInput: function (e) {
      e.preventDefault()
      const elem = e.target.parentElement.parentElement.getElementsByTagName('input')
      elem[0].focus()
    },
    closeAuth: function () {
      if (this.isModal) {
        this.$store.commit('closeModalAuth')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use "sass:math";
@import "@/assets/styles/lib/utilities/initial-variables.sass";
@import "@/assets/styles/lib/utilities/functions.sass";
@import "@/assets/styles/lib/utilities/derived-variables.sass";


@mixin checkbox-size($size) {
  $newSize: $size * 1.5;
  $height: math.div($newSize, 2.5);
  $width: math.div($newSize, 4);

  + label {
    font-size: $size;
    padding-left: $size * 2;
    &::before,
    &:before {
      width: $newSize;
      height: $newSize;
    }
    &::after,
    &:after {
      width: $width;
      height: $height;
      top: ( ( math.div($newSize, 2) ) - ( math.div($height, 2) ) ) * 0.9;
      left: $height;
    }
  }
}

.is-checkradio[type="checkbox"] {
  outline: 0;
  user-select: none;
  display: none;
  position: absolute;
  opacity: 0;

  + label {
    position: relative;
    display: initial;
    cursor: pointer;
    vertical-align: middle;
    margin: .5em;
    padding: .2rem .5rem .2rem 0;
    border-radius: $radius;
    &:first-of-type {
      margin-left: 0;
    }
    &:hover {
      &::before,
      &:before {
        animation-duration: 0.4s;
        animation-fill-mode: both;
        animation-name: hover-color;
      }
    }
    &::before,
    &:before {
      position: absolute;
      left: 0;
      top: 0rem;
      content: '';
      border: .1rem solid $grey-lighter;
    }
    &::after,
    &:after {
      position: absolute;
      display: none;
      content: '';
      top: 0rem;
    }
  }
  &:focus {
    + label {
      &::before,
      &:before {
        outline: 1px dotted $grey-light;
      }
    }
  }
  &:hover:not([disabled]) {
    + label {
      &::before,
      &:before {
        border-color: $primary !important;
      }
    }
  }
  &:checked {
    + label {
      &::before,
      &:before {
        border: .1rem solid $grey-lighter;
      }
    }
    &[disabled] {
      cursor: not-allowed;
      + label {
        opacity: 0.5;
      }
    }
    + label {
      &::before,
      &:before {
        animation-name: none;
      }
      &::after,
      &:after {
        display: inline-block;
      }
    }
  }
  &[disabled] {
    cursor: not-allowed;
    + label {
      opacity: 0.5;
      cursor: not-allowed;
      &:hover,
      &:before,
      &::before,
      &::after,
      &:after {
        cursor: not-allowed;
      }
    }
    &:hover {
      cursor: not-allowed;
      &::before,
      &:before {
        animation-name: none;
      }
    }
    &::before,
    &:before {
      cursor: not-allowed;
    }
    &::after,
    &:after {
      cursor: not-allowed;
    }
  }
}

.is-checkradio[type="checkbox"] {
  + label {
    &::before,
    &:before {
      border-radius: $radius;
    }
    &::after,
    &:after {
      box-sizing: border-box;
      transform: translateY(0rem) rotate(45deg);
      border-width: .1rem;
      border-style: solid;
      border-color: $primary;
      border-top: 0;
      border-left: 0;
    }
  }
  &.is-small {
    @include checkbox-size($size-small);
  }
  &.is-success {
    &:hover:not([disabled]) {
      + label {
        &::before,
        &:before {
          border-color: $success !important;
        }
      }
    }
    &:checked {
      + label {
        &::after,
        &:after {
          border-color: $success !important;
        }
      }
    }
  }
  &:indeterminate {
    + label {
      &::after,
      &:after {
        display: inline-block;
        transform: rotate(90deg);
        border-bottom: none;
      }
    }
    &.is-success {
      + label {
        &::after,
        &:after {
          border-color: $success;
        }
      }
    }
  }
}
</style>


<style lang="scss" scoped>
  .modal-auth {
    .fade-enter-active, .fade-leave-active {
      transition: all .3s ease;
    }

    .fade-enter {
      transform: translateX(100%);
      opacity: 0;
    }

    .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
    {
      transform: translateX(-100%);
      opacity: 0;
    }

    .auth-page {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 30;
      padding-top: 200px;

    }

    .modal {
      flex-direction: column;
    }

    .modal-card {
      width: 400px;
      margin: 0 auto;
    }

    .modal-card-body {
      min-height: 150px;
      padding: 10px 20px;
      overflow: hidden;
    }

    .modal-card-head > div {
      text-align: center;
      width: 100%;
      font-weight: 900;
      font-size: 26px;
      position: relative;
    }

    .card-content {
      padding-top: 0;
      margin-top: calc(10px + 0.25rem);
    }

    .logo-auth {
      /*margin-top: -148px;
    margin-bottom: 10px;*/
      z-index: 50;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    .logo-auth img {
      width: 128px;
      height: 128px;
    }

    .logo-auth-mini {
      display: none;
    }

    .head-title {
      display: inline-block;
      vertical-align: middle;
    }

    .tabs {
      margin-bottom: 0;
    }

    .field-login, .field-login .control {
      margin: 0;
    }

    .field-login {
      margin-bottom: 10px;
    }

    .field-password .input-label {
      min-width: 140px;
    }

    .rules-label span {
      vertical-align: text-bottom;
    }

    .rules-label span a {
      text-decoration: underline;
      color: #FFF;
    }

    .rules-label span a:hover {
      color: #bababa;
    }

    .is-checkradio[type="checkbox"].is-success + label::before {
      border-color: #23d160;
    }

    .forgot-link {
      font-size: 12px;
      color: #FFF;
      opacity: 0.5;
      text-decoration: underline;
    }

    .forgot-link:hover {
      opacity: 1;
    }

    .modal-card-body button {
      /*margin-top: 10px;*/
      //width: 120px;
      font-weight: 900;
    }

    .form-login .field:not(:last-child) {
      margin-bottom: 0;
    }

    .form-login .field-body .input-label {
      min-width: 100px;
    }

    .form-notif {
      margin-bottom: 10px;
    }

    .field-captcha div:first-of-type {
      display: flex;
      justify-content: center;

    }

    .notif-device {
      padding: 5px;
      margin-bottom: 5px;
    }

    .forgot-links {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
    }

    @media (max-width: 767px) {
      .modal-card-head > div {
        font-size: 20px;
      }

      .auth-page .modal-card {
        margin-top: -120px;
      }
      .card-content {
        padding: 0 0 20px 0;
      }
    }
    @media (max-width: 479px) {
      .logo-auth {
        /*margin-top: -128px;*/
      }
      .logo-auth img {
        width: 100px;
        height: 100px;
      }
      .modal-card {

        width: calc(100% - 10px);
      }
      .modal {
        margin-top: 60px;
      }
      .logo-no-modal {
        display: none;
      }
    }
    @media (max-height: 600px) {
      .logo-auth-mini {
        display: inline-block;
      }
      .logo-auth-mini img {
        width: 32px;
        height: 32px;
        vertical-align: sub;
        margin-right: 10px;
      }
      .logo-auth {
        display: none;
      }
    }
    @include media-xxs() {
      .auth-page .modal-card {
        margin-top: -160px;
      }
    }
  }
</style>
