<script>
export default {
  name: "ProviderCard",
  props: {
    name: { type: String, required: true }
  },
  methods: {
    getProviderImage() {
      return new URL(`/src/assets/home/providers/${this.name}.svg`, import.meta.url).href
    },
  }
}
</script>

<template>
  <localized-link
      :to="{name: 'CasinoProvider', params: { provider: this.name }}"
      class="provider-card"
  >
    <div class="provider-card__image-wp">
      <img :src="getProviderImage(this.name)" :alt="this.name" class="provider-card__image" />
    </div>
  </localized-link>
</template>

<style lang="scss" scoped>
.provider-card {
  --provider-card-width: 224px;
  --provider-card-height: 90px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--provider-card-width);
  height: var(--provider-card-height);
  border-radius: 8px;
  border: 1px solid var(--dark7);
  background-color: var(--dark5);
  box-shadow: 0px 8px 12px 0px rgba(20, 21, 24, 0.15);
  transition: background-color .3s ease;

  @include media-lg() {
    width: 154px;
    height: 70px;
  }

  &:hover {
    background-color: var(--dark7);
  }
}
.provider-card__image-wp {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.provider-card__image {
  width: 100%;
}
</style>
