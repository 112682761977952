import { createWebHistory, createRouter } from "vue-router"
import { createLangRouter } from "vue-lang-router"
import { getI18nOptions } from "@/translations/i18nOptions"
//import HomePage from '../components/pages/HomePage.vue'
import HomePageRedesign from '../components/pages/home_redesign/HomePage.vue'
import Layout from './../components/games/Layout.vue'
import NotFound from '@/components/pages/static/NotFoundView.vue'
import translations from '../translations'
//
import { CabinetRoutes } from './cabinet/index'


const routes = [
  ...CabinetRoutes,
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Home',
        // component: HomePage
        component: HomePageRedesign
      },
      {
        path: 'dice',
        name: 'Dice',
        component: () => import('../components/games/Dice/Dice.vue'),
        meta: {
          title: 'WINDICE - Play in the Best Bitcoin (Crypto) Dice Game with a Provably Fair Algorithm by WinDice',
          description: 'Make bets and enjoy one of the best game in gambling industry. You can win bitcoins or any other cryptocurrency. Stop wasting time and start playing!'
        }
      },
      {
        path: 'crash',
        name: 'Crash',
        component: () => import('../components/games/Crash/Crash.vue'),
        meta: {
          title: 'WINDICE - Play in the Best Bitcoin (Crypto) Crash Game with a Provably Fair Algorithm by WinDice',
          description: 'All players love to play at our Crash Game! Why don\'t you become one of them? Bet your bitcoins or other cryptocurrencies and try to make the most of it!'
        }
      },
      {
        path: 'plinko',
        name: 'Plinko',
        component: () => import('../components/games/Plinko/Plinko.vue'),
        meta: {
          title: 'WINDICE - Play at the Best Bitcoin (Crypto) Plinko Game with a Provably Fair Algorithm by WinDice',
          description: 'Have you ever heard of a Plinko game? Although it isn\'t very famous it is one of the most profitable games where you can win a lot bitcoins or other cryptocurrencies!'
        }
      },
      {
        path: 'wheel',
        component: () => import('../components/games/Wheel/WheelLayout.vue'),
        meta: {
          title: 'WINDICE - Play at the Best Bitcoin (Crypto) Wheel Game with a Provably Fair Algorithm by WinDice',
          description: 'Bitcoin wheel is one of the most popular games where anyone can win a lot of bitcoins with a help of luck! Click HERE to start playing at it now.'
        },
        children: [
          {
            path: '',
            name: 'Wheel',
            component: () => import('../components/games/Wheel/Wheel.vue')
          },
          {
            path: 'history',
            name: 'WheelHistory',
            component: () => import('../components/games/Wheel/WheelHistory.vue')
          },
        ]
      },

      {
        path: 'roulette',
        name: 'Roulette',
        component: () => import('../components/games/Roulette/Roulette.vue'),
        meta: {
          title: 'WINDICE - Play at the Best Bitcoin (Crypto) Roulette Game with a Provably Fair Algorithm by WinDice',
          description: 'Bitcoin roulette is one of the most popular games where anyone can win a lot of bitcoins with a help of luck! Click HERE to start playing at it now.'
        }
      },
    ]
  },
  {
    path: '/game',
    component: () => import('./../components/casino_redesign/GameLayout.vue'),
    children: [
      {
        path: ':provider/:name',
        name: 'CasinoGameDemo',
        props: true,
        component: () => import('./../components/casino_redesign/Game.vue')
      },
      {
        path: ':provider/:name/:currency/:currencyPlay',
        name: 'CasinoGame',
        props: true,
        component: () => import('./../components/casino_redesign/Game.vue')
      },
    ]
  },
  {
    path: '/casino/provider/:provider(\\w+)',
    name: 'CasinoProvider',
    props: true,
    component: () => import('../components/casino_redesign/CasinoPageView.vue')
  },
  {
    path: '/casino/providers',
    name: 'CasinoProviders',
    props: true,
    component: () => import('../components/casino_redesign/ProvidersPageView.vue')
  },
  {
    path: '/casino/category/:category(\\w+)',
    name: 'CasinoCategory',
    props: true,
    component: () => import('../components/casino_redesign/CasinoPageView.vue')
  },
  {
    path: '/bonuses',
    name: 'Bonuses',
    component: () => import('@/components/pages/bonuses_redesign/BonusesPage.vue'),
    meta: {
      title: 'WINDICE - Casino Bonuses (Free Bitcoins, Daily Bonuses, Challenges & Tips)',
      description: 'Want to get the some free bonuses to keep playing? This bitcoin (crypto) casino bonuses will make your gaming longer! More information here...'
    }
  },
  {
    path: '/invest',
    component: () => import('./../components/invest/LayoutPublic.vue'),
    meta: {
      title: 'WINDICE - Invest',
      description: 'Crypto currency invest program'
    },
    children: [
      {
        path: '',
        name: 'InvestPublic',
        component: () => import('./../components/invest/Promo.vue'),
      },
      {
        path: 'statistics',
        name: 'InvestStatistics',
        component: () => import('./../components/invest/Statistics.vue'),
      },
      {
        path: 'info',
        name: 'InvestInformation',
        component: () => import('./../components/invest/Info.vue'),
      }
    ]
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('../components/pages/faq_redesign/FaqPage.vue'),
    meta: {
      title: 'WINDICE - FAQ',
      description: 'Windice faq and tutorials'
    }
  },
  {
    path: '/vip',
    name: 'VIP',
    component: () => import('../components/pages/vip_redesign/Vip.vue'),
    meta: {
      title: 'WINDICE - VIP',
      description: 'VIP program'
    }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('@/components/pages/static/TermsView.vue'),
    meta: {
      title: 'WINDICE - Terms of service',
      description: 'Windice terms of service'
    }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('@/components/pages/static/PrivacyView.vue'),
    meta: {
      title: 'WINDICE - Privacy policy',
      description: 'Windice privacy policy and rules'
    }
  },
  {
    path: '/affiliate',
    name: 'Affiliate',
    component: () => import('../components/pages/partner_redesign/Partner.vue'),
    meta: {
      title: 'WINDICE - Affiliate',
      description: 'Windice Affiliate program'
    }
  },
  {
    path: '/docsapi',
    name: 'Api',
    component: () => import('@/components/pages/static/ApiView.vue'),
    meta: {
      title: 'WINDICE - API documentation',
      description: 'WINDICE API documentation'
    }
  },
  //{
  //  path: '/bonus/video',
  //  name: 'Bonus Video',
  //  component: () => import('./../components/pages/BonusVideo')
  //},
  {
    path: '/stats',
    //name: 'Stats',
    component: () => import('../components/pages/stats_redesign/Stats.vue'),
    redirect: '/stats/profit/day/btc',
  },
  {
    path: '/stats/:type/:period/:curr',
    name: 'Stats',
    props: true,
    component: () => import('../components/pages/stats_redesign/Stats.vue'),
    meta: {
      description: 'Windice rating and users stats'
    }
  },
  {
    path: '/contest',
    //name: 'Contest',
    component: () => import('../components/contest_redesign/Contest.vue'),
    meta: {
      description: 'Contest dice tourney game'
    },
    children: [
      {
        path: ':type',
        props: true,
        component: () => import('../components/contest_redesign/Current.vue'),
      },
      {
        path: ':type/history',
        props: true,
        component: () => import('../components/contest_redesign/History.vue'),
      },
    ]
  },
  {
    path: '/tourney',
    name: 'Tourney',
    component: () => import('./../components/tourney/Tourney.vue'),
    children: [
      {
        path: ':type',
        props: true,
      }
    ]
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import('./../components/help/Help.vue')
  },
  {
    path: '/help/:hash',
    name: 'HelpOpen',
    component: () => import('./../components/help/HelpOpen.vue'),
    props: true
  },
  {
    path: '/help/:email/:token',
    name: 'HelpOpenToken',
    component: () => import('./../components/help/HelpOpen.vue'),
    props: true
  },

  {
    path: '/admin',
    component: () => import('./../components/admin/Layout.vue'),
    meta: {
      requiresAuth: true,
      requiresModer: true
    },
    children: [
      {
        path: 'dashboard',
        component: () => import('./../components/admin/Dashboard.vue'),
        meta: { requiresModer: true },
      },
      {
        path: 'users',
        component: () => import('./../components/admin/Users.vue'),
        meta: { requiresModer: true },
      },
      {
        path: 'online',
        component: () => import('./../components/admin/Online.vue'),
        meta: { requiresModer: true },
      },
      {
        path: 'user/:hash',
        component: () => import('./../components/admin/User.vue'),
        props: true,
        meta: { requiresModer: true },
      },
      {
        path: 'help',
        component: () => import('./../components/admin/Tickets.vue'),
        meta: { requiresAdmin: true },
      },
      {
        path: 'help/:hash',
        component: () => import('./../components/admin/TicketOpen.vue'),
        props: true,
        meta: { requiresAdmin: true },
      },
      {
        path: 'trans',
        component: () => import('./../components/admin/LayoutTrans.vue'),
        //meta: {requiresModer: true },
        children: [
          {
            path: 'payIn',
            component: () => import('./../components/admin/PayIn.vue'),
            meta: { requiresAdmin: true },
          },
          {
            path: 'payOut',
            component: () => import('./../components/admin/PayOut.vue'),
            meta: { requiresAdmin: true },
            children: [
              {
                path: 'sort/:sortMethod',
                component: () => import('./../components/admin/PayOut.vue'),
                meta: { requiresAdmin: true },
              }
            ],
          },
          {
            path: 'tips',
            component: () => import('./../components/admin/Tips.vue'),
            meta: { requiresAdmin: true },
            children: [
              {
                path: 'sort/:sortMethod',
                component: () => import('./../components/admin/Tips.vue'),
                meta: { requiresAdmin: true },
              }
            ],
          },
          {
            path: 'requests',
            component: () => import('./../components/admin/Requests.vue'),
            meta: { requiresModer: true },
          },
          {
            path: 'bans',
            component: () => import('./../components/admin/Bans.vue'),
            meta: { requiresAdmin: true },
          },
          {
            path: 'bets',
            component: () => import('./../components/admin/Bets.vue'),
            meta: { requiresAdmin: true },
          },
          {
            path: 'exchange',
            component: () => import('./../components/admin/Exchange.vue'),
            meta: { requiresAdmin: true },
          },
          {
            path: 'faucet',
            component: () => import('./../components/admin/Faucet.vue'),
            meta: { requiresAdmin: true },
          },
          {
            path: 'trivia',
            component: () => import('./../components/admin/Trivia.vue'),
            meta: { requiresModer: true },
          },
          {
            path: 'contests',
            component: () => import('./../components/admin/Contests.vue'),
            meta: { requiresModer: true },
          },
          {
            path: 'rain',
            component: () => import('./../components/admin/Rain.vue'),
            meta: { requiresModer: true },
          },
        ]
      },
    ]
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
    meta: {
      title: 'WINDICE - Error 404'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
]

// page with all components, icons & colors
if (import.meta.env.DEV) {
  routes.push({
    path: '/dev',
    name: 'dev',
    component: () => import('../dev-view/DevView.vue')
  })
}


const langRouterOptions = {
  defaultLanguage: 'en',
  translations,
  i18nOptions: getI18nOptions()
}

const routerOptions = {
  history: createWebHistory(),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {

    // don't scroll when only hash change
    const isChangedHashOnly = to.name === from.name && to.hash !== from.hash
    if (isChangedHashOnly) return savedPosition

    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, behavior: 'smooth' }
    }
  },
}

const router = createLangRouter(langRouterOptions, routerOptions)
//const router = createRouter(routerOptions)

export default router
