<template>
  <div class="notif-block">

    <transition-group name="list" tag="div">
      <div v-for="(item) in notifData" class="notification" :class="'is-' + item.type + (item.text.isPay?' notif-pay':'')" :key="item.index">
        <button v-if="item.isClose" class="delete" @click="closeNotif(item.index)"></button>
        <div v-if="!item.text.isMessage">
          {{item.text}}
        </div>
        <div v-else-if="item.text.isPay">
          <div class="is-flex"><cryptoicon :symbol="item.text.curr" size="30" /></div>
          <div>
            <div class="notif-pay-title">{{item.text.type}}</div>
            <div class="notif-pay-amount">{{parseFloat((item.text.amount / 1000).toFixed(8))}}</div>
          </div>
          <div><a @click="sendChat(item.text.hash, item.text.type)" class="share-link tooltip" :data-tooltip="$t('global.share')"><font-awesome-icon :icon="['far', 'comment']" /></a></div>
        </div>
        <div v-else-if="item.text.isLevel" class="notif-level-up" :class="getLevelMedalByLevel(item.text.level)">
          <div class="notif-destroy-icon">
            <img :src="getVipMedalImage(getLevelMedalByLevel(item.text.level))" />
            <div class="vip-medal-level">{{item.text.level}}</div>
          </div>
          <div class="notif-destroy-text">
            <div>Congratulations</div>
            <div><span>Level {{item.text.level}}</span> reached!</div>
          </div>
        </div>
        <div v-else>
          <div class="notif-message-block columns is-gapless is-mobile">
            <div class="column is-narrow">
              <div class="avatar"><img :src="$filters.printAvatar(item.text.user.avatar)" v-if="item.text.user.avatar !== ''" alt=""></div>
              <div class="rating">
                  <span v-for="n in 5" :key="n" class="icon">
                    <font-awesome-icon :icon="[(item.text.user.rating < n?'far':'fas'), 'star']"/>
                  </span>
              </div>
            </div>
            <div class="column text-block"><div class="text-nick">{{item.text.user.username}}</div><div class="text-data">{{item.text.text}}</div></div>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import vip from "@/components/vip/vip"

export default {
  name: "Notifications",
  mixins: [ vip ],
  computed: {
    notifData: function () {
      return this.$store.getters.getNotifData
    },
  },
  methods: {
    getVipMedalImage: function(name) {
      return new URL(`/src/assets/vip/medal_${name}.svg`, import.meta.url).href
    },
    closeNotif: function (index) {
      this.$store.commit('closeNotif', index)
    },
    sendChat: function(hash, type) {
      let text = '>'
      if (type === 'Payout') text = '<'
      this.$store.commit('shareChat', text + hash)
    },
  }
}
</script>

<style lang="scss" scoped>
  .notif-block{
    position: fixed;
    width: 320px;
    top: 10px;
    right: 10px;
    z-index: 100;
    .notification {
      margin-bottom: 10px;
      box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.50);
      &.is-info {
        padding: 5px;
        > div{
          padding-right: 20px;
        }
      }
      &.is-level {
        background-color: #3e3e42;
        padding: 1rem 2.5rem 1rem 1rem;

        .notif-level-up {
          display: flex;

          .notif-destroy-icon {
            display: flex;
            justify-content: center;
            position: relative;

            img {
              height: 60px;
            }

            .vip-medal-level {
              position: absolute;
              font-weight: bold;
              font-size: 16px;
              top: 10px;
            }
          }

          .notif-destroy-text {
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            > div {
              width: 100%;
              text-align: left;

              > span {
                font-weight: bold;
                font-size: 18px;
              }

              &:first-of-type {
                color: #00F6FF;
              }
            }
          }
        }
      }
    }

    .notif-message-block .avatar{
      height: 45px;
      width: 45px;
      background: url('./../assets/avatar_default.svg') no-repeat;
      margin: 0 3px;
    }
    .notif-message-block .avatar img{
      border-radius: 45px;
    }
    .notif-message-block .rating{
      margin-top: 4px;
      font-size: 8px;
      color: #5c6b78;
    }
    .notif-message-block .rating .icon{
      width: 10px;
      height: 10px;
    }
    .notif-message-block .text-block {
      margin-left: 5px;
      max-width: 254px;
      word-wrap: break-word;
      font-size: 14px;
    }
    .notif-message-block .text-nick {
      font-weight: 700;
    }

    .notif-pay {
      color: #000;
      text-transform: uppercase;
      background: #5c6 url("data:image/svg+xml,%3Csvg width='180' height='180' viewBox='0 0 180 180' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M81.28 88H68.413l19.298 19.298L81.28 88zm2.107 0h13.226L90 107.838 83.387 88zm15.334 0h12.866l-19.298 19.298L98.72 88zm-32.927-2.207L73.586 78h32.827l.5.5 7.294 7.293L115.414 87l-24.707 24.707-.707.707L64.586 87l1.207-1.207zm2.62.207L74 80.414 79.586 86H68.414zm16 0L90 80.414 95.586 86H84.414zm16 0L106 80.414 111.586 86h-11.172zm-8-6h11.173L98 85.586 92.414 80zM82 85.586L87.586 80H76.414L82 85.586zM17.414 0L.707 16.707 0 17.414V0h17.414zM4.28 0L0 12.838V0h4.28zm10.306 0L2.288 12.298 6.388 0h8.198zM180 17.414L162.586 0H180v17.414zM165.414 0l12.298 12.298L173.612 0h-8.198zM180 12.838L175.72 0H180v12.838zM0 163h16.413l.5.5 7.294 7.293L25.414 172l-8 8H0v-17zm0 10h6.613l-2.334 7H0v-7zm14.586 7l7-7H8.72l-2.333 7h8.2zM0 165.414L5.586 171H0v-5.586zM10.414 171L16 165.414 21.586 171H10.414zm-8-6h11.172L8 170.586 2.414 165zM180 163h-16.413l-7.794 7.793-1.207 1.207 8 8H180v-17zm-14.586 17l-7-7h12.865l2.333 7h-8.2zM180 173h-6.613l2.334 7H180v-7zm-21.586-2l5.586-5.586 5.586 5.586h-11.172zM180 165.414L174.414 171H180v-5.586zm-8 5.172l5.586-5.586h-11.172l5.586 5.586zM152.933 25.653l1.414 1.414-33.94 33.942-1.416-1.416 33.943-33.94zm1.414 127.28l-1.414 1.414-33.942-33.94 1.416-1.416 33.94 33.943zm-127.28 1.414l-1.414-1.414 33.94-33.942 1.416 1.416-33.943 33.94zm-1.414-127.28l1.414-1.414 33.942 33.94-1.416 1.416-33.94-33.943zM0 85c2.21 0 4 1.79 4 4s-1.79 4-4 4v-8zm180 0c-2.21 0-4 1.79-4 4s1.79 4 4 4v-8zM94 0c0 2.21-1.79 4-4 4s-4-1.79-4-4h8zm0 180c0-2.21-1.79-4-4-4s-4 1.79-4 4h8z' fill='%23000000' fill-opacity='0.6' fill-rule='evenodd'/%3E%3C/svg%3E");
      background-size: 50px;
      box-shadow: inset 0 0 7px 0 rgba(0,0,0,0.6);
      padding: 15px;
    }
    .notif-pay > div {
      display: flex;
      justify-content: center;
      align-items: center;

    }
    .notif-pay > div > div {
      line-height: 16px;
      text-align: center;
      font-weight: bold;

      margin: 0 5px;
      padding: 3px;
    }
    .notif-pay-title, .notif-pay-amount{
      background-color: rgba(85, 204, 102, 0.7);
    }
    .notif-pay-amount{
      font-size: 16px;
    }
    .notif-pay > div > div:first-of-type {
      background-color: rgba(85, 204, 102, 0.7);

      border-radius: 60px;
    }
    .notif-pay > div > div:last-of-type {
      background-color: #2a2a2c;
      color: #FFF;
      padding: 6px;
      font-size: 22px;
      border-radius: 60px;
      margin-left: 20px;
    }
  }

</style>
