const Win = {
  symbol: 'win',
  color: '#000',
  plainIcon: c => {
    return `<g>
        <circle cx="16" cy="16" r="16" fill="#e9b444"/>
        <circle cx="16" cy="16" r="13.02" fill="#db9126"/>
        <path d="M11,15.81h1.73v1.71H11Zm4.14,3.74h1.74V17.84H15.13ZM11,13.44h1.74v-1.7H11Zm0,6.09h1.73v-1.7H11Zm4.15,2h1.73V19.88H15.12ZM11,11.42h1.72V9.7H11Zm0,10.14h1.7V19.85H11Zm2.06,2H14.8V21.9H13ZM11,15.46h1.7v-1.7H11Zm-2,2h1.68V15.8H9Zm0,2h1.66v-1.7H9Zm0,2h1.66V19.85H9Zm0-8.13h1.68V11.72H9Zm0,2.05h1.68V13.76H9ZM9,11.4h1.69V9.7H9ZM11,23.59H12.7v-1.7H11ZM21.38,9.75v1.69H23.1V9.74Zm0,11.86H23V19.9H21.35Zm0-2H23V17.92H21.36Zm-2.08-6.1H21V11.76H19.29Zm0-2H21V9.74H19.3Zm2.08,6.11H23V15.83H21.36Zm0-4.06H23.1v-1.7H21.38Zm0,2h1.74v-1.7H21.36Zm-2.09,4H21V17.92H19.28ZM17.2,23.62h1.74V21.91H17.2Zm2.1-8.12H21V13.8H19.29Zm-4.16,0h1.73V13.79H15.13Zm4.14,8.1H21V21.93H19.28Zm0-6.09H21V15.83H19.29Zm-4.15,0h1.73v-1.7H15.13Zm4.15,4.08H21v-1.7H19.28Z" fill="#7a4a24"/>
        <path d="M3,16A12.62,12.62,0,0,1,3.84,11,13.23,13.23,0,0,1,6.63,6.63,13,13,0,0,1,21.12,3.7a13.16,13.16,0,0,1,4.29,2.93A13.34,13.34,0,0,1,28.16,11,12.67,12.67,0,0,1,29,16a13.3,13.3,0,0,0-4-9,13,13,0,0,0-18,0A13.29,13.29,0,0,0,3,16Z" fill="#9d612d"/>
    </g>`;
  },
  colorIcon() {
    return `
    <g>
        <circle cx="16" cy="16" r="16" fill="#e9b444"/>
        <circle cx="16" cy="16" r="13.02" fill="#db9126"/>
        <path d="M11,15.81h1.73v1.71H11Zm4.14,3.74h1.74V17.84H15.13ZM11,13.44h1.74v-1.7H11Zm0,6.09h1.73v-1.7H11Zm4.15,2h1.73V19.88H15.12ZM11,11.42h1.72V9.7H11Zm0,10.14h1.7V19.85H11Zm2.06,2H14.8V21.9H13ZM11,15.46h1.7v-1.7H11Zm-2,2h1.68V15.8H9Zm0,2h1.66v-1.7H9Zm0,2h1.66V19.85H9Zm0-8.13h1.68V11.72H9Zm0,2.05h1.68V13.76H9ZM9,11.4h1.69V9.7H9ZM11,23.59H12.7v-1.7H11ZM21.38,9.75v1.69H23.1V9.74Zm0,11.86H23V19.9H21.35Zm0-2H23V17.92H21.36Zm-2.08-6.1H21V11.76H19.29Zm0-2H21V9.74H19.3Zm2.08,6.11H23V15.83H21.36Zm0-4.06H23.1v-1.7H21.38Zm0,2h1.74v-1.7H21.36Zm-2.09,4H21V17.92H19.28ZM17.2,23.62h1.74V21.91H17.2Zm2.1-8.12H21V13.8H19.29Zm-4.16,0h1.73V13.79H15.13Zm4.14,8.1H21V21.93H19.28Zm0-6.09H21V15.83H19.29Zm-4.15,0h1.73v-1.7H15.13Zm4.15,4.08H21v-1.7H19.28Z" fill="#7a4a24"/>
        <path d="M3,16A12.62,12.62,0,0,1,3.84,11,13.23,13.23,0,0,1,6.63,6.63,13,13,0,0,1,21.12,3.7a13.16,13.16,0,0,1,4.29,2.93A13.34,13.34,0,0,1,28.16,11,12.67,12.67,0,0,1,29,16a13.3,13.3,0,0,0-4-9,13,13,0,0,0-18,0A13.29,13.29,0,0,0,3,16Z" fill="#9d612d"/>
    </g>`;
  }
};
export default Win;
