<script>
import vip from "../vip/vip"
export default {
  name: "UserLevel",
  props: {
    width: {type: [String, Number]},
    height: {type: [String, Number]},
    xp: {type: [String, Number]},
  },
  mixins: [vip],
  data() {
    return {
      levelName: null,
    }
  },
  created() {
    this.levelName = this.getLevelName(this.xp).toLowerCase()
  },
}
</script>

<template>
  <div class="level" :class="levelName" :style="{width, height }">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 22.45" :style="{ width, height }">
      <defs>
        <radialGradient id="gradient-newbie" cx="9.87" cy="11.14" r="8.47" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#FFF" stop-opacity="0"/>
          <stop offset="1.00" stop-color="#fffccc"/>
        </radialGradient>
        <radialGradient id="gradient-copper" cx="9.87" cy="11.14" r="8.47" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#c99473" stop-opacity="0"/>
          <stop offset="1.00" stop-color="#7a5944"/>
        </radialGradient>
        <radialGradient id="gradient-bronze" cx="9.87" cy="11.14" r="8.47" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#c76f47" stop-opacity="0"/>
          <stop offset="1.00" stop-color="#782e10"/>
        </radialGradient>
        <radialGradient id="gradient-silver" cx="9.87" cy="11.14" r="8.47" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#a1a1a4" stop-opacity="0"/>
          <stop offset="1.00" stop-color="#4d4d4d"/>
        </radialGradient>
        <radialGradient id="gradient-gold" cx="9.87" cy="11.14" r="8.47" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#bf9c47" stop-opacity="0"/>
          <stop offset="1.00" stop-color="#a97c28"/>
        </radialGradient>
        <radialGradient id="gradient-platinum" cx="9.87" cy="11.14" r="8.47" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#84a3c9" stop-opacity="0"/>
          <stop offset="1.00" stop-color="#6d86a5"/>
        </radialGradient>
        <radialGradient id="gradient-diamond" cx="9.87" cy="11.14" r="8.47" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#000" stop-opacity="0"/>
          <stop offset="1.00" stop-color="#2e71b5"/>
        </radialGradient>
      </defs>
      <path class="a" d="M20,15.8V6.65a2.09,2.09,0,0,0-1-1.8L11,.28A2.09,2.09,0,0,0,9,.28L1,4.85a2.09,2.09,0,0,0-1,1.8V15.8a2.09,2.09,0,0,0,1,1.8L9,22.17a2.09,2.09,0,0,0,2.08,0L19,17.6A2.09,2.09,0,0,0,20,15.8Z"/>
      <path class="b" d="M10.87,14.08,17,10.56a1.62,1.62,0,0,1,.74-.21V7.7a1.63,1.63,0,0,0-.8-1.39L10.8,2.79a1.52,1.52,0,0,0-1-.18V6.8A1.6,1.6,0,0,1,9,8.18L2.85,11.71a1.54,1.54,0,0,1-.56.18v2.86a1.6,1.6,0,0,0,.8,1.38L9.2,19.66a1.67,1.67,0,0,0,.87.21v-4.4A1.6,1.6,0,0,1,10.87,14.08Z"/>
      <path class="b" d="M16.91,16.13a1.6,1.6,0,0,0,.8-1.38v-4.4a1.62,1.62,0,0,0-.74.21l-6.1,3.52a1.6,1.6,0,0,0-.8,1.39v4.4a1.75,1.75,0,0,0,.73-.21Z"/>
      <path class="c" d="M17.84,14.79V7.49A1.66,1.66,0,0,0,17,6.06L10.69,2.42A1.62,1.62,0,0,0,9,2.42L2.72,6.06A1.65,1.65,0,0,0,1.9,7.49v7.3a1.65,1.65,0,0,0,.82,1.43L9,19.87a1.67,1.67,0,0,0,1.65,0L17,16.22A1.66,1.66,0,0,0,17.84,14.79Z"/>
      <text class="d user-level-value" x="50%" y="55%" dominant-baseline="middle" text-anchor="middle">{{getLevel(xp)}}</text>
    </svg>
  </div>
</template>

<style lang="scss" scoped>
.b { opacity:0.66; }
.b,.d {isolation:isolate;}
.d {
  font-size: 10px;
  color: #202020;
  font-family: Roboto-Bold,"sans-serif",Arial;
  font-weight: bold;
}
.level {
  display: flex;
  z-index: 2;
}
.level {
  .a { fill: #393939; }
  .b { fill: #ffffff; }
  .c { fill: url(#gradient-newbie); }

  &.copper {
    .a { fill:#c1906e; }
    .b { fill:#e0b188; }
    .c { fill:url(#gradient-copper); }
  }

  &.bronze {
    .a { fill:#cf4608; }
    .b { fill:#ffaa85; }
    .c { fill:url(#gradient-bronze); }
  }

  &.silver {
    .a { fill:#a1a1a5; }
    .b { fill:#e5e5e5; }
    .c { fill:url(#gradient-silver); }
  }

  &.gold {
    .a { fill:#ffc800; }
    .b { fill:#ffe67d; }
    .c { fill:url(#gradient-gold); }
  }

  &.platinum {
    .a { fill: #a1b9d6; }
    .b { fill: #cfd7f9; }
    .c { fill:url(#gradient-platinum); }
  }

  &.diamond {
    .a { fill: #0074bf; }
    .b { fill: #21a9ff; }
    .c { fill:url(#gradient-diamond); }
  }
}
</style>
