const moduleTourney = {
  state: {
    tourneyTeam: -1,
    tourneyActive: false,
    tourneyStats: null,
  },
  getters: {
    getTourneyTeam: state => state.tourneyTeam,
    getTourneyTeamClass: state => {
      switch (state.tourneyTeam) {
        case 0:
          return 'tourney-blue'
        case 1:
          return 'tourney-red'
        default:
          return false
      }
    },
    getTourneyActive: state => state.tourneyActive,
    getTourneyStats: state => state.tourneyStats,
  },
  mutations: {
    SOCKET_tourney_team: (state, data) => {
      state.tourneyTeam = data.team
    },
    SOCKET_tourney_active: (state, data) => {
      state.tourneyActive = data
    },
    SOCKET_tourney_stats: (state, data) => {
      state.tourneyStats = data
    },
    tourney_team: (state, data) => state.tourneyTeam = data
  }
}
export default moduleTourney
