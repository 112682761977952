<script>
export default {
  name: "Balances",
  data() {
    return {
      isOpenBalance: false,
      lockOpenBalance: false,
    }
  },
  computed: {
    curr: function () {
      return this.$store.getters.getSelectCurr
    },
    getBalanceCurrent: function () {
      if (this.$store.getters.isAuth) {
        return this.$store.getters.getAuthData.balance[this.curr] / 1000
      } else {
        return 0
      }
    },
    getBalance: function () {
      if (this.$store.getters.isAuth) {
        if (this.real) {
          return this.$store.getters.getBalanceReal
        } else {
          return this.$store.getters.getBalance
        }
      } else {
        return {'btc': 0}
      }
    },
  },
  beforeUnmount() {
    if (this.isOpenBalance === true) {
      window.removeEventListener('click', this.handleBalanceClick)
      window.removeEventListener('touchstart', this.handleBalanceClick)
    }
  },
  methods: {
    printBalance(balance) {
      if (balance <= 0) balance = 0
      return balance.toFixed(8)
    },
    changeCurr: function (currencyName) {
      this.$store.commit('changeSelectCurr', currencyName)

      this.toggleBalance(false)
      this.lockOpenBalance = true
      setTimeout(()=>{ this.lockOpenBalance = false }, 250)
    },
    toggleBalance: function (data) {
      if (this.lockOpenBalance === true) return

      if (data !== undefined && (data === true || data === false)){
        this.isOpenBalance = data
      } else {
        this.isOpenBalance = !this.isOpenBalance
      }

      if (this.isOpenBalance === true) {
        window.addEventListener('click', this.handleBalanceClick)
        window.addEventListener('touchstart', this.handleBalanceClick)
      } else {
        window.removeEventListener('click', this.handleBalanceClick)
        window.removeEventListener('touchstart', this.handleBalanceClick)
      }
    },
    handleBalanceClick: function(e) {
      const elem = document.getElementById('balanceBlock')

      if(!elem.contains(e.target)) {
        this.toggleBalance()
      }
    },
  }
}
</script>

<template>
  <div class="balances">
    <div class="balances__select"
         id="balanceBlock"
         :class="{ open: isOpenBalance }"
         @click.prevent="toggleBalance"
         @touchstart.prevent="toggleBalance"
         @mouseenter.prevent="toggleBalance(true)"
         @mouseleave.prevent="toggleBalance(false)"
    >
      <cryptoicon class="balances__icon" :symbol="curr" size="20"/>
      <span class="balances__count">{{printBalance(getBalanceCurrent)}}</span>
      <font-awesome-icon icon="chevron-down"  class="balances__arrow" />
    </div>

    <transition name="fade-opacity">
      <div v-show="isOpenBalance" class="balances__dropdown">
        <div
            class="balances__list-wrap"
            @mouseenter.prevent="toggleBalance(true)"
            @mouseleave.prevent="toggleBalance(false)"
        >
          <ul class="balances__list">
            <li
                v-for="(value, index) in getBalance"
                @click.prevent="changeCurr(index)"
                @touchstart.prevent="changeCurr(index)"
                :key="index"
                class="balances__item"
                :class="{hidden: index === curr}"
            >
              <span v-if="index !== curr" class="balances__text">
                <cryptoicon :symbol="index" size="20" class="balances__icon" />
                {{printBalance(value / 1000)}}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.fade-opacity-enter-active,
.fade-opacity-leave-active {
  transition: all .5s ease;
}
.fade-opacity-enter,
.fade-opacity-leave-to {
  opacity: 0;
}


.balances {
  position: relative;
}
.balances__select {
  display: flex;
  align-items: center;
  padding: 10px 18px 10px 12px;
  cursor: pointer;
  border-radius: 8px 0 0 8px;
  transition: background-color .2s ease-in-out;

  @include media-lg() {
    padding: 6px 8px;
  }

  &.open {
    background-color: var(--dark2);
  }
}
.balances__icon {
  margin-right: 8px;
}
.balances__count {
  color: var(--white);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 10px;
  margin-right: 8px;
}
.balances__arrow {
  color: var(--gray4);
  font-size: 10px;
}
.balances__dropdown{
  min-width: 100%;
  position: absolute;
  z-index: 41;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--dark5);
  border: 1px solid var(--dark7);
  border-radius: 8px;
  box-shadow: 0px 8px 12px rgba(20, 21, 24, 0.15);
}
.balances__item {
  &.hidden {
    display: none;
  }
}
.balances__text {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  background-color: transparent;
  transition: background-color .2s ease-in-out;
  padding: 8px 12px;

  &:hover {
    background-color: var(--dark2);
  }
}
.balances__icon {
  margin-right: 8px;
}
</style>
