<script>
import SearchLoader from "@/components/navbars_redesign/search/SearchLoader.vue";
import GameCard from "@/components/casino_redesign/game-card/GameCard.vue";

export default {
  name: "SearchError",
  components: {GameCard, SearchLoader},
  props: ['text', 'searchError', 'isLoadingPopularSearch', 'popularSearchList', 'popularSearchError'],
  computed: {
    isNotFoundError() {
      return this.searchError === 'Games not found'
    }
  }
}
</script>

<template>
  <div class="search-result">
    <div v-if="isNotFoundError" class="search-not-found">
      <div class="search-not-found__title">{{ $t('casino.search_not_found', { text }) }}</div>
      <div>{{ $t('casino.try_different') }}</div>
    </div>
    <div v-else class="search-error">{{ searchError }}</div>


    <!-- popular_searches -->
    <div class="search__result-category">
      <div class="search__result-title-wp">
        <localized-link class="search__result-title" :to="{name: 'CasinoCategory', params: {category: 'popular'}}">
          {{ $t(`casino.search_popular`) }}
        </localized-link>
      </div>

      <SearchLoader v-if="isLoadingPopularSearch" :text="$t('casino.loading')" />
      <div v-else-if="!popularSearchError" class="search-cards" >
        <div v-for="(game, key) in popularSearchList" :key="key">
          <GameCard :game="game" />
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" scoped>
.search-result {
  width: 100%;
  text-align: left;
}
.search-error {
  font-size: 18px;
  font-weight: bold;
  padding-left: 20px;
}
.search-not-found {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.search-not-found__title {
  font-size: 16px;
  font-weight: bold;
}

.search__result-title-wp {
  text-align: left;
}
.search__result-category {
  margin-top: 20px;
}
</style>
