const moduleCasino = {
  state: {
    providers: [
      //"1x2gaming",
      "alg",
      "amatic",
      "belatra",
      "bgaming",
      "betsoft",
      "booming",
      "evolution",
      "evoplay",
      "ezugi",
      "fugaso",
      "gameart",
      "habanero",
      "igrosoft",
      //"igtech",
      "mrslotty",
      "nucleus",
      //"onlyplay",
      //"platipus",
      "pragmaticplay",
      "quickspin",
      "spinomenal",
      "tomhorn",
      //"truelab",
      //"vivogaming",
      "wazdan",
      //"yggdrasil",
      "endorphina",
      "playngo",
      "playtech",
      "hacksaw"
    ],
    categories: ["popular", "new", "card", "roulette", "slots", "live"],
    sorting: localStorage.getItem('search-sort') === null?'az':localStorage.getItem('search-sort'),
  },
  getters: {
    getProviders: state => state.providers,
    getCategories: state => state.categories,
    getSorting: state => state.sorting,
  },
  mutations: {
    setSorting: (state, value) => {
      state.sorting = value
      localStorage.setItem('search-sort', state.sorting)
    }
  }

}
export default moduleCasino
