<script lang="ts">
import {defineComponent, PropType} from 'vue'

type TVariant = 'primary' | 'yellow' | 'white' | 'gray' | 'outlined-gray' | 'outline-primary'

export default defineComponent({
  name: "BaseButton",
  props: {
    variant: { type: String as PropType<TVariant> },
    disabled: { type: Boolean, default: false },

    // use it to add <router-link to="...">
    to: { type: [String, Object] },

    // use it to add <a href="...">
    href: { type: String },

    // use it to add icon
    iconName: {  type: String },
    iconSize: {  type: [String, Number], default: 20 },
    iconClasses: {  type: String },
  },
  computed: {
    buttonClasses() {
      const arr = ['base-button']
      if (this.disabled) arr.push('base-button--disabled')
      if (this.variant) arr.push(`base-button--${this.variant}`)

      return arr
    },
  },
})
</script>

<template>
  <localized-link v-if="to" :to="to" v-bind="$attrs" :class="buttonClasses" :disabled="disabled">
    <SvgIcon v-if="iconName" :name="iconName" :size="iconSize" class="base-button__icon" :class="iconClasses" />
    <slot></slot>
  </localized-link>

  <a v-else-if="href" :href="href" v-bind="$attrs" :class="buttonClasses" :disabled="disabled">
    <SvgIcon v-if="iconName" :name="iconName" :size="iconSize" class="base-button__icon" :class="iconClasses" />
    <slot></slot>
  </a>

  <button v-else v-bind="$attrs" :class="buttonClasses" :disabled="disabled">
    <SvgIcon v-if="iconName" :name="iconName" :size="iconSize" class="base-button__icon" :class="iconClasses" />
    <slot></slot>
  </button>
</template>

<style scoped lang="scss">
.base-button {
  // for button
  --bg: transparent;
  --color: inherit;
  --border-color: transparent;
  --border-width: 1px;

  // for icon
  --icon-margin-right: 0;
  --icon-color: currentColor;


  cursor: pointer;
  border-radius: 8px;
  border: var(--border-width) solid var(--border-color);
  color: var(--color);
  background: var(--bg);
  min-height: 32px;
  min-width: 32px;
  font-weight: 700;
  line-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  transition: border-color 0.2s ease, opacity 0.2s ease, background 0.2s ease, color 0.2s ease;

  &--primary {
    --bg: var(--primary);
    --color: var(--white);
    --border-color: var(--primary-light);

    &:hover {
      --bg: var(--primary-light);
      --border-color: var(--primary-light);
    }
  }
  &--yellow {
    --border-color: #E7EF31;
    --bg: #E7EF31;
    --color: #141519;

    &:hover {
      opacity: 0.8;
    }
  }
  &--white {
    --bg: var(--white);
    --color: #1B1C1F;

    &:hover {
      opacity: 0.8;
    }
  }
  &--gray {
    --bg: var(--gray);

    &:hover {
      --bg: var(--gray2);
    }
  }
  &--outlined-gray {
    --border-color: var(--gray);
    --border-width: 2px;

    &:hover {
      --border-color: rgba(112, 115, 128, 0.50);
    }
  }
  &--outline-primary {
    --color: var(--primary);
    --border-color: var(--primary);
    --border-width: 1px;

    &:hover {
      --color: var(--white);
      --bg: var(--primary-light);
    }
  }


  &__icon {
    margin-right: var(--icon-margin-right);
    --color: var(--icon-color);
    flex-shrink: 0;
  }

  &--disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
}
</style>
