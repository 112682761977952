<script>
export default {
  name: "Support",
  methods: {
    openIntercom: function() {
      this.$store.commit("openIntercom")
    },
  }
}
</script>

<template>
  <button class="support link" @click="openIntercom">
    <SvgIcon name="chat_dots" size="24" class="support__icon" />
    {{$t('menu.support')}}
  </button>
</template>

<style lang="scss" scoped>
.support {
  color: var(--gray5);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  min-height: 24px;
  width: 100%;
}
.support__icon {
  margin-right: 8px;
}

.link {
  color: var(--white);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
  padding: 12px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 4px;
  transition: background-color .3s ease;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    background-color: var(--dark3);
    color: var(--white);
  }
}
</style>
