const moduleSocket = {
  state: {

  },
  getters: {
  },
  mutations: {

  },
  actions: {

  }

}
export default moduleSocket
