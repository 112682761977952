//import Vue from 'vue'
//import Vuex from 'vuex'
import main from '../main'
import { createStore } from 'vuex'
import axios from "axios"
import moduleSocket from "@/store/socket"
import moduleLive from "./live"
import moduleMessages from "./messages"
import moduleFriends from "./friends"
import moduleTourney from "./tourney"
import moduleVip from "./vip"
import moduleShop from "./shop"
import moduleCasino from "./casino"
//import { ecommerce } from 'vue3-analytics'
import router from '@/router'

import { purchase } from 'vue-gtag'

//Vue.use(Vuex)

//export default new Vuex.Store({
export default createStore({
  modules: {
    moduleSocket,
    moduleLive,
    moduleMessages,
    moduleFriends,
    moduleTourney,
    moduleVip,
    moduleShop,
    moduleCasino,
  },
  state: {
    count: 1,
    authToken: localStorage.getItem('user-token') || '',
    authState: 'loading',
    authData: {
      is_email: false,
      friend_msg: true
    },
    authSec: {
      hash: null,
      data: null,
      load: false
    },
    country: "",
    socketTimeout: null,
    isAdmin: false,
    isApprover: false,
    isModer: false,

    isChatOpen: true,
    isModalBet: false,
    isModalUser: false,
    isModalDeposit: false,
    isModalPayout: false,
    isModalTrivia: false,
    modalWallet: null,
    isFaucetOpen: false,
    isDepositAnimate: false,
    isAuthServerError: false,
    isConnectedSocket: false,
    closedSocketCount: 0,
    isPageLoad: false,
    isAuthSocket: false,
    isCountryBlock: false,
    isTutorialOpen: false,
    chatDeleteBan: null,
    verifyLink: 'https://codepen.io/windice/pen/JjjaWRj',
    name: import.meta.env.VITE_APP_NAME,
    defaultTitle: 'WINDICE - Bitcoin/Cryptocurrency Casino Games (Dice, Roulette & Slots) with Faucet',
    defaultDescription: 'WinDice is one of the best bitcoin (crypto) casinos with generous bonuses, huge variety of games and even bitcoin faucet! Click HERE to start playing now!',
    domains: import.meta.env.VITE_APP_DOMAINS,
    domains_ws: import.meta.env.VITE_APP_WS,
    domains_api: import.meta.env.VITE_APP_API,
    domains_images: import.meta.env.VITE_APP_IMAGES,
    version: localStorage.getItem('version') || null,
    intReloadAuth: null,
    Username: '',
    statMain: {
      online: 0,
      onlineDetail: {
        site: 0,
        api: 0,
        telegram: 0
      },
      bets: 0,
      lastWon: 0,
      lastWonData: [],
      jackpot: 0,
      jackpotData: [],
      bigWin: {}
    },
    statMainLoad: false,
    gameSliderWidth: false,
    selectCurr: 'win',
    currencies: ['win', 'btc', 'eth', 'ltc', 'doge', 'bch', 'xrp', 'bnb', 'trx', 'usdt'],
    gameConfig: {
      minimum: null,
      maximum: null,
    },
    game: {
      // countRoll: 0,
      betType: true, // true - manual game, false - auto game
      gameType: true, // true - in, false - out
      gameResult: [0, 0, 0, 0],
      betAmount: null,
      gameChance: '50.00',
      gamePayout: '1.98',
      winAmount: null,
      pointerLow: 5000,
      pointerHigh: 9999,
      //minimum: null
    },
    gameDiceAuto: {
      onLoss: false,
      onWin: false,
      speed: 'slow',
      count: 0,
      countLoss: 0,
      countWin: 0,
      balanceMin: 0,
      balanceMax: 0
    },
    gameCrash: {
      betType: true,
      betAmount: null,
      gameChance: '50.00',
      gamePayout: '1.98',
      winAmount: null,

      speed: 2
    },
    gameCrashAuto: {
      onLoss: false,
      onWin: false,
      speed: 'slow',
      count: 0,
      countLoss: 0,
      countWin: 0,
      balanceMin: 0,
      balanceMax: 0
    },

    gamePlinko: {
      betType: true,
      betAmount: null,
      gameRisk: null,
      gameRows: null,
    },
    gamePlinkoAuto: {
      onLoss: false,
      onWin: false,
      speed: 'slow',
      count: 0,
      countLoss: 0,
      countWin: 0,
      balanceMin: 0,
      balanceMax: 0
    },
    gameWheel: {
      betAmount: null,
      ticketsCount: null,
    },
    gameRoulette: {
      betType: true,
      betAmount: null,
      betsLast: [],
    },
    gameRouletteAuto: {
      onLoss: false,
      onWin: false,
      speed: 'slow',
      count: 0,
      countLoss: 0,
      countWin: 0,
      balanceMin: 0,
      balanceMax: 0
    },

    gameLogs: {
      maxLogs: 30,
      lastTime: 0,
      main: [],
      self: [],
      high: [],
      jack: []
    },
    gameSettings: {
      sound: false,
      animation: true,
      hotkeys: false,
      maxbet: true,
      games_random: true
    },
    audioData: {
      win: null,
      lose: null,
      notif: null,
      coins: null,
      plinkoButton: null,
      plinkoDrop0: null,
      plinkoDrop1: null,
      plinkoDrop2: null,
      plinkoDrop3: null,
      plinkoDrop4: null,
      plinkoDrop5: null,
      plinkoDrop6: null,
      plinkoDrop7: null,
      plinkoDrop8: null,
      crashButton: null,
      crashJump: null,
      crashWin: null,
      crashLose: null,
      wheelButton: null,
      wheelEnd: null,
      rouletteButton: null,
      rouletteRoll: null,
      rouletteStop: null,
      rouletteStopTurbo: null,
    },
    audioDataNotifications: {
      chat: null,
    },
    chatData: {
      maxMessages: 50,
      selectChatLang: localStorage.getItem('chatLang') || null,
      countMsg: 0,
      isLoad: false,
      msgData: [],
      share: "",
      width: 330,
      languages: [
        {name: 'English',   code: 'en'},
        {name: 'Русский',   code: 'ru'},
        {name: 'Español',   code: 'es'},
        {name: 'Português', code: 'pt'},
        {name: 'Filipino',  code: 'ph'},
        {name: 'Indian',    code: 'in'},
        {name: 'Any',       code: 'any'},
      ]
    },
    notifData: {
      index: 0,
      data: []
    },
    modalBet: {
      hash: ''
    },
    modalUser: {
      hash: ''
    },
    modalTips: {
      isOpen: false,
      username: '',
      hash: ''
    },
    modalRain: {
      isOpen: false
    },
    modalExchange: {
      isOpen: false
    },
    modalAuth: {
      isOpen: false,
      type: 'reg'
    },
    modalChatBan: {
      isOpen: false,
      username: null,
      hash: null
    },
    modalChatUnBan: {
      isOpen: false,
      username: null,
      hash: null
    },
    modalCoupon: {
      isOpen: false,
      defaultValue: '',
    },
    faucet: {
      isLoader: false,
      loaderWidth: 0,
      loadSec: 180,
      loadStop: parseInt(localStorage.getItem('faucet-loader')) || 0,
      loaderTime: '',
      interval: null
    },
    finances: {
      updatePayInOut: false
    },
    socketSubscribes: {
      update_help_data: null,
      update_payout: null,
      chat_send: null,
      roll: null,
      crash: null,
      plinko: null,
      roulette: null,

      wheel: null,
      wheel_new: null,
      wheel_active: null,
      wheel_pool: null,
      wheel_end: null,
    },
    firstLogin: false,
    wheelUsersCount: 0,
    contests: {
      balance_day: null,
      wagering_day: null,
      wagering_week: null,
    },
    openIntercom: 0,
    loadIntercom: import.meta.env.VITE_APP_INTERCOM === 'true',
    lastPing: 0,
    supportWebP: true,
  },
  getters: {
    getLastPing: state => state.lastPing,
    authStatus: state => state.authState,

    getName: state => state.name,
    getDefaultTitle: state => state.defaultTitle,
    getDefaultDescription: state => state.defaultDescription,
    getVersion: state => state.version,
    isAuthSecLoad: state => state.authSec.load,
    getAuthSecHash: state => state.authSec.hash,
    getAuthSec: state => state.authSec,
    getCountry: state => state.country,
    isModalBet: state => state.isModalBet,
    isModalUser: state => state.isModalUser,
    isModalTips: state => state.modalTips.isOpen,
    isModalTrivia: state => state.isModalTrivia,
    isModalRain: state => state.modalRain.isOpen,
    isModalExchange: state => state.modalExchange.isOpen,
    isModalDeposit: state => state.isModalDeposit,
    isModalPayout: state => state.isModalPayout,
    isModalWallet: state => state.modalWallet !== null,
    isModalAuth: state => state.modalAuth.isOpen,
    isModalChatBan: state => state.modalChatBan.isOpen,
    isModalChatUnBan: state => state.modalChatUnBan.isOpen,
    isModalCoupon: state => state.modalCoupon.isOpen,
    getModalWallet: state => state.modalWallet,
    getModalAuthType: state => state.modalAuth.type,
    isChatOpen: state => state.isChatOpen,
    isFaucetOpen: state => state.isFaucetOpen,
    isAuthSocket: state => state.isAuthSocket,
    isConnectedSocket: state => state.isConnectedSocket,
    isPageLoad: state => state.isPageLoad,
    isCountryBlock: state => state.isCountryBlock,
    isTutorialOpen: state => state.isTutorialOpen,
    getVerifyLink: state => state.verifyLink,
    getNotifData: state => state.notifData.data,
    getNotifIndex: state => state.notifData.index,
    getChatLang: state => {
      if (state.chatData.selectChatLang === null) {
        let lang = navigator.language
        if (lang === undefined || lang === null) {
          return 'en'
        }
        let languages = state.chatData.languages
        for (let key in languages) {
          //if (languages.hasOwnProperty(key) && languages[key].code === translations.substr(0, 2).toLowerCase()){
          if (languages.hasOwnProperty(key) && languages[key].code === lang.substr(0, 2).toLowerCase()){
            return languages[key].code
          }
        }
        return 'en'
      }
      return state.chatData.selectChatLang
    },
    getChatLanguages: state => state.chatData.languages,
    getChatIsLoad: state => state.chatData.isLoad,
    getChatMsgData: state => state.chatData.msgData,
    getChatCount: state => state.chatData.countMsg,
    getChatShare: state => state.chatData.share,
    getModalBetHash: state => state.modalBet.hash,
    getModalTips: state => state.modalTips,
    getModalChatBan: state => state.modalChatBan,
    getModalChatUnBan: state => state.modalChatUnBan,
    getModalCoupon: state => state.modalCoupon,
    getModalCouponDefaultValue: state => state.modalCoupon.defaultValue,
    getModalUserHash: state => state.modalUser.hash,
    getFaucetIsLoader: state => state.faucet.isLoader,
    getFaucetLoaderWidth: state => state.faucet.loaderWidth,
    getFaucetLoaderTime: state => state.faucet.loaderTime,
    getFaucetLoaderStop: state => state.faucet.loadStop,
    getDepositAnimate: state => state.isDepositAnimate,
    getFinancePayInOut: state => state.finances.updatePayInOut,
    getDeviceType: () => {
      const width = window.innerWidth
      if (width <= 768) {
        return 'mobile'
      }
      if (width >= 769 && width <= 1023) {
        return 'tablet'
      }
      if (width >= 1024 && width <= 1215) {
        return 'desktop'
      }
      if (width >= 1216) {
        return 'widescreen'
      }
    },
    getChatWidth: state => state.chatData.width + "px",
    getOnline: state => state.statMain.online,
    getOnlineDetail: state => state.statMain.onlineDetail,
    getBets: state => state.statMain.bets,
    getLastWon: state => state.statMain.lastWon,
    getLastWonData: state => state.statMain.lastWonData,
    getJackpotData: state => state.statMain.jackpotData,
    getJackpot: state => state.statMain.jackpot,
    getLastBigWin: state => state.statMain.bigWin,

    getStatMainLoad: state => state.statMainLoad,
    getSelectCurr: state => {
      if (localStorage.selectCurr !== undefined && localStorage.selectCurr !== state.selectCurr) {
        state.selectCurr = localStorage.selectCurr
      }
      return state.selectCurr
    },
    getSelectCurrReal: (state, getters) => {
      return getters.getSelectCurr === 'win' ? 'btc' : getters.getSelectCurr
    },
    getSelectCurrRealForMod: (state, getters) => {
      if (getters.isAdmin || getters.isApprover || getters.isModer) {
        return getters.getSelectCurr
      }

      return getters.getSelectCurr === 'win' ? 'btc' : getters.getSelectCurr
    },
    getCurrencies: state => state.currencies,
    getCurrenciesReal: state => state.currencies.slice(1),
    getMinimumBet: state => state.gameConfig.minimum,
    getMaximumWin: state => state.gameConfig.maximum,
    getGame: state => state.game,
    getGameDiceAuto: state => state.gameDiceAuto,
    getGameCrash: state => state.gameCrash,
    getGameCrashAuto: state => state.gameCrashAuto,
    getGamePlinko: state => state.gamePlinko,
    getGamePlinkoAuto: state => state.gamePlinkoAuto,
    getGameWheel: state => state.gameWheel,
    getGameRoulette: state => state.gameRoulette,
    getGameRouletteAuto: state => state.gameRouletteAuto,
    getGameLogs: state => state.gameLogs,

    isAuth: state => {
      let ret = false
      if (state.authState === 'success') {
        ret = true
      }
      return ret
    },
    isAdmin: state => state.isAdmin,
    isApprover: state => state.isApprover,
    isModer: state => state.isModer || state.isApprover,
    isAdminOrModer: state => state.isModer || state.isApprover || state.isAdmin,
    getAuthToken: state => state.authToken,
    getAuthData: state => state.authData,
    getUserHash: state => state.authData.hash,
    getRating: state => state.authData.rating,
    getBalance: state => state.authData.balance,
    getBalanceReal: (state, getters) => {
      let ret = {}
      for (let key in getters.getBalance) {
        if (key !== 'win') {
          ret[key] = getters.getBalance[key]
        }
      }
      //delete ret['win']
      return ret
    },
    getIsEmailConfirm: state => state.authData.is_email,
    getUserFriendsOnly: state => state.authData.friend_msg,
    getUserXP: state => state.authData.xp,
    gameSettings: state => state.gameSettings,

    socketSubscribesUpdateHelp:     state => state.socketSubscribes.update_help_data,
    socketSubscribesUpdatePayout:   state => state.socketSubscribes.update_payout,
    socketSubscribesChatSend:       state => state.socketSubscribes.chat_send,
    socketSubscribesRoll:           state => state.socketSubscribes.roll,
    socketSubscribesCrash:          state => state.socketSubscribes.crash,
    socketSubscribesPlinko:         state => state.socketSubscribes.plinko,
    socketSubscribesRoulette:       state => state.socketSubscribes.roulette,

    socketSubscribesWheel:       state => state.socketSubscribes.wheel,
    socketSubscribesWheelNew:    state => state.socketSubscribes.wheel_new,
    socketSubscribesWheelActive: state => state.socketSubscribes.wheel_active,
    socketSubscribesWheelPool:   state => state.socketSubscribes.wheel_pool,
    socketSubscribesWheelEnd:    state => state.socketSubscribes.wheel_end,

    getWheelUsersCount: state => state.wheelUsersCount,

    getGmtValue: function() {
      const d = new Date()
      const n = d.getTimezoneOffset() * -1
      let hours = Math.floor(n /60)
      let minutes = n - hours * 60

      if (hours < 10) hours = "0" + hours.toString()
      if (minutes < 10) minutes = "0" + minutes.toString()

      let add = ""
      if (n >= 0) add = "+"

      return 'GMT' + add + hours.toString() + ":" + minutes.toString()
    },
    getHost: () => {
      return location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '')
    },
    getDomainIndex: state => {
      const domains = state.domains.split(" ")
      const host = window.location.host

      for (let key in domains) {
        if (domains[key] === host) {
          return key
        }
      }
      return 0
    },
    getDomains: (state, getters) => {
      return state.domains.split(" ")
    },
    getDomain: (state, getters) => {
      const domains = state.domains.split(" ")
      return domains[getters.getDomainIndex]
    },
    getDomainWs: (state, getters) => {
      const domainsWs = state.domains_ws.split(" ")
      return domainsWs[getters.getDomainIndex]
    },
    getDomainApi: (state, getters) => {
      const domainsApi = state.domains_api.split(" ")
      return domainsApi[getters.getDomainIndex]
    },
    getDomainImages: (state, getters) => {
      const domainsImages = state.domains_images.split(" ")
      return domainsImages[getters.getDomainIndex]
    },

    isFirstLogin: state => state.firstLogin,
    getContestBalanceDay: state => state.contests.balance_day,
    getContestWageringDay: state => state.contests.wagering_day,
    getContestWageringWeek: state => state.contests.wagering_week,
    getOpenIntercom: state => state.openIntercom,
    getLoadIntercom: state => state.loadIntercom,
    getSupportWebP: state => state.supportWebP,
  },
  mutations: {
    authSecSet: (state, data) => state.authSec = data,
    setVersion: (state, data) => state.version = data,
    changeGame: (state, data) => state.game[data.param] = data.value,
    changeGameDiceAuto: (state, data) => state.gameDiceAuto[data.param] = data.value,
    changeGameCrash: (state, data) => state.gameCrash[data.param] = data.value,
    changeGameCrashAuto: (state, data) => state.gameCrashAuto[data.param] = data.value,
    changeGamePlinko: (state, data) => state.gamePlinko[data.param] = data.value,
    changeGamePlinkoAuto: (state, data) => state.gamePlinkoAuto[data.param] = data.value,
    changeGameWheel: (state, data) => state.gameWheel[data.param] = data.value,
    changeGameRoulette: (state, data) => state.gameRoulette[data.param] = data.value,
    changeGameRouletteAuto: (state, data) => state.gameRouletteAuto[data.param] = data.value,
    openModalAuth: (state, type) => {
      state.modalAuth.isOpen = true
      state.modalAuth.type = type
    },
    firstLogin: state => {
      state.firstLogin = true
    },
    closeModalAuth: (state) => {
      state.modalAuth.isOpen = false
      state.modalAuth.type = 'reg'
    },
    changeModalAuthType: (state, type) => {
      state.modalAuth.type = type
    },
    openModalBet: (state, hash) => {
      main.config.globalProperties.$Progress.start()
      state.modalBet.hash = hash
      state.isModalBet = true
    },
    openModalUser: (state, hash) => {
      //console.log(hash)
      main.config.globalProperties.$Progress.start()
      state.modalUser.hash = hash
      state.isModalUser = true
    },
    openModalTips: (state, value) => {
      main.config.globalProperties.$Progress.start()
      state.modalTips = { isOpen: true, username: value.username, hash: value.hash }
    },
    openModalTrivia: state => {
      main.config.globalProperties.$Progress.start()
      state.isModalTrivia = true
    },
    openModalRain: state => {
      main.config.globalProperties.$Progress.start()
      state.modalRain = { isOpen: true }
    },
    openModalExchange: state => {
      main.config.globalProperties.$Progress.start()
      state.modalExchange = { isOpen: true }
    },
    openModalDeposit: state => {
      main.config.globalProperties.$Progress.start()
      state.isModalDeposit = true
    },
    openModalPayout: state => {
      main.config.globalProperties.$Progress.start()
      state.isModalPayout = true
    },
    openModalWallet: (state, value) => {
      main.config.globalProperties.$Progress.start()
      state.modalWallet = value
    },
    openModalChatBan: (state, value) => state.modalChatBan = { isOpen: true, username: value.username, hash: value.hash },
    openModalChatUnBan: (state, value) => state.modalChatUnBan = { isOpen: true, username: value.username, hash: value.hash },
    openModalCoupon: (state, value) => {
      state.modalCoupon.isOpen = true
      // если есть уже известный промокод - установить его в модал-окно
      if (value.code) state.modalCoupon.defaultValue = value.code;
    },

    closeModalBet: state => {
      state.modalBet.hash = ''
      state.isModalBet = false
    },
    closeModalUser: state => {
      state.modalUser.hash = ''
      state.isModalUser = false
    },
    closeModalTips: state => state.modalTips = { isOpen: false, username: '', hash: '' },
    closeModalTrivia: state => state.isModalTrivia = false,
    closeModalRain: state => state.modalRain = { isOpen: false },
    closeModalExchange: state => state.modalExchange = { isOpen: false },
    closeModalDeposit: state => state.isModalDeposit = false,
    closeModalPayout: state => state.isModalPayout = false,
    closeModalChatBan: state => state.modalChatBan.isOpen = false,
    closeModalChatUnBan: state => state.modalChatUnBan.isOpen = false,
    closeModalCoupon: state => {
      state.modalCoupon.isOpen = false;
      // при закрытие попапа - сброс дефолт значения кода
      state.modalCoupon.defaultValue = '';
    },
    closeModalWallet: state => state.modalWallet = null,
    setDepositAnimate: (state, value) => state.isDepositAnimate = value,
    faucetToggle: state => {
      if (state.isFaucetOpen === false) {
        main.config.globalProperties.$Progress.start()
      }
      state.isFaucetOpen = !state.isFaucetOpen
    },

    changeSelectCurr: (state, curr) => {
      state.selectCurr = curr
      localStorage.setItem('selectCurr', state.selectCurr)
    },
    changeScroll: (state, value) => {
      //document.body.className = value === true ? '' : 'disable-scroll'

      //document.html.style = 'overflow: hidden;'
      //document.body.style = 'overflow: hidden;'


      if (value !== true) {
        //document.getElementsByTagName("body")[0].style = 'overflow: hidden'
        //document.getElementsByTagName("html")[0].style = 'overflow: hidden'
        document.getElementsByTagName("body")[0].style.overflow = 'hidden'
        document.getElementsByTagName("html")[0].style.overflow = 'hidden'
      } else {
        //document.getElementsByTagName("body")[0].style = ''
        //document.getElementsByTagName("html")[0].style = ''

        document.getElementsByTagName("body")[0].style.overflow = null
        document.getElementsByTagName("html")[0].style.overflow = null
      }

    },
    /*
    changeScrollChat: (state, value) => {
      if (value !== true) {
        document.getElementsByTagName("body")[0].style.overflow = "hidden"
        document.getElementsByTagName("html")[0].style.overflow = "hidden"
      } else {
        document.getElementsByTagName("body")[0].style.overflow = ""
        document.getElementsByTagName("html")[0].style.overflow = ""
      }
    },
    */
    addChatCount: state => state.chatData.countMsg += !state.isChatOpen ? 1 : 0,
    pushChatData: (state, data) => {
      state.chatData.msgData[data.lang].push(data)

      if (state.chatData.msgData[data.lang].length > state.chatData.maxMessages) {
        state.chatData.msgData[data.lang].splice(0, 1)
      }
    },
    loadChatMessages: (state, messages) => {
      state.chatData.msgData[messages.room] = messages.data
    },
    setChatLoaded: state => state.chatData.isLoad = true,
    shareChat: (state, text) => {
      //if (text.length > 0) text += " "
      state.chatData.share = text


      if (text === "") return

      state.chatData.countMsg = 0
      state.isChatOpen = true
      localStorage.setItem('isChatOpen', state.isChatOpen)
    },
    changeChatLang: (state, lang) => {
      state.chatData.selectChatLang = lang
      localStorage.setItem('chatLang', lang)
    },
    socketAuthStatus: (state, status) => state.isAuthSocket = status,
    chatToggle: state => {
      const width = (window.innerWidth > 0) ? window.innerWidth : screen.width
      if (width <= 768) {
        state.chatData.width = width
      } else {
        state.chatData.width = 334
      }

      state.chatData.countMsg = 0
      state.isChatOpen = !state.isChatOpen
      localStorage.setItem('isChatOpen', state.isChatOpen)

      if (width <= 768 && state.isChatOpen === true) {
        document.getElementsByTagName("body")[0].style.overflow = "hidden"
        document.getElementsByTagName("html")[0].style.overflow = "hidden"
      } else {
        document.getElementsByTagName("body")[0].style.overflow = ""
        document.getElementsByTagName("html")[0].style.overflow = ""
      }
    },
    chatClose: state => {
      state.isChatOpen = false
      localStorage.setItem('isChatOpen', state.isChatOpen)

      document.getElementsByTagName("body")[0].style.overflow = ""
      document.getElementsByTagName("html")[0].style.overflow = ""
    },
    chatLoadStatus: (state, status) => {
      state.chatData.isLoad = status
    },
    setSupportWebP: (state, value) => {
      state.supportWebP = value
    },

    addNotif: (state, data) => state.notifData.data.unshift(data),
    addNotifIndex: state => state.notifData.index++,
    closeNotif: (state, index) => {
      for (let i = 0; i < state.notifData.data.length; i++) {
        if (state.notifData.data[i].index === index) {
          state.notifData.data.splice(i, 1)
          i--
        }
      }
    },
    tutorialOpen: state => {
      state.isTutorialOpen = true
      state.firstLogin = false
    },
    tutorialClose: state => state.isTutorialOpen = false,
    updateFinancePayInOut: state => state.finances.updatePayInOut = !state.finances.updatePayInOut,

    loadGameSettings: state => {
      let settings = localStorage.gameSettings
      try {
        settings = JSON.parse(settings)
      } catch(e) {
        settings = {sound: false, animation: true, hotkeys: false, maxbet: true}
      }
      if (settings === undefined) {
        settings = {sound: false, animation: true, hotkeys: false, maxbet: true}
      }

      if (settings.sound === undefined) settings.sound = false
      if (settings.animation === undefined) settings.animation = true
      if (settings.hotkeys === undefined) settings.hotkeys = false
      if (settings.maxbet === undefined) settings.maxbet = true
      if (settings.games_random === undefined) settings.games_random = true

      state.gameSettings = settings
    },
    changeGameSettings: (state, {name, value}) => {
      let settings = state.gameSettings
      settings[name] = value
      localStorage.setItem('gameSettings', JSON.stringify(settings))
      state.gameSettings = settings
    },
    loadSounds: state => {
      if (state.gameSettings.sound === true) {

        if (state.audioData.win === null) {
          state.audioData.win = new Audio()
          state.audioData.win.src = '/static/sounds/win.wav'
        }
        if (state.audioData.lose === null) {
          state.audioData.lose = new Audio()
          state.audioData.lose.src = '/static/sounds/lose.wav'
        }
        if (state.audioData.coins === null) {
          state.audioData.coins = new Audio()
          state.audioData.coins.src = '/static/sounds/coins.wav'
        }
        if (state.audioData.notif === null) {
          state.audioData.notif = new Audio()
          state.audioData.notif.src = '/static/sounds/notif.wav'
        }

        if (state.audioData.plinkoButton === null) {
          state.audioData.plinkoButton = new Audio()
          state.audioData.plinkoButton.src = '/static/sounds/plinko_button.wav'
        }

        for (let i=0; i<9; i++) {
          if (state.audioData['plinkoDrop'+i] === null) {

            let audio = new Audio()
            audio.src = '/static/sounds/plinko_'+i+'.wav'
            state.audioData['plinkoDrop'+i] = audio
          }
        }

        if (state.audioData.crashButton === null) {
          state.audioData.crashButton = new Audio()
          state.audioData.crashButton.src = '/static/sounds/crash_button.wav'
        }

        if (state.audioData.crashJump === null) {
          state.audioData.crashJump = new Audio()
          state.audioData.crashJump.src = '/static/sounds/crash_jump.wav'
        }

        if (state.audioData.crashWin === null) {
          state.audioData.crashWin = new Audio()
          state.audioData.crashWin.src = '/static/sounds/crash_win.wav'
        }

        if (state.audioData.crashLose === null) {
          state.audioData.crashLose = new Audio()
          state.audioData.crashLose.src = '/static/sounds/crash_lose.wav'
        }

        if (state.audioData.wheelButton === null) {
          state.audioData.wheelButton = new Audio()
          state.audioData.wheelButton.src = '/static/sounds/crash_button.wav'
        }

        if (state.audioData.wheelEnd === null) {
          state.audioData.wheelEnd = new Audio()
          state.audioData.wheelEnd.src = '/static/sounds/roulette_end.wav'
        }

        if (state.audioData.rouletteButton === null) {
          state.audioData.rouletteButton = new Audio()
          state.audioData.rouletteButton.src = '/static/sounds/crash_button.wav'
        }
        if (state.audioData.rouletteRoll === null) {
          state.audioData.rouletteRoll = new Audio()
          state.audioData.rouletteRoll.src = '/static/sounds/roulette_roll.wav'
        }
        if (state.audioData.rouletteStop === null) {
          state.audioData.rouletteStop = new Audio()
          state.audioData.rouletteStop.src = '/static/sounds/roulette_stop.wav'
        }
        if (state.audioData.rouletteStopTurbo === null) {
          state.audioData.rouletteStopTurbo = new Audio()
          state.audioData.rouletteStopTurbo.src = '/static/sounds/roulette_stop_turbo.wav'
        }
      }
    },
    loadSoundsNotifications: state => {
      if (state.audioDataNotifications.chat === null) {
        state.audioDataNotifications.chat = new Audio()
        state.audioDataNotifications.chat.src = '/static/sounds/notif_chat.wav'
      }
    },
    playSound: (state, data) => {
      let name = data
      let duration = null
      let active = false
      if (typeof(data) === 'object') {
        name = data.name
        duration = data.duration
        active = data.active
      }

      if ((state.gameSettings.sound === true || active) && state.audioData[name] !== null) {
        let playObject = state.audioData[name].cloneNode()
        let playPromise = playObject.play()


        if (playPromise !== undefined) {
          playPromise.then(function() {
            // Automatic playback started!
          }).catch(function() {
            // Automatic playback failed.
            // Show a UI element to let the user manually start playback.
          })
        }
        playObject.remove()

        if (duration !== null) {
          setTimeout(()=>{
            playObject.pause()
            playObject.currentTime = 0
          }, duration)
        }
      }
    },
    playSoundNotification: (state, name) => {
      if (state.audioDataNotifications[name] !== null) {
        let playObject = state.audioDataNotifications[name].cloneNode()
        let playPromise = playObject.play()


        if (playPromise !== undefined) {
          playPromise.then(function() {
            // Automatic playback started!
          }).catch(function() {
            // Automatic playback failed.
            // Show a UI element to let the user manually start playback.
          })
        }
        playObject.remove()
      }
    },
    stopSound: (state, data) => {

    },

    openIntercom: state => state.openIntercom++,

    //SOCKET_RECONNECT: (state, count) => {
    SOCKET_RECONNECT: () => {

      console.log('reconnection')
    },
    SOCKET_RECONNECT_ERROR: () => {
      console.log('reconnection error')
    },
    //SOCKET_ONOPEN: (state, event) => {
    SOCKET_ONOPEN: (state) => {
      console.log('connect socket')

      state.isAuthSocket = false
      state.isConnectedSocket = true

      state.isPageLoad = true

      clearTimeout(state.socketTimeout)

      //console.log(Vue.prototype.$socket)

      state.socketTimeout = setTimeout(() => {
        //if (state.isConnectedSocket === true) {
        //Vue.prototype.$socket.onclose(state)
        main.config.globalProperties.$disconnect()

        //Vue.prototype.$connect()
        //}

        console.log('no ping after connect')
      }, 35000);
    },
    socketActive: (state) => {
      clearTimeout(state.socketTimeout)

      state.lastPing = (new Date).getTime()

      state.socketTimeout = setTimeout(() => {
        //if (state.isConnectedSocket === true) {
        //Vue.prototype.$socket.onclose(state)
        main.config.globalProperties.$disconnect()

        console.log('no ping')
      }, 35000);
    },
    //SOCKET_ONCLOSE: (state, event) => {
    SOCKET_ONCLOSE: (state, event) => {
      // fixed double close
      if (state.closedSocketCount > 0) return
      state.closedSocketCount++


      clearTimeout(state.socketTimeout)
      console.log('close')
      console.log(event)

      state.chatData.msgData = []
      state.chatData.isLoad = false

      //console.log('disconnect socket')
      state.isAuthSocket = false
      state.isConnectedSocket = false

      //state.statMainLoad = false
      //Vue.prototype.$disconnect()
      let waitTimer = 3000
      if (event === 'fast') waitTimer = 1

      setTimeout(() => {
        state.closedSocketCount = 0
        main.config.globalProperties.$connect()
      }, waitTimer)
    },
    SOCKET_ONERROR: (state, event) => {
      console.log("Error on socket server")
      console.error(event)
    },
    SOCKET_ONMESSAGE: (state, message) => {
      console.log('Received message: ', message)
    },
    SOCKET_stat_main: (state, message) => {
      state.statMainLoad = false
      state.statMain = message
      state.statMainLoad = true
    },

    SOCKET_contest_info: (state, message) => {
      //console.log("contest info: ", message)
      for (let i=0; i<message.length; i++) {
        state.contests[message[i].type] = message[i]
      }
    },

    // deprecated
    SOCKET_chat_load: (state, message) => { },

    chatDeleteBanMessages: state => {
      clearInterval(state.chatDeleteBan)

      let banInterval = () => {
        let date = Math.round((new Date()).getTime() / 1000)

        // delete old ban messages
        for (let lang in state.chatData.msgData) {
          if (state.chatData.msgData[lang].length > 0) {
            for (let i = 0; i < state.chatData.msgData[lang].length; i++) {
              if (state.chatData.msgData[lang][i].type === "ban" && state.chatData.msgData[lang][i].time + 60 < date) {
                state.chatData.msgData[lang].splice(i, 1)
                i--
              }
            }
          }
        }
      }

      banInterval()
      state.chatDeleteBan = setInterval(() => {
        banInterval()
      }, 30000)
    },

    SOCKET_chat_delete: (state, hash) => {
      if (state.chatData.isLoad === true) {
        for (let key in state.chatData.msgData) {
          if (state.chatData.msgData.hasOwnProperty(key)) {
            for (let v=0; v<state.chatData.msgData[key].length; v++) {
              if (state.chatData.msgData[key][v].user.hash === hash) {
                //state.chatData.msgData[key][v].type = 'delete'
                state.chatData.msgData[key].splice(v, 1)
                v--
              }
            }
          }
        }
      }
    },

    SOCKET_game_data: (state, data) => {
      if (data.minimum !== undefined) {
        state.gameConfig.minimum = data.minimum
      }
      if (data.maximum !== undefined) {
        state.gameConfig.maximum = data.maximum
      }
    },

    plusUserXP: (state, value) => {
      state.authData.xp = parseFloat(state.authData.xp) + value
    },
    minusUserXP: (state, value) => {
      //console.log("minus xp:", value)
      state.authData.xp = parseFloat(state.authData.xp) - value
    },
    newGameLog: (state, message) => {
      //console.log('New game log ', message)

      const setLogs = (logs, type) => {
        if (logs.length >= state.gameLogs.maxLogs) {
          logs.pop()
        }
        logs.unshift(message)
      }

      if (state.authData !== null && message.userHash === state.authData.hash) {
        setTimeout(function () {
          setLogs(state.gameLogs.main)
          setLogs(state.gameLogs.self)
          if (message.isHigh === true) {
            setLogs(state.gameLogs.high)
          }
          if (message.jackpot > 0) {
            setLogs(state.gameLogs.jack)
          }

          if (message.xp > 0) {
            state.authData.xp = parseFloat(state.authData.xp) + message.xp
          }

        }, 500)
      } else {
        //if (message.game === 3) return

        setLogs(state.gameLogs.main, "main")
        if (message.isHigh === true) {
          setLogs(state.gameLogs.high)
        }
        if (message.jackpot > 0) {
          setLogs(state.gameLogs.jack)
        }
      }
    },

    // deprecated
    SOCKET_game_log_load: (state, data) => { },

    SOCKET_game_log_load_main: (state, data) => {
      state.gameLogs.main = data.reverse()
    },
    SOCKET_game_log_load_high: (state, data) => {
      state.gameLogs.high = data.reverse()
    },
    SOCKET_game_log_load_jack: (state, data) => {
      state.gameLogs.jack = data.reverse()
    },
    SOCKET_game_log_load_self: (state, data) => {
      state.gameLogs.self = data
    },

    SOCKET_country: (state, data) => state.country = data,
    SOCKET_country_block: state => state.isCountryBlock = true,
    SOCKET_set_admin: state => state.isAdmin = true,
    SOCKET_set_approver: state => state.isApprover = true,
    SOCKET_set_moder: state => state.isModer = true,
    SOCKET_update_help_data: (state, data) => state.socketSubscribes.update_help_data = data,
    SOCKET_update_payout: (state, data) => state.socketSubscribes.update_payout = data,
    SOCKET_chat_send: (state, data) => state.socketSubscribes.chat_send = data,
    SOCKET_roll: (state, data) => state.socketSubscribes.roll = data,
    SOCKET_crash: (state, data) => state.socketSubscribes.crash = data,
    SOCKET_plinko: (state, data) => state.socketSubscribes.plinko = data,
    SOCKET_roulette:           (state, data) => state.socketSubscribes.roulette = data,

    SOCKET_wheel:        (state, data) => state.socketSubscribes.wheel = data,
    SOCKET_wheel_new:    (state, data) => state.socketSubscribes.wheel_new = data,
    SOCKET_wheel_active: (state, data) => state.socketSubscribes.wheel_active = data,
    SOCKET_wheel_pool:   (state, data) => state.socketSubscribes.wheel_pool = data,
    SOCKET_wheel_end:    (state, data) => state.socketSubscribes.wheel_end = data,
    SOCKET_wheel_users:  (state, data) => state.wheelUsersCount = data,

    subscribe_update_help_data: state => state.socketSubscribes.update_help_data = null,
    subscribe_update_payout:    state => state.socketSubscribes.update_payout = null,
    subscribe_chat_send:        state => state.socketSubscribes.chat_send = null,
    subscribe_roll:             state => state.socketSubscribes.roll = null,
    subscribe_crash:            state => state.socketSubscribes.crash = null,
    subscribe_plinko:           state => state.socketSubscribes.plinko = null,
    subscribe_roulette:         state => state.socketSubscribes.roulette = null,

    subscribe_wheel:         state => state.socketSubscribes.wheel = null,
    subscribe_wheel_new:     state => state.socketSubscribes.wheel_new = null,
    subscribe_wheel_active:  state => state.socketSubscribes.wheel_active = null,
    subscribe_wheel_pool:    state => state.socketSubscribes.wheel_pool = null,
    subscribe_wheel_end:     state => state.socketSubscribes.wheel_end = null,

    startFaucetLoader: (state) => {
      function getLoaderTime (secData) {
        let min = parseInt((secData / 60).toString())
        let sec = secData - (min * 60)
        if (sec < 10) {
          sec = '0' + sec
        }
        return min + ':' + sec
      }
      const timeStop = parseInt(new Date().getTime()/1000) + state.faucet.loadSec
      const timeNow = parseInt(new Date().getTime()/1000)

      let range = 100 / state.faucet.loadSec
      let ticks = state.faucet.loadSec - 1
      let width = 100

      state.faucet.isLoader = true
      state.faucet.loaderWidth = 100
      if (state.faucet.loadStop === 0) {

        state.faucet.loadStop = timeStop
        localStorage.setItem('faucet-loader', timeStop)
        state.faucet.loaderTime = getLoaderTime(state.faucet.loadSec)
      } else {

        width = (state.faucet.loadStop - timeNow)*range
        ticks = state.faucet.loadStop - timeNow - 1
        state.faucet.loaderTime = getLoaderTime(state.faucet.loadStop - timeNow)
      }

      state.faucet.interval = setInterval(function () {
        const timeNow = parseInt(new Date().getTime()/1000)
        if (ticks <= 0) {
          state.faucet.isLoader = false
          state.faucet.loaderWidth = 0
          state.faucet.loadStop = 0
          localStorage.removeItem('faucet-loader')
          clearInterval(state.faucet.interval)
        }
        width = (state.faucet.loadStop - timeNow)*range
        ticks = state.faucet.loadStop - timeNow - 1
        state.faucet.loaderWidth = parseFloat(width.toFixed(2))
        state.faucet.loaderTime = getLoaderTime(ticks)
        //ticks--
      }, 1000)
    },


    AUTH_REQUEST: (state) => {
      state.authState = 'loading'
    },
    AUTH_NO: (state) => {
      state.authState = ''
    },
    AUTH_SUCCESS: (state, data) => {
      state.authState = 'success'
      if (data.token) {
        localStorage.setItem('user-token', data.token)
        state.authToken = data.token
      }

      let balances = {}
      for (let i=0; i<data.data.balance.length; i++) {
        balances[data.data.balance[i].curr] = data.data.balance[i].amount
      }
      data.data.balance = balances

      state.authData = data.data

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + state.authToken
    },
    AUTH_ERROR: (state) => {
      state.authState = 'error'
    },

    AUTH_LOGOUT: state => {
      state.authState = ''
      state.authToken = ''
      //state.authData = null
      state.isAdmin = false
      state.isApprover = false
      state.isModer = false
      state.authData = {
        is_email: false
      }
      //context.commit('tourney_team', -1)
      //state.tourneyTeam = -1

      localStorage.removeItem('user-token')
      if (localStorage.getItem('faucet-loader')) {
        state.faucet.isLoader = false
        state.faucet.loaderWidth = 0
        state.faucet.loadStop = 0
        localStorage.removeItem('faucet-loader')
        clearInterval(state.faucet.interval)
      }
      localStorage.removeItem('selectCurr')
      axios.defaults.headers.common['Authorization'] = ''
    },
    setUserFriendsOnly: (state, data) => state.authData.friend_msg = data,
    setIsEmailConfirm: (state, data) => state.authData.is_email = data,
    updateUserBalanceCurr: (state, data) => {
      state.authData.balance[data.curr] += data.amount
    },
    setUserBalanceCurr: (state, data) => {
      state.authData.balance[data.curr] = data.amount
    },
    setUserData: (state, data) => {
      state.authData[data.key] = data.value
    },
    depositGoogle (state, data) {
      let amount = data.amount
      if (data.type === 'payout') {
        amount = amount * -1
      }

      purchase({
        "transaction_id": new Date().getTime().toString(),
        "affiliation": data.type+'-'+data.curr,
        "value": (amount * 100000)
      })

      /*
      ecommerce.addItem({
        id: new Date().getTime(),       // Transaction ID. Required.
        name: data.type+'-'+data.curr,    // Product name. Required.
        price: '1',                 // Unit price.
        quantity: (amount * 100000).toString(),
      })
      ecommerce.send()
      */

      //event('user-click', 'increase', 'counter', state.counter)
    },
  },
  actions: {

    socket_game_log: (context, data) => {
      context.commit('newGameLog', data)

      //const authData = context.getters.getAuthData
      //if (authData !== null && data.userHash === authData.hash) {
      //  context.dispatch('updateDatabaseStats', data).then()
      //}
    },

    socket_chat_messages: (context, messages) => {
      context.commit('loadChatMessages', messages)
      if (context.getters.getChatLang === messages.room) {
        context.commit('setChatLoaded')
        context.commit('chatDeleteBanMessages')
      }
    },

    socket_chat_new: (context, data) => {
      if (context.getters.getChatIsLoad === true) {

        data['uniq'] = data.user.hash + data.time + Math.random()

        context.commit('pushChatData', data)

        if (data.data !== undefined && data.data.data !== undefined && data.data.data.users !== undefined && data.data.data.users !== null) {
          for (let i = 0; i < data.data.data.users.length; i++) {
            if (data.data.data.users[i].hash === context.getters.getAuthData.hash) {
              // user mark in chat

              //console.log('play chat sound')
              if (localStorage.getItem('chat_notification') !== 'false') {
                context.commit('playSoundNotification', 'chat')
              }

              if (!context.getters.isChatOpen || data.lang !== context.getters.getChatLang) {
                let message = {
                  isMessage: true,
                  user: {
                    avatar: data.user.avatar,
                    username: data.user.username,
                    rating: data.user.rating,
                  },
                  text: data.lang.toUpperCase() + ' chat: ' + data.data.text,
                }
                context.dispatch('addNotif', {type: 'info', text: message, isClose: false, timer: 3000}).then()
              }
            }
          }
        }

        if (!context.getters.isChatOpen && data.lang === context.getters.getChatLang) {
          context.commit('addChatCount')
        }
      }
    },

    socket_update_balance: (context, data) => {
      for (let key in context.getters.getAuthData.balance) {
        if (context.getters.getAuthData.balance.hasOwnProperty(key) && key === data.curr){
          if (data.type === 'update') {
            context.commit('setUserBalanceCurr', { curr: key, amount: context.getters.getAuthData.balance[key] + data.amount })

            if (data.notif !== '') {
              let symbol = '+'
              if (data.amount < 0) {
                symbol = ''
              }

              context.commit('playSound', 'coins')

              if (data.notif === 'Deposit' || data.notif === 'Payout') {
                context.commit('updateFinancePayInOut')

                context.commit('depositGoogle', {type: data.notif.toLocaleLowerCase(), amount: data.amount/1000, curr: data.curr})


                const text = {isMessage: true, isPay: true, type: data.notif, hash: data.hash, curr: data.curr, amount: data.amount}

                context.dispatch('addNotif', {type: 'success', text: text, isClose: true, timer: false}).then()
              } else {

                const notifMsg = symbol + (data.amount / 1000).toFixed(8) + ' ' + data.curr.toLocaleUpperCase() + ' by ' + data.notif
                let timer = false
                if (data.curr === 'win') {
                  timer = 3000
                }
                context.dispatch('addNotif', {type: 'success', text: notifMsg, isClose: true, timer: timer}).then()
              }
            }
          } else if (data.type === 'set') {
            context.commit('setUserBalanceCurr', { curr: key, amount: data.amount })
          }
        }
      }
    },
    socket_update_help: (context, data) => {
      context.dispatch('addNotif', {type: 'success', text: "A new reply received by ticket #" + data, isClose: true, timer: false}).then()
      context.commit('playSound', 'notif')
    },

    socket_auth: (context, msg) => {
      if (msg.status === 'success') {
        context.commit('socketAuthStatus', true)
      } else if (msg.status === 'error' && msg.message === 'Authentication error') {
        //window.location.reload(false)
        context.dispatch('addNotif', {type: 'danger', text: msg.message, isClose: true, timer: false}).then()
      } else {
        context.commit('socketAuthStatus', false)
      }
    },

    socket_roulette_winner: (context) => {
      if (router.currentRoute.name !== "Roulette") {
        context.dispatch('addNotif', {type: 'success', text: "You won at Roulette", isClose: true, timer: false}).then()
      }
    },

    socket_ping: context => {
      //console.log('ping')
      context.commit('socketActive')
      context.dispatch('sendSocketPong').then()
    },


    sendSocketPong: () => {
      //console.log('ping')
      main.config.globalProperties.$socket.sendObj({action: 'pong'})
    },

    sendSocketAuth: (context) => {
      main.config.globalProperties.$socket.sendObj({action: 'auth', data: context.getters.getAuthToken})
      //Vue.prototype.$socket.sendObj({action: 'auth', data: {token: context.getters.getAuthToken, hash: context.getters.getAuthSecHash}})
    },
    sendSocketChatSend: (context, data) => {
      main.config.globalProperties.$socket.sendObj({action: 'chat_send', data: data})
    },
    sendSocketRoll: (context, data) => {
      main.config.globalProperties.$socket.sendObj({action: 'roll', data: data})
    },
    sendSocketCrash: (context, data) => {
      //console.log("send crash", data)
      main.config.globalProperties.$socket.sendObj({action: 'crash', data: data})
    },
    sendSocketPlinko: (context, data) => {
      //console.log("send plinko", data)
      main.config.globalProperties.$socket.sendObj({action: 'plinko', data: data})
    },
    sendSocketAuthSecGet: (context) => {
      main.config.globalProperties.$socket.sendObj({action: 'auth_sec_get', data: context.getters.getAuthSecHash})
    },


    sendSocketRoulette: (context, data) => {
      main.config.globalProperties.$socket.sendObj({action: 'roulette', data: data})
    },
    sendSocketWheel: (context, data) => {
      main.config.globalProperties.$socket.sendObj({action: 'wheel', data: data})
    },
    sendSocketWheelEnter: (context, data) => {
      main.config.globalProperties.$socket.sendObj({action: 'wheel_enter', data: data})
    },
    sendSocketWheelLeave: (context, data) => {
      main.config.globalProperties.$socket.sendObj({action: 'wheel_leave', data: data})
    },

    socketCheckConnectFrozen: (context) => {
      if (context.getters.isConnectedSocket === true && context.getters.getLastPing < (new Date).getTime() - 35000) {
        main.config.globalProperties.$disconnect()
        context.commit('SOCKET_ONCLOSE', 'fast')
      }
    },

    addNotif: (context, data) => {
      data.index = context.getters.getNotifIndex + 1
      context.commit('addNotifIndex')
      context.commit('addNotif', data)
      if (data.timer !== false) {
        setTimeout(function () {
          context.commit('closeNotif', data.index)
        }, data.timer)
      }
    },
  }
})
