<script>
import UserLevel from "@/components/user_redesign/UserLevel.vue";
import { useRoute } from 'vue-router';

export default {
  name: "TourneyCard",
  components: {
    UserLevel,
  },
  slots: ["timer"],
  props: {
    title: { type: String },
    prize: { type: Number },
    currency: { type: String }, // btc
    winners: {
      type: Array,
      default: () => [],
    }
  },
  setup() {
    //
    const routeName = useRoute().name;
    //
    return {
      isHomePage: routeName === 'Home',
    }
  },
}
</script>

<template>
  <localized-link class="tourney-card">
    <div class="tourney-card__content">
      <header class="tourney-card__header">
        <h3 class="tourney-card__title">{{ title }}</h3>
      </header>
      <div class="tourney-card__timer">
        <SvgIcon name="watch" size="20" class="tourney-card__icon" />
        <slot name="timer">00:00:00</slot>
      </div>
      <div class="tourney-card__prize">
        <cryptoicon :symbol="currency" size="20" class="tourney-card__currency" />
        {{ prize }} {{ currency.toUpperCase() }}
      </div>
      <div v-if="!isHomePage && $root.isMobile && winners.length > 0" class="tourney-card__winners">
        <div v-for="(winner, index) in winners" :key="winner.id" class="tourney-card__winners-item">
          <div class="index">{{ index + 1 }}</div>
          <img :src="winner.photo" :alt="winner.name" class="photo" />
          <UserLevel
            levelName="gold"
            :leveNumber="winner.level"
          />
          <div class="name">{{ winner.name }}</div>
          <div class="rating">{{ winner.rating }}</div>
          <div class="rating-icon">
            <SvgIcon name="rating-indigo" size="16" />
          </div>
        </div>
      </div>
    </div>
  </localized-link>
</template>

<style lang="scss" scoped>
$mobile-breakpoint: 1087px;

.tourney-card {
  display: inline-block;
  min-height: 153px;
  height: fit-content;
  position: relative;
  border-radius: 8px;
  background-color: var(--dark5);
  box-shadow: 0px 8px 12px 0px rgba(20, 21, 24, 0.15);
  color: var(--white);
  text-transform: uppercase;
  padding: 18px;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    background: url("@/assets/home/grain.png") repeat;
    mix-blend-mode: overlay;
  }

  @include media-lg() {
    padding: 12px;
  }
}
.tourney-card__content {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;
  position: relative;
  height: 100%;
}
.tourney-card__header {
  margin-bottom: 13px;
}
.tourney-card__title {
  font-family: var(--secondary-font);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @include media-lg() {
    font-size: 20px;
  }
}
.tourney-card__timer {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: rgba(29, 29, 36, 0.50);
  backdrop-filter: blur(6px);
  padding: 4px 8px;
  margin-bottom: 10px;
  font-weight: 700;
}

.tourney-card__winners {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin-top: 33px;

  &-item + .tourney-card__winners-item {
    margin-top: 12px;
  }

  &-item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 12px;
    background: rgba(29, 29, 36, 0.50);
    border-radius: 8px;
    backdrop-filter: blur(6px);
    font-family: var(--main-font);
    & .index {
      margin-right: 12px;
      font-size: 14px;
      font-weight: 500;
    }
    & .photo {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
    & .level {
      margin-right: 8px;
    }
    & .name {
      flex-grow: 1;
      font-size: 14px;
      font-weight: 500;
      text-transform: none;
    }
    & .rating {
      margin-right: 6px;
      &-icon {
        display: flex;
        align-items: center;
        & svg {
          --color: #627EEA;
        }
      }
    }
  }
}

.tourney-card__icon {
  --color: var(--white);
  margin-right: 6px;
}
.tourney-card__prize {
  display: flex;
  align-items: center;
  font-family: var(--secondary-font);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: auto;
  justify-self: flex-end;
}
.tourney-card__currency {
  margin-right: 8px;
}
</style>
