<script>
import SvgIcon from "@/components/base/SvgIcon.vue";

export default {
  name: "NavLinks",
  components: {SvgIcon},
  emits: ['logout', 'close'],
  methods: {
    openModalUser() {
      this.$emit('close')
      this.$store.commit('openModalUser', this.$store.getters.getAuthData.hash)
    },
  }
}
</script>

<template>
  <a class="user-navigation-bar-item" @click.prevent="openModalUser">
    <SvgIcon name="user" class="user-navigation-bar-item__icon" size="20" />
    {{ $t('menu.profile') }}</a>
  <localized-link class="user-navigation-bar-item" to="/cabinet/finances" @click="$emit('close')">
    <SvgIcon name="calculator" class="user-navigation-bar-item__icon" size="20" />
    {{ $t('menu.finance') }}</localized-link>
  <localized-link class="user-navigation-bar-item" to="/cabinet/history" @click="$emit('close')">
    <SvgIcon name="history" class="user-navigation-bar-item__icon" size="20" />
    {{ $t('menu.history') }}</localized-link>
  <localized-link class="user-navigation-bar-item" :to="{ name: 'CabinetFair' }" @click="$emit('close')">
    <SvgIcon name="safe" class="user-navigation-bar-item__icon" size="20" />
    {{ $t('menu.fairness') }}</localized-link>
  <localized-link class="user-navigation-bar-item" :to="{ name: 'Vip' }" @click="$emit('close')">
    <SvgIcon name="diamond" class="user-navigation-bar-item__icon" size="20" />
    {{ $t('menu.vip') }}</localized-link>
  <localized-link class="user-navigation-bar-item" to="/cabinet/shop" @click="$emit('close')">
    <SvgIcon name="shop" class="user-navigation-bar-item__icon" size="20" />
    {{ $t('menu.store') }}</localized-link>
  <localized-link class="user-navigation-bar-item" :to="{ name: 'CabinetPartnership' }" @click="$emit('close')">
    <SvgIcon name="document_solid" class="user-navigation-bar-item__icon" size="20" />
    {{ $t('menu.affiliate') }}</localized-link>
  <localized-link class="user-navigation-bar-item" to="/cabinet/settings" @click="$emit('close')">
    <SvgIcon name="settings" class="user-navigation-bar-item__icon" size="20" />
    {{ $t('menu.settings') }}</localized-link>

  <hr class="user-navigation-bar-divider">

  <a class="user-navigation-bar-item user-navigation-bar-item--exit" @click.prevent="$emit('logout')">
    <SvgIcon name="logout" class="user-navigation-bar-item__icon" size="20" />
    {{ $t('menu.logout') }}
  </a>
</template>

<style lang="scss" scoped>
.user-navigation-bar-item {
  color: var(--white);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
  padding: 12px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

  &:hover {
    background-color: var(--dark3);
    color: var(--white);
  }
}

.user-navigation-bar-item__icon {
  margin-right: 8px;
}

.user-navigation-bar-divider {
  background-color: var(--gray);
  margin-top: 3px;
  margin-bottom: 8px;
  display: block;
}
</style>
