<template>
  <div class="window" :class="{close: closeAnimation}">
    <div class="window-background" @click.prevent="close()" />


    <div class="window-data">
      <button class="window-data-close" @click.prevent="close()" />

      <div class="window-data-menu">
        <button :class="{active: walletType === 'deposit'}">{{ $t('menu.deposit') }}</button>
        <button :class="{active: walletType === 'payout'}">{{ $t('menu.payout') }}</button>
        <button :class="{active: walletType === 'exchange'}">{{ $t('menu.exchange') }}</button>
        <button :class="{active: walletType === 'bank'}">{{ $t('menu.bank') }}</button>
      </div>
      123 {{walletType}}
    </div>
  </div>
</template>

<script>
export default {
  name: "Wallet",
  //emits: ['close'],
  props: ['walletType'],
  data() {
    return {
      closeAnimation: false,
    }
  },
  created() {

    this.$Progress.finish()
  },
  methods: {
    close: function() {
      //this.$emit('close')
      this.closeAnimation = true
      setTimeout(() => {
        this.$store.commit('closeModalWallet')
      }, 400)
    }
  }
}
</script>

<style src="../../assets/styles/modal-window.scss" lang="scss" />
<style scoped>

</style>
