<template>
  <div class="modal is-active">
    <div class="modal-background"></div>

    <div class="modal-card-wrap">
      <div class="logo-top">
        <img src="../../assets/logo_gold.svg" alt="windice gold" />
      </div>
      <div class="modal-card">
        <div class="bonus-title">{{ $t('modals.daily.hello') }} <span>{{ $store.getters.getAuthData.username }}</span></div>
        <div class="bonus-title-two">{{ $t('modals.daily.welcome') }}</div>

        <div class="bonus-current">
          <div class="bonus-current-title is-uppercase">{{ $t('modals.daily.reward_your[0]') }} <span>{{ $t('modals.daily.reward_your[1]') }}</span> {{ $t('modals.daily.reward_your[2]') }}</div>
          <div class="bonus-current-data">
            <div class="bonus-current-icon"><img src="../../assets/vip/daily_bonus.svg" alt="" /></div>
            <div class="bonus-current-prize">+ {{todayTokens}} {{ $t('modals.daily.tokens') }} <span>+ {{todayExp}} EXP</span></div>
            <div class="bonus-current-buttons"><a @click="collect(day)" class="button is-uppercase" :class="{'is-loading': loader}">{{ $t('modals.daily.collect') }}</a></div>
          </div>
        </div>

        <div class="bonus-title-down is-uppercase">{{ $t('modals.daily.streak') }}</div>
        <div class="bonus-title-down-two">{{ $t('modals.daily.streak_text') }}</div>

        <div class="bonus-days">

          <div @click="collect(parseInt(n))" v-for="(item, n) in prize" :key="n" class="bonus-day-wrap" :class="{collected: parseInt(n) < day, take: parseInt(n) === day}">
            <div class="bonus-day">
              <div class="bonus-day-icon">
                <img src="../../assets/vip/daily_bonus.svg" alt="" />
              </div>
              <div class="bonus-day-data">
                <div v-for="(i, k) in item" :key="k">+{{i.amount}} <span v-if="i.name==='win'">{{ $t('modals.daily.tokens') }}</span><span v-if="i.name==='xp'">XP</span></div>
              </div>
              <div class="bonus-day-status">
                <div class="is-uppercase">{{parseInt(n) + 1}} {{ $t('modals.daily.day') }}</div>
                <div v-if="parseInt(n) < day">{{ $t('modals.daily.collected') }}</div>
                <div v-else-if="parseInt(n)===day"><a @click="collect()" class="button" :class="{'is-loading': loader}">{{ $t('modals.daily.collect').toLowerCase() }}</a></div>
                <div v-else>{{ $t('modals.daily.wait') }}</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: "DailyBonus",
    data() {
      return {
        loader: false
      }
    },
    computed: {
      prize: function() {
        return this.$store.getters.getBonusDailyPrize
      },
      day: function() {
        return this.$store.getters.getBonusDailyDay
      },
      todayTokens: function() {
        for (let key in this.prize) {
          if (this.prize.hasOwnProperty(key) && parseInt(key) === this.day) {
            for (let i = 0; i < this.prize[key].length; i++) {
              if (this.prize[key][i].name === 'win') {
                return this.prize[key][i].amount
              }
            }
          }
        }
        return 0
      },
      todayExp: function() {
        for (let key in this.prize) {
          if (this.prize.hasOwnProperty(key) && parseInt(key) === this.day) {
            for (let i = 0; i < this.prize[key].length; i++) {
              if (this.prize[key][i].name === 'xp') {
                return this.prize[key][i].amount
              }
            }
          }
        }
        return 0
      }
    },
    methods: {
      collect: function(value) {
        if (value !== this.day) return
        if (this.loader) return
        this.loader = true

        this.axios.get('/user/bonus/daily?t=' + new Date().getTime())
          .then(response => {
            if (response.data.status !== 'success') {
              this.$store.dispatch('addNotif', {type: 'danger', text: response.data.message, isClose: false, timer: 2000}).then()
            }
            //this.$store.commit('bonusDailyClose')
            this.$store.dispatch('bonusDailyClose').then()
            this.loader = false
          })
          .catch(() => {
            this.$store.dispatch('addNotif', {type: 'danger', text: 'Server error', isClose: false, timer: 2000}).then()
            this.loader = false
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .modal-card-wrap {
    position: relative;
    display: flex;
    justify-content: center;

    .logo-top {
      position: absolute;
      top: -45px;
      width: 90px;
      height: 90px;
      padding: 3px;
      border-radius: 90px;
      background-color: #060606;
      border: 6px solid #1D1E23;
      z-index: 2;
    }
    .modal-card {
      padding-top: 40px;
      background-color: #1D1E23;

      .bonus-title {
        font-size: 22px;
        font-weight: bold;
        > span {
          color: #00ACFF;
        }
      }
      .bonus-title-two {
        font-size: 16px;
        font-weight: bold;
        line-height: 16px;
      }

      .bonus-current {
        background-color: #272B37;
        padding: 10px;
        margin: 20px 0;

        .bonus-current-title {
          font-size: 26px;
          font-weight: bold;
          > span {
            color: #3FD144;
          }
        }
        .bonus-current-data {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 80px;
          .bonus-current-icon {
            display: flex;
            height: 80px;
            z-index: 1;
          }
          .bonus-current-prize {
            height: 60px;
            margin-left: -40px;
            padding-left: 40px;
            padding-right: 10px;
            background-color: #1d1e23;
            font-size: 22px;
            font-weight: bold;
            color: #F7C162;
            display: flex;
            align-items: center;
            border-bottom-left-radius: 30px;
            border-top-left-radius: 30px;
            > span {
              margin-left: 10px;
              color: #00F6FF;
            }
          }
          .bonus-current-buttons {
            height: 60px;
            background-color: #373842;
            padding: 0 20px;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            display: flex;
            align-items: center;
            > a.button {
              background-color: #00ACFF;
              border-color: #00ACFF;
              color: #FFF;
              font-weight: bold;
              font-size: 18px;
              padding-right: 1em;
              padding-left: 1em;
              transition: .3s ease;
              &:hover {
                background-color: #28bcff;
              }
            }
          }
        }
      }

      .bonus-title-down {
        font-size: 22px;
        font-weight: bold;
      }
      .bonus-title-down-two {
        font-size: 12px;
        color: #00F6FF;
      }

      .bonus-days {
        margin: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .bonus-day-wrap {
          margin: 5px 5px;

          .bonus-day {
            .bonus-day-icon {
              display: flex;
              height: 30px;
              width: 100px;
              z-index: 1;
              margin-bottom: -10px;
              transition: .3s ease;
              justify-content: center;
            }
            .bonus-day-data {
              background-color: #28292D;
              border-radius: 8px;
              padding: 15px 3px 5px 3px;
              border: 2px solid #28292D;
              transition: .3s ease;
              > div {
                font-size: 12px;
                font-weight: bold;
                color: #F7C162;
                &:last-of-type {
                  color: #00F6FF;
                }
              }
            }
            .bonus-day-status {

              padding-top: 5px;
              display: flex;
              justify-content: space-between;
              > div {
                display: flex;
                align-items: center;
                &:first-of-type {
                  background-color: #28292D;
                  border-radius: 8px;
                  padding: 0 6px;
                  color: #00F6FF;
                  font-weight: bold;
                  font-size: 14px;

                  justify-content: center;
                }
                &:last-of-type {
                  padding-right: 3px;
                  color: #555555;
                  font-weight: bold;
                  > a {
                    background-color: #00ACFF;
                    border-color: #00ACFF;
                    color: #FFF;
                    font-weight: bold;
                    font-size: 8px;
                    transition: .3s ease;
                    padding: 0 5px;
                    text-transform: uppercase;
                    &:hover {
                      background-color: #28bcff;
                    }
                  }
                }
              }
            }
          }
          &.collected, &.take:hover {
            .bonus-day {
              .bonus-day-icon {
                height: 35px;
                margin-bottom: -15px;
              }
              .bonus-day-data {
                border: 2px solid #3FD144;
                box-shadow: 0 3px 14px rgba(63, 209, 68, 0.60);
              }
              .bonus-day-status {
                > div {
                  &:last-of-type {
                    font-size: 10px;
                  }
                }
              }
            }
          }
          &.take {
            cursor: pointer;
          }
        }
      }
    }
    @media (max-width: 767px) {

      .modal-card {
        margin: 0 10px;
        .bonus-current {
          margin: 10px 0;
          .bonus-current-title {
            font-size: 22px;
          }
          .bonus-current-data {
            .bonus-current-icon {
              height: 40px;
              > img {
                max-height: 40px;
              }
            }
            .bonus-current-prize {
              height: 40px;
              font-size: 12px;

              padding-left: 50px;

            }
            .bonus-current-buttons {
              height: 40px;
              padding: 0 10px;
              > a.button {
                font-size: 14px;
                padding: 0 6px;
              }
            }
          }
        }
        .bonus-title-down-two {
          padding: 0 5px;
        }
        .bonus-days {
          overflow: auto;
        }
      }
    }
  }
</style>
