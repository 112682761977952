<script lang="ts">
import {PropType} from "vue";
type TypeLevel = 'newbie' | 'copper' | 'bronze' | 'silver' | 'gold' | 'platinum' | 'diamond';

export default {
  name: "LevelIcon",
  props: {
    level: { type: String as PropType<TypeLevel>, required: true },
  }
}
</script>

<template>
  <svg class="profile-level-icon" :class="level" width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="path-gradient" d="M30 20.8463L30 11.1537C29.9965 10.0405 29.6969 8.9482 29.1316 7.98845C28.5663 7.02871 27.7558 6.23593 26.7828 5.69117L18.2172 0.844896C17.2358 0.291079 16.1275 -1.09645e-07 15 -1.0198e-07C13.8725 -9.43144e-08 12.7642 0.291079 11.7828 0.844896L3.21719 5.69117C2.2442 6.23593 1.43365 7.02871 0.868398 7.98845C0.303145 8.9482 0.00346901 10.0405 7.583e-08 11.1537L1.41727e-07 20.8463C0.00346909 21.9595 0.303145 23.0518 0.868398 24.0115C1.43365 24.9713 2.2442 25.764 3.21719 26.3088L11.7828 31.1551C12.7642 31.709 13.8725 32 15 32C16.1275 32 17.2358 31.709 18.2172 31.1551L26.7828 26.3088C27.7558 25.764 28.5663 24.9713 29.1316 24.0115C29.6969 23.0518 29.9965 21.9595 30 20.8463Z" />
    <path d="M25.6863 6.88651L17.7186 2.36729C16.8896 1.89819 15.9528 1.65161 14.9997 1.65161C14.0466 1.65161 13.1098 1.89819 12.2807 2.36729L4.31301 6.88651C3.49042 7.34908 2.80543 8.02096 2.32783 8.83367C1.85023 9.64638 1.59711 10.5709 1.59424 11.513L1.59424 12.7744C1.59705 11.8322 1.85014 10.9076 2.32774 10.0948C2.80534 9.28206 3.49037 8.61015 4.31301 8.14755L12.2807 3.62833C13.1098 3.1592 14.0466 2.91261 14.9997 2.91261C15.9528 2.91261 16.8896 3.1592 17.7186 3.62833L25.6863 8.14755C26.509 8.61015 27.194 9.28206 27.6716 10.0948C28.1492 10.9076 28.4023 11.8322 28.4051 12.7744L28.4051 11.513C28.4022 10.5709 28.1491 9.64638 27.6715 8.83367C27.1939 8.02096 26.5089 7.34908 25.6863 6.88651Z" fill="var(--color1)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7867 7.68311L21.5432 10.381C22.0837 10.6849 22.5339 11.1263 22.8477 11.6603C23.1615 12.1943 23.3278 12.8017 23.3296 13.4207V13.6723L23.3297 13.6932V13.9878V14.0929V14.2824V14.3875V14.6822C23.3279 14.0631 23.1616 13.4556 22.8478 12.9217C22.534 12.3877 22.0839 11.9463 21.5433 11.6424L16.7868 8.94451C16.2422 8.63642 15.6268 8.47449 15.0008 8.47449C14.3747 8.47449 13.7593 8.63642 13.2147 8.94451L8.45821 11.6424C7.91761 11.9462 7.46744 12.3877 7.15357 12.9217C6.83969 13.4556 6.67336 14.0631 6.67151 14.6822V14.3875L6.67151 14.2824L6.67151 14.0929L6.67151 13.9878L6.67151 13.7998L6.67139 13.8204V13.4207C6.6732 12.8017 6.83952 12.1942 7.1534 11.6602C7.46727 11.1262 7.91747 10.6848 8.45808 10.381L13.2146 7.68311C13.7592 7.37506 14.3746 7.21313 15.0006 7.21313C15.6267 7.21313 16.242 7.37506 16.7867 7.68311Z" fill="var(--color1)"/>
    <defs>
      <linearGradient id="newbie" x1="15" y1="-1.0198e-07" x2="15" y2="32" gradientUnits="userSpaceOnUse">
        <stop stop-color="#ffffff"/>
        <stop offset="1" stop-color="#393939"/>
      </linearGradient>
      <linearGradient id="copper" x1="15" y1="-1.0198e-07" x2="15" y2="32" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EAC09C"/>
        <stop offset="1" stop-color="#E8A367"/>
      </linearGradient>
      <linearGradient id="bronze" x1="15" y1="-1.0198e-07" x2="15" y2="32" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFA77A"/>
        <stop offset="1" stop-color="#FF7F1E"/>
      </linearGradient>
      <linearGradient id="silver" x1="15" y1="-1.0198e-07" x2="15" y2="32" gradientUnits="userSpaceOnUse">
        <stop stop-color="#D8D8D8"/>
        <stop offset="1" stop-color="#8F8F8F"/>
      </linearGradient>
      <linearGradient id="gold" x1="15" y1="-1.0198e-07" x2="15" y2="32" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFFA89"/>
        <stop offset="1" stop-color="#FFA800"/>
      </linearGradient>
      <linearGradient id="platinum" x1="15" y1="-1.0198e-07" x2="15" y2="32" gradientUnits="userSpaceOnUse">
        <stop stop-color="#D4F0FF"/>
        <stop offset="1" stop-color="#7A7BA7"/>
      </linearGradient>
      <linearGradient id="diamond" x1="15" y1="-1.0198e-07" x2="15" y2="32" gradientUnits="userSpaceOnUse">
        <stop stop-color="#84CFFF"/>
        <stop offset="1" stop-color="#2094FF"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<style lang="scss" scoped>
.profile-level-icon {
  height: 100%;
  width: 100%;

  &.newbie {
    --color1: #393939;
    .path-gradient { fill: url(#newbie);  }
  }
  &.copper {
    --color1: #A1704F;
    .path-gradient { fill: url(#copper);  }
  }
  &.bronze {
    --color1: #DE6128;
    .path-gradient { fill: url(#bronze);  }
  }
  &.silver {
    --color1: #464646;
    .path-gradient { fill: url(#silver);  }
  }
  &.gold {
    --color1: #CB9424;
    .path-gradient { fill: url(#gold);  }
  }
  &.platinum {
    --color1: #515C80;
    .path-gradient { fill: url(#platinum);  }
  }
  &.diamond {
    --color1: #0074BF;
    .path-gradient { fill: url(#diamond);  }
  }
}
</style>
