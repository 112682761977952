<template>
    <div v-show="!$store.getters.isPageLoad">
      <ServerError type="loader"></ServerError>
    </div>

    <div v-if="$store.getters.isPageLoad">
      <transition name="fade-up" mode="out-in">
        <div class="reconnect" v-if="!$store.getters.isConnectedSocket">{{ $t('global.connection') }}<span>.</span><span>.</span><span>.</span></div>
      </transition>
    </div>

    <Notifications />

    <Maintenance v-if="maintenance"></Maintenance>

    <Auth isModal v-if="$store.getters.isModalAuth"></Auth>

    <DailyBonus v-if="$store.getters.isBonusDaily" />

      <Wallet
          v-if="$store.getters.isModalWallet"
          :wallet-type="$store.getters.getModalWallet"
          key="modal-wallet"
      />

    <transition name="slide-fade">
      <ModalInfo v-if="$store.getters.isModalBet" key="modal-bet"></ModalInfo>
    </transition>

    <transition name="slide-fade">
      <ModalUser v-if="$store.getters.isModalUser" key="modal-user"></ModalUser>
    </transition>

    <transition name="slide-fade">
      <ModalTips v-if="$store.getters.isAuth && $store.getters.isModalTips" key="modal-tips"></ModalTips>
    </transition>

    <transition name="slide-fade">
      <ModalTrivia v-if="$store.getters.isAuth && $store.getters.isModalTrivia" key="modal-trivia"></ModalTrivia>
    </transition>

    <transition name="slide-fade">
      <ModalRain v-if="$store.getters.isAuth && $store.getters.isModalRain" key="modal-rain"></ModalRain>
    </transition>

    <transition name="slide-fade">
      <ModalExchange v-if="$store.getters.isAuth && $store.getters.isModalExchange" key="modal-exchange"></ModalExchange>
    </transition>

    <transition name="slide-fade">
      <ModalDeposit v-if="$store.getters.isAuth && $store.getters.isModalDeposit" key="modal-payin"></ModalDeposit>
    </transition>

    <transition name="slide-fade">
      <ModalPayout v-if="$store.getters.isAuth && $store.getters.isModalPayout" key="modal-payout"></ModalPayout>
    </transition>

    <transition name="slide-fade">
      <Faucet v-if="$store.getters.isAuth && $store.getters.isFaucetOpen" key="modal-faucet"></Faucet>
    </transition>

    <transition name="slide-fade">
      <ChatBan v-if="($store.getters.isAdmin || $store.getters.isModer) && $store.getters.isModalChatBan" key="modal-chat-ban"></ChatBan>
    </transition>

    <transition name="slide-fade">
      <ChatUnban v-if="($store.getters.isAdmin || $store.getters.isModer) && $store.getters.isModalChatUnBan" key="modal-chat-un-ban"></ChatUnban>
    </transition>

    <transition name="slide-fade">
      <ModalCoupon v-if="$store.getters.isModalCoupon" key="modal-coupon" />
    </transition>


  <!--  START CONTENT -->
  <div v-if="$store.getters.isPageLoad" style="height: 100%; margin-top: 0; margin-bottom: 0;">
    <!--    SIDE BAR-->
    <NavSideBarWrap :open="isLeftNavOpen" @closeSideBar="burgerMenuClick"  />

    <!--   MAIN CONTENT-->
    <div class="main-block" :style="{'--margin-right': isRightMenuOpen ? $store.getters.getChatWidth : '0'}">
      <NavHeader class="main-block__header"
                 :class="{ open: isRightMenuOpen }"
                 :style="{'--margin-right': isRightMenuOpen ? '0' : '156px'}"
                 :open="isRightMenuOpen"
                 @burgerMenuClick="burgerMenuClick"
      />
      <div class="page-data" id="pageData" :class="$store.getters.getTourneyTeamClass">
        <div class="main-block__inner">
          <div id="mainBlockContent" class="main-block__content">
            <router-view  />
          </div>
          <NavFooter />
        </div>
        <vue-progress-bar />
      </div>
    </div>

    <!--    CHAT-->
    <div class="chat-box-new"
         :class="{ open: isRightMenuOpen }"
         :style="{ '--width': isRightMenuOpen ? $store.getters.getChatWidth : '156px' }"
    >
      <div v-show="isRightMenuOpen" class="chat-loader-new">
        <font-awesome-icon icon="spinner" size="5x" pulse/>
      </div>
      <ChatView />
    </div>
  </div>
  <!--  END MAIN CONTENT -->


    <div class="upgrade-version notification is-info" v-if="openUpgrade">
      <div>{{ $t('global.new_version') }}</div>
      <button class="button is-danger is-small" @click="reloadPage">{{ $t('global.reload_page') }}</button>
    </div>

    <div class="reload-page notification is-danger" v-if="countSocketConnect > 10 && !$store.getters.isConnectedSocket">
      <div>{{ $t('global.many_reconnects') }}</div>
      <button class="button is-info is-small" @click="reloadPage">{{ $t('global.reload_page') }}</button>
    </div>

    <Intercom :class="{'inter-maintenance': maintenance}" />

</template>

<script>
  import { defineAsyncComponent } from 'vue'
  import FingerprintJS from '@fingerprintjs/fingerprintjs'
  import NavFooter from './components/navbars_redesign/NavFooter.vue'
  import NavHeader from './components/navbars_redesign/NavHeader.vue'
  import NavSideBarWrap from './components/navbars_redesign/nav-side-bar/NavSideBarWrap.vue'
  import Auth from './components/modals/Auth.vue'
  import ServerError from './components/ServerError.vue'
  import Maintenance from "./components/Maintenance.vue"
  import DailyBonus from "./components/modals/DailyBonus.vue"
  import Wallet from "@/components/modals/Wallet.vue"
  import Notifications from "@/components/Notifications.vue"
  // MIXINS
  import { globalResizeMixin } from '@/mixins/Breakpoints'
  //
  const ChatView = defineAsyncComponent(() => import("@/components/messages_redisign/ChatView.vue"))

  const Intercom = defineAsyncComponent(() => import("@/components/Intercom.vue"))
  const NavEvents = defineAsyncComponent(() => import("@/components/navbars/NavEvents.vue"))

  const Faucet = defineAsyncComponent(() => import('./components/modals_redesign/ModalFaucet.vue'))
  const ModalInfo = defineAsyncComponent(() => import('./components/modals/ModalInfo.vue'))
  const ModalUser = defineAsyncComponent(() => import('./components/modals_redesign/ModalUser.vue'))
  const ModalTips = defineAsyncComponent(() => import("./components/modals/ModalTips.vue"))
  const ModalTrivia = defineAsyncComponent(() => import("./components/modals/ModalTrivia.vue"))
  const ModalRain = defineAsyncComponent(() => import("./components/modals/ModalRain.vue"))
  const ModalExchange = defineAsyncComponent(() => import("./components/modals_redesign/ModalExchange.vue"))
  const ModalDeposit = defineAsyncComponent(() => import("./components/modals_redesign/ModalDeposit.vue"))
  const ModalPayout = defineAsyncComponent(() => import("./components/modals_redesign/ModalPayout.vue"))

  let ChatBan = defineAsyncComponent(() =>  import("./components/admin/ChatBan.vue"))
  let ChatUnban = defineAsyncComponent(() =>  import("./components/admin/ChatUnban.vue"))
  let ModalCoupon = defineAsyncComponent(() =>  import("./components/modals/ModalCoupon.vue"))

  //import Fingerprint2 from 'fingerprintjs2'

  export default {
    name: 'App',
    mixins: [globalResizeMixin],
    components: {
      Notifications,
      Wallet,
      Intercom,
      DailyBonus,
      Maintenance,
      ChatBan,
      ChatUnban,
      ModalCoupon,
      ModalDeposit,
      ModalPayout,
      ModalTips,
      ModalTrivia,
      ModalRain,
      ModalExchange,
      ModalUser,
      Faucet,
      ModalInfo,
      Auth,
      ServerError,

      NavFooter,
      NavHeader,
      NavSideBarWrap,
      ChatView,
    },
    data() {
      return {
        appLang: this.$i18n.locale || "en",
        Fingerprint2: null,
        openUpgrade: false,
        versionTimer: null,
        maintenance: false,
        countSocketConnect: 0,
        isLeftNavOpen: false,
      }
    },
    mounted () {
      (function(l){
        let i, s={touchend:function(){}}
        for(i in s) l.addEventListener(i, s)
      })(document) // sticky hover fix in iOS

      this.$Progress.finish()

      const fpPromise = FingerprintJS.load();(async () => {
        // Get the visitor identifier when you need it.
        const fp = await fpPromise
        const result = await fp.get()
        //console.log(result.visitorId)

        this.runFingerPrintNew(result)
      })()

      let lastTouchStart = 0
      document.addEventListener('touchstart', function (event) {
        let now = (new Date()).getTime()
        if (now - lastTouchStart <= 500) {
          event.preventDefault()
        }
        lastTouchStart = now
      }, false)


    },
    created () {

      this.checkSupportWebP()

      this.$connect()

      this.$Progress.start()

      this.$router.beforeEach((to, from, next) => {
        //  does the page we want to go to have a meta.progress object
        if (to.meta.progress !== undefined) {
          let meta = to.meta.progress
          // parse meta tags
          this.$Progress.parseMeta(meta)
        }
        //  start the progress bar
        this.$Progress.start()
        //  continue to next page
        next()
      })
      //  hook the progress bar to finish after we've finished moving router-view
      this.$router.afterEach(() => {
        //  finish the progress bar
        this.$Progress.finish()
      })

      this.getUserData()
      this.checkVersion()
      this.$store.commit('loadGameSettings')
      this.$store.commit('loadSounds')
      this.$store.commit('loadSoundsNotifications')

      window.addEventListener('visibilitychange', this.visibilityChange)


      this.$nextTick(function () {
        this.saveReferral()
        this.openModalByGetParameter()
        this.saveStagParameter()
      })

    },
    beforeUnmount() {
      window.removeEventListener('visibilitychange', this.visibilityChange)
    },
    computed: {
      isAuthUser: function () {
        return this.$store.getters.isAuth
      },
      isConnectedSocket: function () {
        return this.$store.getters.isConnectedSocket
      },
      isRightMenuOpen: function () {
        return this.$store.getters.isChatOpen
      },
      currentVersion: function() {
        return this.$store.getters.getVersion
      },
      isPrivilege: function() {
        return this.$store.getters.isModer || this.$store.getters.isAdmin
      }
    },
    watch: {
      isAuthUser: function (newVal) {
        if (newVal === true && this.isConnectedSocket === true) {
          this.$store.dispatch("sendSocketAuth").then()

          let IntS = setInterval(function () {
            if (this.$store.getters.isAuthSecLoad && this.$store.getters.isAuthSocket) {
              clearInterval(IntS)
              this.$store.dispatch('sendSocketAuthSecGet').then()
            }
          }.bind(this), 1000)

          //обрабатываем редирект если он есть и юзер авторизован
          let redirect = this.getParameterByName('redirect')
          if (redirect !== null) {
            if (redirect.substr(1, 5) !== 'admin' && /^([a-z0-9/])+$/.test(redirect)) {
              this.$router.push({path: redirect})
            }
          }
        }
      },
      isPrivilege: function(value) {
        if (value === true) {
          setTimeout(()=> {
            let redirect = this.getParameterByName('redirect')
            if (redirect !== null && redirect !== this.$router.currentRoute.path && /^([a-z0-9/])+$/.test(redirect)) {
              this.$router.push({path: redirect})
            }
          }, 50)
        }
      },
      isConnectedSocket: function (newVal) {
        if (newVal === true && this.$store.getters.getAuthToken.length > 0) {
          this.getUserData()
        }

        if (newVal === true && this.$store.getters.isAuth) {
          this.$store.dispatch("sendSocketAuth").then()

          let IntS = setInterval(function () {
            if (this.$store.getters.isAuthSecLoad && this.$store.getters.isAuthSocket) {
              clearInterval(IntS)
              this.$store.dispatch('sendSocketAuthSecGet').then()
            }
          }.bind(this), 1000)
        }

        clearInterval(this.versionTimer)
        this.versionTimer = setInterval(() => this.checkVersion(), 60000 * 5)
        this.plusConnects()
      },
      isRightMenuOpen: function (val) {
      },
    },
    methods: {
      burgerMenuClick(open = false) {
        this.$store.commit('changeScroll', !open)
        this.isLeftNavOpen = open
      },
      saveReferral: function() {
        // referral
        const ref = this.getParameterByName('r')
        if (ref !== null && ref.length > 0) {
          // console.log('Referral link: ' + ref)
          this.$cookies.set('referral', ref, 14)
          localStorage.setItem('referral', ref)

          const link = document.referrer
          if (link !== "") {
            this.$cookies.set('link', link, 14)
            localStorage.setItem('link', link)
          }

          this.sendPartnerHit(ref, link)
          this.$router.push("/")
        }
      },
      openModalByGetParameter: function () {
        let modal = this.getParameterByName('modal')
        if (modal !== null) {
          modal = modal.replace(/[^a-z0-9\s]/gi, '')
        }
        switch (modal) {
          case 'deposit':
            this.$store.commit('openModalDeposit')
            break
        }
      },
      saveStagParameter: function() {
        // user transferred from another site
        const stag = this.getParameterByName('stag')
        if (stag !== null && stag.length > 0) {
          localStorage.setItem('affiliate_tag', stag)
        }
      },
      visibilityChange: function() {
        if (this.$store.getters.getDeviceType === 'mobile' && document.visibilityState === 'visible') {
          this.$store.dispatch('socketCheckConnectFrozen').then()
        }
      },
      plusConnects: function() {
        this.countSocketConnect++
      },

      closeModalMessage: function () {
        this.message = 'noset'
      },
      getParameterByName: function (name, url) {
        if (!url) url = window.location.href
        name = name.replace(/[\\[\\]]/g, '\\$&')
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
        const results = regex.exec(url)
        if (!results) return null
        if (!results[2]) return ''
        return decodeURIComponent(results[2].replace(/\+/g, ' '))
      },
      getUserData: function () {
        if (this.$store.getters.getAuthToken === '') {
          this.$store.commit('AUTH_NO')
          return false
        }

        //const self = this
        this.axios.get('/auth/user?' + new Date().getTime() + `&language=${this.appLang}`)
          .then(response => {
            if (response.data.status === 'success') {
              this.$store.commit('AUTH_SUCCESS', response.data)

              // send token to Google Analytics (after success login)
              this.$gtag.config({ user_id: response.data.data.hash })

              this.$store.dispatch('createLive').then()
            } else if (response.data.status === 'error' && response.data.message !== '') {
              if (response.data.message === 'Authentication error') {
                this.$store.commit('AUTH_LOGOUT')
                this.$store.commit('tourney_team', -1)
                this.$store.dispatch('clearDatabaseAll').then()
              }
              this.$store.dispatch('addNotif', {type: 'danger', text: response.data.message, isClose: false, timer: 2000}).then()
            }
          })
          .catch((e) => {
            if (e.response !== undefined && e.response.status === 401) {
              this.$store.commit('AUTH_LOGOUT')
              this.$store.commit('tourney_team', -1)
              this.$store.dispatch('clearDatabaseAll').then()
            }
            this.$store.dispatch('addNotif', {type: 'danger', text: 'Server error', isClose: false, timer: 2000}).then()
          })
      },
      runFingerPrintNew: function (result) {
        this.axios.defaults.headers.common['X-Data'] = result.visitorId

        let encodedData = window.btoa(unescape(encodeURIComponent(JSON.stringify(result.components))))
        this.$store.commit('authSecSet', {hash: result.visitorId, data: encodedData, load: true})
      },
      checkVersion: function() {
        this.axios.get(this.$store.getters.getHost + '/version.json?' + Date.now())
          .then(response => {
            if (this.currentVersion === null) {
              this.$store.commit('setVersion', response.data.version)
              localStorage.setItem('version', response.data.version)
            } else if (this.currentVersion !== response.data.version) {
              this.$store.commit('setVersion', response.data.version)
              this.openUpgrade = true

              if (response.data.upgrade === true) {
                this.reloadPage()
              }
            }

            if (response.data.maintenance !== undefined) {
              this.maintenance = response.data.maintenance
              this.$store.commit('changeScroll', !this.maintenance)
            }

            console.log("Version: " + this.currentVersion)
          })
          .catch(()=>{
            this.$store.dispatch('addNotif', {type: 'danger', text: 'Error get version', isClose: false, timer: 2000}).then()
          })
      },
      reloadPage: function () {
        localStorage.setItem('version', this.currentVersion)
        window.location.reload(true)
      },
      sendPartnerHit: function(referral, link) {
        if (this.axios.defaults.headers.common['X-Data'] === undefined) {
          setTimeout(()=>{
            this.sendPartnerHit(referral, link)
          }, 1000)
        } else {
          this.axios.post('/partner/hit', {referral: referral, link: link}).then()
        }
      },
      checkSupportWebP: function() {
        (async () => {
          let useWebp = false;
          if (typeof document === 'object') {
            // Client side rendering
            const canvas = document.createElement('canvas');

            if (
                canvas.getContext &&
                canvas.getContext('2d') &&
                canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0
            ) {
              useWebp = true;
            }
          }

          this.$store.commit('setSupportWebP', useWebp)
        })();
      }
    }
  }
</script>

<style lang="scss">
  .page-data {
    display: flex;
    background-color: var(--dark4);
  }
  .main-block{
    --margin-right: 0px;
    transition: margin-right .3s;
    margin-right: var(--margin-right);
    margin-left: 224px;

    @include media-lg() {
      margin-right: 0;
      margin-left: 0;
    }
  }
  .main-block__inner {
    border-radius: 12px 12px 0px 0px;
    border-top: 1px solid var(--dark7);
    border-right: 1px solid var(--dark7);
    border-left: 1px solid var(--dark7);
    background: var(--dark2);
    width: 100%;
    @include media-lg() {
      border-radius: 0;
    }
  }
  .main-block__content {
    max-width: calc(1170px + var(--mobile-content-padding) + var(--mobile-content-padding));
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--mobile-content-padding);
    padding-right: var(--mobile-content-padding);

    @include media-lg() {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .main-block__header {
    transition: margin-right .3s;
    margin-right: var(--margin-right);

    @include media-lg() {
      margin-right: 0;
    }
    &.open {
      margin-right: 0;
    }
  }
  .chat-box-new{
    --width: 0;
    width: 100%;
    max-width: var(--width);
    height: 100%;
    position: absolute;
    z-index: 32;
    top: 0;
    right: 0;
    overflow: hidden;
    transition: max-width 0.3s;
    max-height: 80px;
    &.open {
      max-height: 100vh;
      position: fixed;
    }
    @include media-lg() {
      display: none;
      &.open {
        display: block;
      }
    }
  }
  .chat-loader-new{
    display: block;
    position: absolute;
    color: #3e3e42;
    left: 0;
    right: 0;
    bottom: calc(50% - 40px);
    z-index: 0;
  }
  #app {
    font-family: Roboto, "sans-serif", Arial;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    text-align: center;
    color: var(--white);
    background-color: var(--dark2);
  }

  .column-chat .chat-box{
    height: 100vh;
    transition: all .5s linear;
    position: relative;
  }
  .column-chat .chat-loader{
    display: block;
    position: absolute;
    color: #00769c;
    left: 0;
    right: 0;
    bottom: calc(50% - 40px);
    z-index: 0;
  }

  .upgrade-version.notification.is-info,
  .reload-page.notification.is-danger {
    position: fixed;
    left: 5px;
    bottom: 5px;
    opacity: .9;
    padding: 5px 10px;
    text-transform: uppercase;
    font-weight: bold;
    z-index: 99999;
    > div {
      padding-right: 0;
    }
  }
  .upgrade-version button,
  .reload-page button {
    font-weight: bold;
  }

  .list-item {
    display: inline-block;
    margin-right: 10px;
  }
  .list-enter-active, .list-leave-active {
    transition: all 1s;
  }
  .list-enter {
    opacity: 0;
    transform: translateY(-30px);
  }
  .list-leave-to {
    opacity: 0;
    transform: translateY(30px);
  }

  .slide-fade-enter-active,
  .slide-fade-leave-active  {
    transition: all 1.0s ease;
  }

  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateX(100%);
    opacity: 0;
  }

  .modal.slide-fade-enter-active,
  .modal.slide-fade-leave-active  {
    transition: all .5s ease;
  }
  .modal.slide-fade-enter,
  .modal.slide-fade-leave-to {
    transform: none;
    opacity: 0;
  }


  .fade-up-enter-active,
  .fade-up-leave-active  {
    transition: all 1.0s ease;
  }

  .fade-up-enter,
  .fade-up-leave-to {
    transform: translateY(-100%);
    opacity: 0;
  }

  .reconnect {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    height: 40px;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab );
    background-size: 600% 100%;
    animation: gradient 3s ease-in-out infinite;
    animation-direction: alternate;
    z-index: 100;

    font-size: 26px;
    font-weight: bold;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  @keyframes gradient {
    0% {background-position: 0%}
    100% {background-position: 100%}
  }

  .reconnect span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;

    font-size: 45px;
    margin-top: -12px;
  }
  .reconnect span:nth-child(2) {
    animation-delay: .2s;
  }
  .reconnect span:nth-child(3) {
    animation-delay: .4s;
  }
  @keyframes blink {
    0% { opacity: .2;}
    20% { opacity: 1;}
    100% { opacity: .2;}
  }

  @media (max-width: 767px) {
    .reconnect {
      font-size: 20px;
    }
    .reconnect span {
      font-size: 36px;
    }
  }

  .inter-maintenance {
    z-index: 999999 !important;
  }

</style>
