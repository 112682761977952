<script>
export default {
  name: "NavLinks",
  computed: {
    isAuthUser: function () {
      return this.$store.getters.isAuth
    },
  }
}
</script>

<template>
  <div class="links">
    <localized-link class="link" to="/bonuses">
      <SvgIcon name="gift" size="24" class="link__icon"/>
      {{ $t('menu.bonuses') }}</localized-link>
    <localized-link class="link" to="/faq">
      <SvgIcon name="question_mark" size="24" class="link__icon"/>
      {{ $t('menu.faq') }}</localized-link>
    <a class="link" href="https://forum.windice.io" target="_blank">
      <SvgIcon name="chat_invert" size="24" class="link__icon"/>
      {{ $t('menu.forum') }}</a>
    <localized-link class="link" to="/stats/profit/day/btc">
      <SvgIcon name="pie" size="24" class="link__icon"/>
      {{ $t('menu.statistics') }}</localized-link>
    <localized-link class="link" to="/vip">
      <SvgIcon name="diamond" size="24" class="link__icon"/>
      {{ $t('menu.vip') }}</localized-link>
    <localized-link v-if="isAuthUser" class="link" to="/cabinet/shop">
      <SvgIcon name="shop" size="24" class="link__icon"/>
      {{ $t('menu.store') }}</localized-link>
    <localized-link class="link" to="/affiliate">
      <SvgIcon name="document_solid" size="24" class="link__icon"/>
      {{ $t('menu.affiliate') }}</localized-link>
  </div>
</template>

<style lang="scss" scoped>
.link {
  color: var(--white);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
  padding: 12px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 4px;
  transition: background-color .3s ease;
  cursor: pointer;

  @include media-lg() {
    margin-bottom: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    background-color: var(--dark3);
    color: var(--white);
    .link__icon {
      --color: var(--primary);
    }
  }
}

.link__icon {
  margin-right: 8px;
}
</style>
