<script>
export default {
  name: "OpenChat",
  methods: {
    openChart() {
      this.$emit('openChart')
    },
  }
}
</script>

<template>
  <button @click.prevent="openChart" class="open-chat-button">
    <SvgIcon name="chat" size="20" class="open-chat-button-icon" />
    <span>{{ $t('messages.open_chat') }}</span>
  </button>
</template>

<style lang="scss" scoped>
.open-chat-button {
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--gray);
  border: none;
  cursor: pointer;
  color: var(--gray5);
  margin-left: 6px;
  font-weight: 700;
  font-size: 14px;
  transition: background .3s ease;
  height: 36px;
  margin-bottom: 20px;
  width: 100%;

  &:hover {
    background: var(--gray2);
  }
}

.open-chat-button-icon {
  --color: var(--gray5);
  margin-right: 8px;
}
</style>
