<script>
export default {
  name: "OurGameCard",
  props: {
    count: { type: [Number, String], default: 0 },
    // dice | plinko | roulette | wheel | crash
    game: { type: String, required: true },
  },
  computed: {
    capitalizeGameName() {
      return this.game.toUpperCase();
    },
    getImage() {
      return new URL(`../../../../assets/home/games/${this.game}.svg`, import.meta.url).href
    },
  }
}
</script>

<template>
  <localized-link :to="`/${game}`" class="our-games__card" :class="game">
    <img
        :src="getImage"
        :alt="capitalizeGameName"
        class="our-games__image"
    />
    <div class="our-games__users">
      <SvgIcon name="user_solid" class="our-games__user-icon" size="20" />
      <div class="our-games__user-count">{{ count }}</div>
    </div>
    <h3 class="our-games__title">{{ capitalizeGameName }}</h3>
  </localized-link>
</template>

<style lang="scss" scoped>
$card-height-mobile: 213px;

.our-games__title {
  color: var(--white);
  font-family: var(--secondary-font);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;

  @include media-lg() {
    font-size: 20px;
  }
}

.our-games__card {
  border-radius: 8px;
  position: relative;
  width: 100%;
  max-width: 224px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 17.7% 12px 12px 12px;


  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 120px;
    height: 0;
    border-radius: 50%;
    transition: .3s ease;
    opacity: 0;
  }

  &:hover {
    &:after {
      bottom: -5px;
      opacity: 1;
      background-color: var(--bg);
      box-shadow: var(--shadow);
    }
  }

  @include media-lg() {
    width: 168px;
    height: $card-height-mobile;
    margin-right: 8px;
    padding-top: 12px;
    flex-shrink: 0;
    display: inline-flex;
  }

  &:last-of-type {
    margin-right: 0;
  }


  &.dice {
    background-color: #EE6D42;
    --bg: rgba(251, 116, 120, 0.6);
    --shadow: 0 0 10px 4px #fb7478;
  }
  &.plinko {
    background-color: #316BFF;
    --bg: rgba(45, 105, 225, 0.6);
    --shadow: 0 0 10px 4px #2d69e1;
  }
  &.roulette {
    background-color: #00973C;
    --bg: rgba(39, 218, 154, 0.6);
    --shadow: 0 0 10px 4px #27da9a;
  }
  &.wheel {
    background-color: #613BE8;
    --bg: rgba(255, 211, 5, 0.6);
    --shadow: 0 0 10px 4px #ffd305;
  }
  &.crash {
    background-color: #232691;
    --bg: rgba(70, 68, 172, 0.6);
    --shadow: 0 0 10px 4px #4644b6;
  }
}


.our-games__image {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;

  .dice & {
    top: 24px;
  }
  .plinko & {
    top: auto;
    bottom: 0;
  }
  .roulette & {
    left: 50%;
    transform: translateX(-50%);
  }
}

.our-games__users {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: rgba(29, 29, 36, 0.50);
  padding: 4px 8px;
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1;
}
.our-games__user-icon {
  --color: var(--gray5);
  margin-right: 8px;
}
.our-games__user-count {
  color: var(--gray5);
  font-size: 14px;
  font-weight: 500;
  line-height: 1em;
}
</style>
