<script>
import SearchGames from "@/components/navbars_redesign/search/SearchGames.vue"

const POPULAR_SEARCHES_CATEGORY = 'popular_searches'
const TOP_GAMES_CATEGORY = 'popular'
const FIRST_PAGE = 1
const DEFAULT_SORT_TYPE = 'popular'
const MAX_LENGTH_ARRAY = 4

export default {
  name: "SearchGamesWrapper",
  components: {SearchGames},
  props: {
    hideOnOutsideClick: { type: Boolean, default: false},
  },
  data() {
    return {
      loaderMain: true,
      loaderPage: false,
      searchTop: [],
      openSearchResult: false,
      sortLoader: false,
      searchGames: [],
      searchPages: 0,
      searchPerPage: 0,
      searchCurrentPage: 1,

      isLoadingPopularSearch: false,
      popularSearchList: [],
      popularSearchError: '',
      isLoadingTopGame: false,
      topGameList: [],
      topGameError: '',
    }
  },
  computed: {
    sortType: {
      get: function() {
        return this.$store.getters.getSorting
      }
    },
  },
  created() {
    this.loadMainList()
    this.loadPopularSearchList()
    this.loadTopGameList()
  },
  methods: {
    loadMainList: function() {
      this.loaderMain = true

      this.axios.get('/casino/list/main?' + new Date().getTime())
          .then(response => {
            if (response.data.status === 'success') {
              this.searchTop = response.data.data.search
              this.loaderMain = false
            } else {
              this.$store.dispatch('addNotif', {type: 'danger', text: response.data.message, isClose: false, timer: 2000}).then()
            }
          })
          .catch(() => {
            this.loaderMain = false
            this.$store.dispatch('addNotif', {type: 'danger', text: 'Server error', isClose: false, timer: 2000}).then()
          })
    },
    loadPopularSearchList() {
      const page = FIRST_PAGE
      const sortType = DEFAULT_SORT_TYPE
      const searchText = POPULAR_SEARCHES_CATEGORY
      this.isLoadingPopularSearch = true
      this.popularSearchError = ''

      this.axios.get( `/casino/search/${searchText}/${sortType}/${page}`)
          .then(response => {
            const status = response.data.status

            if (status === "success") {
              const list = response.data.data.transactions || []
              this.popularSearchList = this.getSlicedArray(list)
            } else if (status === 'error' && response.data.message === '') {
              if (response.data.data.text !== undefined) {
                this.popularSearchError = response.data.data.text
              }
            } else {
              this.showErrorNotification(response.data.message)
            }
          })
          .finally(() => {
            this.isLoadingPopularSearch = false
          })
    },
    loadTopGameList() {
      const page = FIRST_PAGE
      const sortType = DEFAULT_SORT_TYPE
      const searchText = TOP_GAMES_CATEGORY
      this.isLoadingTopGame = true
      this.topGameError = ''

      this.axios.get( `/casino/search/${searchText}/${sortType}/${page}`)
          .then(response => {
            const status = response.data.status

            if (status === "success") {
              const list = response.data.data.transactions || []
              this.topGameList = this.getSlicedArray(list)
            } else if (status === 'error' && response.data.message === '') {
              if (response.data.data.text !== undefined) {
                this.topGameError = response.data.data.text
              }
            } else {
              this.showErrorNotification(response.data.message)
            }
          })
          .finally(() => {
            this.isLoadingTopGame = false
          })
    },
    getSlicedArray(array = []) {
      return array.slice(0, MAX_LENGTH_ARRAY)
    },
    showErrorNotification: function(text = '') {
      this.$store.dispatch('addNotif', {type: 'danger', text, isClose: false, timer: 2000}).then()
    },
    changeSort: function(type) {
      this.searchCurrentPage = 1
      this.sortLoader = true
    },
    searchClose: function() {
      this.openSearchResult = false
    },
    searchResult: function(data, pages, perPage) {
      if (data.length > 0) {
        this.searchGames = data
        this.searchPages = pages
        this.searchPerPage = perPage

        this.openSearchResult = true
        this.sortLoader = false
      } else {
        this.openSearchResult = false
      }
    },
    searchChange: function() {
      this.searchCurrentPage = 1
    },
    searchResultLoad: function(data) {
      for (let i=0; i<data.length; i++) {
        this.searchGames.push(data[i])
      }
      this.loaderPage = false
    },
    openNextSearchPage: function() {
      this.loaderPage = true
      this.searchCurrentPage++
    },
    onChangeIsOpenSearch(value) {
      this.$emit('onChangeIsOpenSearch', value)
    },
  }
}
</script>



<template>
  <div class="casino-block" id="casinoMainBlock">
    <div class="casino-block-search">
      <SearchGames
          :search-top="searchTop"
          :sort-type="sortType"
          :page="searchCurrentPage"
          :isLoadingPopularSearch="isLoadingPopularSearch"
          :popularSearchList="popularSearchList"
          :popularSearchError="popularSearchError"
          :isLoadingTopGame="isLoadingTopGame"
          :topGameList="topGameList"
          :topGameError="topGameError"
          :loaderPage="loaderPage"
          :sortLoader="sortLoader"
          :searchGames="searchGames"
          :searchPages="searchPages"
          :searchPerPage="searchPerPage"
          :searchCurrentPage="searchCurrentPage"
          :openSearchResult="openSearchResult"
          :hideOnOutsideClick="hideOnOutsideClick"
          @close="searchClose"
          @result="searchResult"
          @result-load="searchResultLoad"
          @change-search="searchChange"
          @changeSort="changeSort"
          @openNextSearchPage="openNextSearchPage"
          @onChangeIsOpenSearch="onChangeIsOpenSearch"
      />
    </div>
  </div>
</template>


<style lang="scss" scoped>
</style>
