<script>
import Avatar from "@/components/user_redesign/Avatar.vue";
import SvgIcon from "@/components/base/SvgIcon.vue";
import Vip from "@/components/vip/vip";

const MAX_RATING = 5

export default {
  name: "UserData",
  components: {SvgIcon, Avatar},
  mixins: [Vip],
  props: {
    avatarSize: { type: Number, default: 48 },
  },
  data() {
    return {
      MAX_RATING,
    }
  },
  computed: {
    userXP: function() {
      return this.$store.getters.getAuthData.xp
    },
    userRating() {
      return this.$store.getters.getRating
    },
  },
}
</script>

<template>
  <a class="user" href="#" @click.prevent="$emit('onClick')">
    <div class="user__avatar">
      <Avatar :avatar="$store.getters.getAuthData.avatar"
              :xp="$store.getters.getAuthData.xp"
              :size="avatarSize"
              :decoration="$store.getters.getShopDecor"
              :gift="$store.getters.getShopGift"
              progress
      />
    </div>

    <div class="user__data">
      <slot name="firstData" />
      <div class="user__name">{{ $store.getters.getAuthData.username }}</div>
      <div class="user__stars">
        <SvgIcon v-for="n in MAX_RATING"
                 :key="n"
                 name="star"
                 size="10"
                 class="user__star"
                 :class="userRating < n ? '' : 'user__star--active'"
        />
      </div>

      <div class="user__exp">
            <span class="user__exp-current">
              {{parseInt(userXP)}}</span> / <span class="user__exp-max">{{getLevelNextXp(userXP)}}
          </span> EXP
      </div>
    </div>

    <font-awesome-icon icon="chevron-down"  class="user__arrow" />
  </a>
</template>

<style lang="scss" scoped>
.user__avatar {
  margin-right: 8px;
  position: relative;
}

.user__progress-bar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 51px;
  width: 51px;
}

.user {
  text-align: left;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: end;
}

.user__data {
  margin-right: 13px;
  @include media-lg() {
    display: none;
  }
}

.user__arrow {
  color: var(--gray4);
  font-size: 10px;
  @include media-lg() {
    display: none;
  }
}

.user__name {
  color: var(--white);
  font-size: 14px;
  font-weight: 700;
  line-height: 1em;
  margin-bottom: -4px;
}

.user__stars {
  display: flex;
  align-items: center;
  margin-top: 6px;
  margin-bottom: 6px;
}
.user__star {
  --color: var(--gray4);
  margin-right: 2px;
}
.user__star--active {
  --color: var(--gold);
}

.user__exp {
  color: var(--gray4);
  font-size: 10px;
  font-weight: 600;
  line-height: normal;
  text-align: left;
}
</style>
