<script>
import NavSideBar from "@/components/navbars_redesign/nav-side-bar/NavSideBar.vue";

export default {
  name: "NavSideBarWrap",
  props: {
    open: {  type: Boolean, default: false }
  },
  components: {NavSideBar},
}
</script>

<template>
  <!-- MOBILE VERSION -->
  <transition name="left-menu-animation">
    <div v-if="open" class="left-menu-box left-menu-box--mobile" :class="{ open }" >
      <NavSideBar @closeSideBar="$emit('closeSideBar')"  />
    </div>
  </transition>

  <!-- DESKTOP VERSION -->
  <div class="left-menu-box left-menu-box--desktop">
    <NavSideBar @closeSideBar="$emit('closeSideBar')" />
  </div>
</template>

<style lang="scss" scoped>
.left-menu-animation-enter-active,
.left-menu-animation-leave-active  {
  transition: all .3s ease-in-out;
}

.left-menu-animation-enter,
.left-menu-animation-leave-to {
  transform: translateX(-200px);
  opacity: 0;
}

.left-menu-box {
  background-color: var(--dark4);
  width: 224px;
  flex-shrink: 0;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  max-height: 100vh;
  overflow: auto;
  @include media-lg() {
    display: none;
    &.open {
      display: block;
      width: 375px;
      max-width: 100%;
      z-index: 4;
    }
  }
}
.left-menu-box--mobile {
  display: none;
  @include media-lg() {
    display: block;
  }
}
.left-menu-box--desktop {
  display: block;

  &:deep {
    .side-bar__content {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  @include media-lg() {
    display: none;
  }
}
</style>
