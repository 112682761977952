<script>
import LangSwitcher from "@/components/navbars_redesign/lang-switcher/LangSwitcher.vue";
import NavProfileLinks from "@/components/navbars_redesign/personal-bonus/NavPersonalBonus.vue";
import SearchGamesWrapper from "@/components/navbars_redesign/search/SearchGamesWrapper.vue";
import GameList from '@/components/navbars_redesign/nav-side-bar/GameList.vue';
import NavLinks from '@/components/navbars_redesign/nav-side-bar/NavLinks.vue';
import SocialLinks from '@/components/navbars_redesign/nav-side-bar/SocialLinks.vue';
import Support from '@/components/navbars_redesign/nav-side-bar/Support.vue';
import SideBarHeader from '@/components/navbars_redesign/nav-side-bar/SideBarHeader.vue';
import OpenChat from '@/components/navbars_redesign/nav-side-bar/OpenChat.vue';

export default {
  name: "NavSideBar",
  components: {
    LangSwitcher,
    NavProfileLinks,
    SearchGamesWrapper,
    GameList,
    NavLinks,
    SocialLinks,
    Support,
    SideBarHeader,
    OpenChat,
  },
  data() {
    return {
      showGameList: false,
      isOpenSearch: false,
    }
  },
  methods: {
    toggleGame() {
      this.showGameList = !this.showGameList
    },
    closeSideBar() {
      this.changeIsOpenSearch()
      this.$emit('closeSideBar')
    },
    openChart() {
      this.closeSideBar()
      this.$store.commit('chatToggle')
    },
    changeIsOpenSearch(value = false) {
      this.isOpenSearch = value
    }
  }
}
</script>

<template>
<div class="side-bar">
  <SideBarHeader class="side-bar__mobile-header" @closeSideBar="closeSideBar" />

  <SearchGamesWrapper
      class="side-bar__search"
      @onChangeIsOpenSearch="changeIsOpenSearch"
  />

  <div v-if="!isOpenSearch" class="side-bar__content">
    <OpenChat class="side-bar__chat-btn" @openChart="openChart" />

    <div class="side-bar__navigation">
      <NavProfileLinks class="side-bar__personal-bonuses" />
      <GameList class="side-bar__games" />
      <NavLinks class="side-bar__links" />
    </div>

    <div class="side-bar__footer">
      <div class="lang-switcher"><LangSwitcher /></div>
      <Support  class="side-bar__support"/>
      <SocialLinks class="side-bar__social" />
    </div>
  </div>

</div>
</template>

<style lang="scss" scoped>
.side-bar {
  padding: 20px 12px 20px 12px;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @include media-lg() {
    padding-top: 15px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
.side-bar__games {
  margin-bottom: 12px;
}
.side-bar__support {
}
.support__icon {
  margin-right: 8px;
}

.side-bar__mobile-header {
  margin-bottom: 38px;
  margin-left: 24px;
  @include media-lg() {
    margin-left: 0;
    margin-bottom: 17px;
  }
}

.side-bar__chat-btn {
  display: none;

  @include media-lg() {
    display: flex;
    margin-left: 0;
    margin-top: 6px;
  }
}

.side-bar__personal-bonuses {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.side-bar__links {
  margin-top: 16px;

  @include media-lg() {
    margin-top: 13px;
  }
}
.link {
  color: var(--white);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
  padding: 12px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 4px;
  transition: background-color .3s ease;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    background-color: var(--dark3);
    color: var(--white);
  }
}

.side-bar__footer {
  margin-top: auto;
}

.lang-switcher {
  margin-left: 12px;
  margin-top: 40px;
  max-width: 100px;

  @include media-lg() {
    margin-left: 0;
    max-width: 90px;
    margin-top: 26px;
  }
}

.side-bar__social {
  margin-top: 16px;
}

.side-bar__search {
  display: none;

  @include media-lg() {
    display: flex;
  }

  &:deep {
    .search__results {
      position: static;
      background-color: transparent;
      max-width: 100%;

      @include media-lg() {
        margin-top: 14px;
      }
    }
    .casino-search-top {
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .casino-search-right {
      margin-left: auto;
    }
    .search-result__scroll {
      max-height: none;
      overflow: visible;
      padding: 0;
    }
    .casino-block-search {
      width: 100%;
    }
    .casino-search-block .casino-search-data {
      padding: 0;
    }
  }
}
</style>
