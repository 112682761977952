<template>
  <div>

    <div class="main-game-block">

      <Auth v-if="$store.getters.authStatus.length === 0 && !$store.getters.isModalAuth" :isModal="false"></Auth>

      <CountryBlock v-if="$store.getters.isCountryBlock"></CountryBlock>

      <div class="columns game-block">
        <div class="game-block__inner column is-paddingless">

          <router-view />

          <GameRandomList
              v-if="gameSettings.games_random === true"
              :currency-select="getSelectCurrReal"
              :currency-play="getSelectCurrReal"
          />

        </div>
      </div>
    </div>

    <Betslog />
    <TextLayout />

  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue'
  import Auth from '@/components/modals/Auth.vue'
  import TextLayout from "@/components/texts/TextLayout.vue"
  import GameRandomList from "@/components/casino/GameRandomList.vue"
  import {mapGetters} from "vuex"
  const CountryBlock = defineAsyncComponent(() => import('@/components/modals/CountryBlock.vue'))
  const Betslog = defineAsyncComponent(() => import('@/components/betslog_redisign/Betslog.vue'))
  export default {
    name: "Layout",
    components: {GameRandomList, TextLayout, CountryBlock, Betslog, Auth},
    computed: {
      ...mapGetters(['getSelectCurrReal', 'gameSettings'])
    }
  }
</script>
<style src="../../assets/styles/style_game.scss" lang="scss"></style>
<style lang="scss" scoped>

  .main-game-block{
    position: relative;
  }

  .game-block {
    padding: 10px 0 10px 0;
    margin: 0 !important;
  }

  .game-block__inner {
    margin-left: auto;
    margin-right: auto;

    // fix swiper error
    // error: after init no slider width is set and slider is displayed outside the screen
    min-width: 0;
  }
  .game-block .column {
    padding: 0;
  }

  @media (max-width: 767px) {
    .game-block {
      padding: 15px 0 30px 0;
    }
  }
</style>
