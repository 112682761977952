import { library } from '@fortawesome/fontawesome-svg-core'

import { faStar } from '@fortawesome/free-solid-svg-icons/faStar'
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons/faStar'
import { faDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign'
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle'
import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart'
import { faPiggyBank } from '@fortawesome/free-solid-svg-icons/faPiggyBank'
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons/faAngleUp'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers'
import { faPercent } from '@fortawesome/free-solid-svg-icons/faPercent'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook'
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons/faLessThanEqual'
import { faLessThan } from '@fortawesome/free-solid-svg-icons/faLessThan'
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog'
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons/faAngleDoubleLeft'
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons/faAngleDoubleRight'
import { faFileCode } from '@fortawesome/free-solid-svg-icons/faFileCode'
import { faTrophy } from '@fortawesome/free-solid-svg-icons/faTrophy'
import { faUniversity } from '@fortawesome/free-solid-svg-icons/faUniversity'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt'
import { faUserShield } from '@fortawesome/free-solid-svg-icons/faUserShield'
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons/faGlobeAmericas'
import { faCoins } from '@fortawesome/free-solid-svg-icons/faCoins'
import { faQrcode } from '@fortawesome/free-solid-svg-icons/faQrcode'
import { faHashtag } from '@fortawesome/free-solid-svg-icons/faHashtag'
import { faDice } from '@fortawesome/free-solid-svg-icons/faDice'
import { faComment as faCommentRegular } from '@fortawesome/free-regular-svg-icons/faComment'
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey'
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock'
import { faLockOpen } from '@fortawesome/free-solid-svg-icons/faLockOpen'
import { faTag } from '@fortawesome/free-solid-svg-icons/faTag'
import { faMoneyBillAlt as faMoneyBillAltRegular } from '@fortawesome/free-regular-svg-icons/faMoneyBillAlt'
import { faHistory } from '@fortawesome/free-solid-svg-icons/faHistory'
import { faThumbsUp as faThumbsUpRegular } from '@fortawesome/free-regular-svg-icons/faThumbsUp'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faEnvelope as faEnvelopeRegular } from '@fortawesome/free-regular-svg-icons/faEnvelope'
import { faComments } from '@fortawesome/free-solid-svg-icons/faComments'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle'
import { faGamepad } from '@fortawesome/free-solid-svg-icons/faGamepad'
import { faLifeRing as faLifeRingRegular } from '@fortawesome/free-regular-svg-icons/faLifeRing'
import { faFlask } from '@fortawesome/free-solid-svg-icons/faFlask'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle'
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay'
import { faSmile } from '@fortawesome/free-solid-svg-icons/faSmile'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle'
import { faUmbrella } from '@fortawesome/free-solid-svg-icons/faUmbrella'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight'
import { faHeadset } from '@fortawesome/free-solid-svg-icons/faHeadset'
import { faFrown as faFrownRegular } from '@fortawesome/free-regular-svg-icons/faFrown'
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faWalking } from '@fortawesome/free-solid-svg-icons/faWalking'
import { faCar } from '@fortawesome/free-solid-svg-icons/faCar'
import { faFighterJet } from '@fortawesome/free-solid-svg-icons/faFighterJet'
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons/faRedoAlt'
import { faKeyboard as faKeyboardRegular } from '@fortawesome/free-regular-svg-icons/faKeyboard'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV'
import { faUserCog } from '@fortawesome/free-solid-svg-icons/faUserCog'
import { faSave as faSaveRegular } from '@fortawesome/free-regular-svg-icons/faSave'
import { faFolderOpen as faFolderOpenRegular } from '@fortawesome/free-regular-svg-icons/faFolderOpen'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt'
import { faAward } from '@fortawesome/free-solid-svg-icons/faAward'
import { faCrown } from '@fortawesome/free-solid-svg-icons/faCrown'
import { faTags } from '@fortawesome/free-solid-svg-icons/faTags'
import { faUserTag } from '@fortawesome/free-solid-svg-icons/faUserTag'
import { faPaintRoller } from '@fortawesome/free-solid-svg-icons/faPaintRoller'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle'
import { faUserEdit } from '@fortawesome/free-solid-svg-icons/faUserEdit'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp'
import { faTicket } from '@fortawesome/free-solid-svg-icons/faTicket'
import { faBolt } from '@fortawesome/free-solid-svg-icons/faBolt'


import { faLevelUpAlt } from '@fortawesome/free-solid-svg-icons/faLevelUpAlt'
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars'
import { faCashRegister } from '@fortawesome/free-solid-svg-icons/faCashRegister'
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons/faExchangeAlt'
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons/faChevronCircleDown'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt'
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons/faSyncAlt'
import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faCode } from '@fortawesome/free-solid-svg-icons/faCode'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus'
import { faUserTimes } from '@fortawesome/free-solid-svg-icons/faUserTimes'
import { faUserSlash } from '@fortawesome/free-solid-svg-icons/faUserSlash'
import { faGift } from '@fortawesome/free-solid-svg-icons/faGift'
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit'
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt'
import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons/faHandHoldingUsd'
import { faFire } from '@fortawesome/free-solid-svg-icons/faFire'
import { faUndoAlt } from '@fortawesome/free-solid-svg-icons/faUndoAlt'
import { faArchive } from '@fortawesome/free-solid-svg-icons/faArchive'
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons/faExpandArrowsAlt'


import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle'
import { faDonate } from '@fortawesome/free-solid-svg-icons/faDonate'
import { faCocktail } from '@fortawesome/free-solid-svg-icons/faCocktail'

import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons/faTelegramPlane'
import { faVk } from '@fortawesome/free-brands-svg-icons/faVk'
import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub'

library.add(faStar, faDollarSign, faCopy, faTimes, faSpinner, faFileCode, faTrophy, faAngleUp, faAngleDown) // many uses
library.add(faPercent, faBook, faCoins, faAngleDoubleLeft, faAngleDoubleRight, faSmile, faPlay, faQuestionCircle)
library.add(faLessThanEqual, faLessThan, faUserCircle, faComments, faMoneyBillAltRegular, faFlask)
library.add(faStarRegular, faEnvelopeRegular, faLifeRingRegular, faCommentRegular, faCircleNotch, faBars, faCaretDown)
library.add(faSyncAlt, faChartLine, faSearch, faCode, faGift, faEdit, faSaveRegular, faFolderOpenRegular, faTrashAlt)
library.add(faLink, faSignInAlt, faSignOutAlt, faHandHoldingUsd, faAward, faCrown, faTags, faUserTag, faPaintRoller)
library.add(faCheckCircle, faExclamationCircle, faUserEdit, faChevronDown, faChevronUp, faTicket, faBolt, faFire)
library.add(faPlusCircle, faDonate, faCocktail, faHeadset, faUndoAlt, faArchive, faExpandArrowsAlt)

library.add(faCircle, faHeart, faTwitter, faTelegramPlane, faVk, faGithub) //footer
library.add(faPiggyBank) // users/finances
library.add(faUsers) // users/affiliate

library.add(faCog, faDownload, faCheck) // user/settings
library.add(faUniversity, faExternalLinkAlt) // pages/fair
library.add(faUserShield) // pages/privacy
library.add(faGlobeAmericas) // modals/country_block
library.add(faQrcode) // modals/deposit
library.add(faHashtag, faDice, faKey, faLock, faLockOpen, faTag, faLevelUpAlt) // modals/info
library.add(faHistory) // modals/payout
library.add(faThumbsUpRegular) // modals/tips
library.add(faCashRegister, faExchangeAlt) // modals/exchange
library.add(faUser, faTimesCircle, faGamepad) // modals/user
library.add(faExclamationTriangle) // faucet
library.add(faUmbrella, faAngleRight, faUserCog) // chat
library.add(faFrownRegular, faUserPlus, faUserTimes, faUserSlash) // messages
library.add(faEnvelope) //navbar
library.add(faChevronCircleDown, faCalendarAlt, faClock) //tourney
library.add(faMinus, faPlus, faWalking, faCar, faFighterJet, faRedoAlt, faKeyboardRegular, faArrowUp, faArrowDown, faArrowLeft, faArrowRight, faEllipsisV)
