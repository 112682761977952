<script>
import PersonalBonus from "./PersonalBonus.vue";
import vip from "@/components/vip/vip";

const RakebackNeedLevel = 21
const CashbackNeedLevel = 31

export default {
  name: "NavProfileLinks",
  components: { PersonalBonus },
  mixins: [vip],
  data() {
    return {
      RakebackNeedLevel,
      CashbackNeedLevel,
    }
  },
  computed: {
    isFaucetLoader: function () {
      return this.$store.getters.getFaucetIsLoader
    },
    getFaucetLoaderWidth: function () {
      return this.$store.getters.getFaucetLoaderWidth
    },
    getFaucetLoaderTime: function () {
      return this.$store.getters.getFaucetLoaderTime
    },
    level() {
      return this.getLevel(this.$store.getters.getUserXP)
    },
    isEnableRakeback() {
      return this.level >= RakebackNeedLevel
    },
    isEnableCashback() {
      return this.level >= CashbackNeedLevel
    }
  },
  methods: {
    faucetToggle() {
      if (this.isFaucetLoader === true) return
      this.$store.commit('faucetToggle')
    },
    openModalCoupon() {
      this.$store.commit('openModalCoupon')
    },
    moveToCashback() {
      if (this.isEnableCashback) {
        this.$router.push({ name: 'VipCashback' })
      } else {
        this.$router.push({ name: 'VIP' })
      }
    },
    moveToRakeback() {
      if (this.isEnableRakeback) {
        this.$router.push({ name: 'VipRakeback' })
      } else {
        this.$router.push({ name: 'VIP' })
      }
    },
  }
}
</script>

<template>
  <div>
    <nav>
      <ul class="links__images">
        <li class="links__item">
          <PersonalBonus name="everyday_bonus" disabled />
        </li>
        <li class="links__item">
          <PersonalBonus name="faucet"
                       @onclick="faucetToggle"
                       :disabled="isFaucetLoader"
                       :showTimer="isFaucetLoader"
                       :timer="getFaucetLoaderTime"
                       :timerPercent="getFaucetLoaderWidth"
          />
        </li>
        <li class="links__item">
          <PersonalBonus name="bonus_wheel" disabled/>
        </li>
        <li class="links__item">
          <PersonalBonus name="coupon"
                         @onclick="openModalCoupon"
          />
        </li>
        <li class="links__item">
          <PersonalBonus name="cashback"
                         :isShowLevelDisabled="!isEnableCashback"
                         :level="CashbackNeedLevel"
                         @onclick="moveToCashback"
          />
        </li>
        <li class="links__item">
          <PersonalBonus name="rakeback"
                         :isShowLevelDisabled="!isEnableRakeback"
                         :level="RakebackNeedLevel"
                         @onclick="moveToRakeback"
          />
        </li>
      </ul>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
.links__images {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 12px;
}
.links__item {
  margin-right: 8px;
  margin-bottom: 8px;
  width: calc(50% - 8px);
  &:nth-of-type(2n) {
    margin-right: 0;
  }
}
</style>
