<script lang="ts">
import SvgIcon from "@/components/base/SvgIcon.vue";
import { onClickOutside } from '@vueuse/core'
import {PropType} from "vue";

type TLanguage = {
  langIndex: string,
  langName: string,
  url: string,
  active: boolean
}

export default {
  name: "Switcher",
  components: {SvgIcon},
  props: {
    currentLang: { type: [Object, null] as PropType<TLanguage | null>, default: null },
    links: { type: Array as PropType<Array<TLanguage>>, required: true },
    small: { type: Boolean, default: false},
    bottom: { type: Boolean, default: false},
  },
  data () {
    return {
      show: false,
    }
  },
  mounted() {
    const switcher = this.$refs.switcher
    onClickOutside(switcher, () => this.closeModal())
  },
  methods: {
    getFlagImage: function(name) {
      return new URL(`/src/assets/flag_${name}_round.svg`, import.meta.url).href
    },
    setLanguage: function (lang, url) {
      this.closeModal()
      this.$emit('setLanguage', lang, url)
    },
    closeModal () {
      this.show = false
    }
  }
}
</script>

<template>
  <div class="language-switcher" ref="switcher">

    <div class="language-current" :class="{active: show}" @click="show = !show">
      <div class="language-current__inner language-image">
        <img :src="getFlagImage(currentLang.langIndex)" :alt="currentLang.langName" height="20" width="20" />
      </div>
      <div v-if="small" class="language-current__arrow-wp">
        <SvgIcon name="arrow_down" size="18" class="language-current__arrow" :class="{active: show}"/>
      </div>
      <div v-else class="language-current__inner language-current__text language-text">
        {{ currentLang.langName}}
      </div>
    </div>

    <transition name="language-fade">
      <div v-show="show" class="language-drop" :class="{  bottom }">
        <div v-for="link in links" :key="link.langIndex">
          <a @click.prevent="setLanguage(link.langIndex, link.url)" :class="{ active: link.active }" :href="link.url">
            <div class="language-image">
              <img :src="getFlagImage(link.langIndex)" :alt="link.langName" height="20" width="20" />
            </div>
            <div class="language-drop__text language-text">{{ link.langName}}</div>
          </a>
        </div>
        <div class="language-drop__decorate-wp" :class="{ bottom }">
          <SvgIcon name="switch_lang_decorate" height="8" width="100" class="language-drop__decorate" :class="{ bottom }"/>
        </div>
      </div>
    </transition>
  </div>
</template>


<style lang="scss" scoped>
$width: 100px;
$border-radius: 8px;
$background-color: var(--dark6);
$background-color-hover: var(--dark3);
$decorate-color: var(--dark6);
$link-color: var(--gray5);

.language-switcher {
  position: relative;
  display: flex;
  align-items: center;

  .language-fade-enter-active,
  .language-fade-leave-active {
    transition: all .5s ease;
  }

  .language-fade-enter-from,
  .language-fade-leave-to {
    transform: translateY(40px);
    opacity: 0;
  }

  .language-image {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    > img {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
  .language-text {
    text-transform: uppercase;
  }


  .language-current {
    display: flex;
    padding: 4px 4px;
    background-color: transparent;
    cursor: pointer;
    z-index: 1;

    &__inner {
      display: flex;
      align-items: center;
    }
    &__text {
      font-size: 14px;
      font-weight: 500;
      margin-left: 10px;
    }
    &__arrow-wp {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 8px;
    }
    &__arrow {
      transition: .3s ease;
      &.active {
        transform: rotate(180deg);
      }
    }
  }

  .language-drop {
    position: absolute;
    z-index: 2;
    left: calc(50% - #{$width / 2});
    right: 0;
    bottom: calc(100% + #{$border-radius});
    background-color: $background-color;
    border-radius: $border-radius;
    width: $width;
    padding-top: 8px;
    padding-bottom: 8px;

    &.bottom {
      bottom: auto;
      top: calc(100% + #{$border-radius});
    }

    &__text {
      font-weight: 700;
      margin-left: 10px;
    }

    &__decorate-wp {
      position: absolute;
      bottom: -14px;
      left: 0;
      &.bottom {
        bottom: auto;
        top: -17px;
      }
    }
    &__decorate {
      --color: #{$decorate-color};
      &.bottom {
        transform: rotate(180deg);
      }
    }

    > div {
      > a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: $link-color;
        padding: 6px 12px;
        &:hover {
          background-color: $background-color-hover;
        }
      }
      &:last-of-type {
        > a {
          border-bottom: 0;
        }
      }
    }
  }

  @include media-lg() {
    justify-content: center;
  }
}
</style>

