
<script>
export default {
  name: 'WinStats',
  data () {
    return {
      lastBigWinDefault: {hash: '', username: '', amount: '', curr: 'btc'},
      intervals: 0,
      loaded: false,
      timerBets: null,
      timerUpdate: 0,
    }
  },
  computed: {
    online: function () {
      return this.$store.getters.getOnline
    },
    onlineDetail: function () {
      return this.$store.getters.getOnlineDetail
    },
    bets: function () {
      return this.$store.getters.getBets
    },
    lastWon: function () {
      return this.$store.getters.getLastWon
    },
    lastWonData: function () {
      return this.$store.getters.getLastWonData
    },
    jackpot: function () {
      return this.$store.getters.getJackpot
    },
    jackpotData: function () {
      return this.$store.getters.getJackpotData
    },
    selfHash: function() {
      return this.$store.getters.getUserHash
    }
  },
  watch: {
    bets: function(newVal, oldVal) {
      if(!this.loaded) return
      this.printCounts(newVal, oldVal, 'betsCount')
    }
  },
  mounted() {
    document.getElementById('betsCount').innerText = this.printNumber(this.bets)
    this.loaded = true
  },
  methods: {
    printNumber: function(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    openModalBet: function (hash) {
      this.$store.commit('openModalBet', hash)
    },
    printCounts: function(newVal, oldVal, id) {
      clearInterval(this.timerBets)
      let elem = document.getElementById(id)

      if (newVal > oldVal + 1000) {
        elem.innerText = this.printNumber(newVal)
        this.timerUpdate = 0
        return
      }

      let timerInterval = this.timerUpdate === 0? 1000 : new Date().getTime() - this.timerUpdate
      this.timerUpdate = new Date().getTime()

      const count = newVal - oldVal - 1
      const timer = Math.floor(timerInterval / count)

      let checker = 0
      elem.innerText = this.printNumber(oldVal + 1)

      if (count > 0) {
        this.timerBets = setInterval(() => {
          if (checker === count) {
            clearInterval(this.timerBets)
            return
          }

          checker++
          elem.innerText = this.printNumber(oldVal + checker + 1)
        }, timer)
      }
    }
  }
}
</script>

<template>
  <div class="stats navbar">

    <!-- ONLINE -->
    <div class="stats__block stats__open-block">
      <SvgIcon name="user_solid" size="20" class="icon" />
      <span>{{ $t('stats_top.online') }} <span class="stats__value">{{ online }}</span></span>
      <div class="stats__dropdown-menu hide-md-down">
        <ul class="stats__list">
          <li class="stats__item stats__online-item">
            <span>{{ $t('stats_top.online_site') }}</span>
            <span>{{onlineDetail.site}}</span>
          </li>
          <li class="stats__item stats__online-item">
            <span>{{ $t('stats_top.online_api') }}</span>
            <span>{{onlineDetail.api}}</span>
          </li>
          <li class="stats__item stats__online-item">
            <span>{{ $t('stats_top.online_telegram') }}</span>
            <span>{{onlineDetail.telegram}}</span>
          </li>
        </ul>
      </div>
    </div>

    <!-- BETS -->
    <div class="stats__block">
      <SvgIcon name="receipt" size="20" class="icon" />
      {{ $t('stats_top.bets') }}
      <span class="stats__value" id="betsCount">-</span>
    </div>

    <!-- WIN  24h -->
    <div class="stats__block stats__open-block">
      <SvgIcon name="wallet" size="20" class="icon" />
      <span>
        {{ $t('stats_top.won') }}
        <span class="stats__value">
          $ {{ printNumber(lastWon) }}
        </span>
      </span>
      <div class="stats__dropdown-menu hide-md-down">
        <ul>
          <li v-for="(item, key) in lastWonData" v-bind:key="key" class="stats__item">
            <cryptoicon :symbol="item.curr" size="20" class="stats__cryptoicon" />
            {{ parseFloat((item.amount / 1000).toFixed(8)) }}
          </li>
        </ul>
      </div>
    </div>

    <!-- JACKPOT -->
    <div class="stats__block stats__open-block">
      <SvgIcon name="safe_solid" size="20" class="icon" />
      <span>
        {{ $t('stats_top.jackpots') }}
        <span class="stats__value">
          $ {{ printNumber(jackpot) }}
        </span>
      </span>
      <div class="stats__dropdown-menu hide-md-down">
        <ul>
          <li v-for="(item, key) in jackpotData" v-bind:key="key" class="stats__item">
            <cryptoicon :symbol="item.curr" size="20" class="stats__cryptoicon" />
            {{ parseFloat((item.amount / 1000).toFixed(8)) }}
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<style lang="scss" scoped>
  .stats{
    color: var(--gray5);
    font-size: 12px;
    font-weight: 500;
    line-height: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .stats__block{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: 20px;

    &:first-of-type {
      margin-left: 0;
    }
  }
  .stats__value{
    font-family: var(--secondary-font);
    color: var(--white);
    font-size: 14px;
    font-weight: 600;
    margin-left: 12px;
  }
  .stats__dropdown-menu{
    display: none;
    position: absolute;
    z-index: 41;
    padding: 5px 10px;
    text-align: left;
    top: 20px;
    border-radius: 8px;
    background-color: var(--dark6);
    cursor: default;
  }
  .stats__open-block {
    cursor: pointer;

    &:hover {
      .stats__dropdown-menu{
        display: block;
      }
    }
  }
  .stats__item {
    padding: 4px 0;
    display: flex;
    display: flex;
    align-items: center;
  }
  .stats__online-item {
    justify-content: space-between;

    span {
      &:last-of-type {
        color: var(--white);
        font-weight: bold;
        margin-left: 10px;
      }
    }
  }
  .stats__cryptoicon {
    margin-right: 5px;
  }
  .icon {
    --color: var(--primary);
    margin-right: 6px;
  }
  .hide-md-down {
    @include media-md() {
      display: none;
    }
  }
</style>
