<script>
import BaseButton from '@/components/base/BaseButton.vue';

export default {
  name: "GetDeposit",
  components: {
    BaseButton,
  },
  computed: {
    depositAnimation() {
      return this.$store.getters.getDepositAnimate
    },
    getCurrency: function () {
      return this.$store.getters.getBalanceReal
    },
  },
  methods: {
    openDeposit() {
      this.$store.commit('openModalDeposit')
    },
  }
}
</script>

<template>
  <section class="get-deposit">
    <picture class="get-deposit__picture">
      <source
          srcset="@/assets/home/get-deposit/background.svg"
          media="(min-width: 1088px)"
      >
      <img
          src="@/assets/home/get-deposit/background.svg"
          alt="Deposit"
          class="get-deposit__image"
      >
    </picture>

    <div class="get-deposit__content">
      <header class="get-deposit__header">
        <h2 class="get-deposit__title">
          <span class="get-deposit__text">Get 5 BTC</span>
          <span class="get-deposit__text">or $ 500.00</span>
        </h2>
      </header>

      <BaseButton
          variant="yellow"
          class="get-deposit__button"
          @click="openDeposit"
          :class="{'pulse-deposit': depositAnimation}"
      >
        {{ $t('menu.deposit') }}
      </BaseButton>

      <div class="get-deposit__currencies">
        <cryptoicon
            v-for="(item, key) in getCurrency"
            :key="key"
            :symbol="key"
            size="24"
            class="get-deposit__currency"
        />
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.get-deposit {
  border-radius: 8px;
  background: linear-gradient(90deg, #192A36 50%, #245C59 100%);
  position: relative;
  padding: 50px 53px;
  min-height: 390px;
  overflow: hidden;
  box-shadow: 0px 8px 12px 0px rgba(20, 21, 24, 0.15);
  display: flex;

  &:after, &:before {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
  }
  &:after {
    top: 0;
    height: 100%;
    z-index: 1;
    background: url("@/assets/home/grain.png") repeat;
    mix-blend-mode: overlay;
  }

  @include media-lg() {
    padding: 20px 28px 20px 28px;
    background: linear-gradient(181deg, #192A36 0.53%, #245C59 101.39%);
    min-height: 493px;
    display: block;

    &:after, &:before {
      display: block;
    }
    &:after {
      top: 0;
      height: 100%;
      z-index: 1;
      opacity: 1;
      background: linear-gradient(180deg, rgba(25, 42, 54, 0.00) 30.11%, #192A36 66.2%);
    }
    &:before {
      bottom: 0;
      z-index: 2;
      height: calc(100% - 103px);
      background: linear-gradient(180deg, rgba(58, 57, 151, 0.00) 54.24%, #3A3997 138.21%);
    }
  }
}
.get-deposit__content {
  max-width: 400px;
  z-index: 3;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  align-self: stretch;

  @include media-lg() {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}
.get-deposit__header {
  text-align: left;
  margin-bottom: 32px;

  @include media-lg() {
    text-align: center;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}
.get-deposit__title {
  color: var(--white);
  font-family: var(--secondary-font);
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  @include media-lg() {
    font-size: 32px;
  }
}
.get-deposit__text {
  display: block;
}
.get-deposit__button {
  font-size: 16px;
  padding: 12px 24px;
  height: 50px;
  width: 100%;
  margin-bottom: 20px;
  margin-top: auto;

  &:hover {
    opacity: 0.8;
  }

  @include media-lg() {
    margin-bottom: 26px;
    font-size: 14px;
    height: 40px;
  }
}
.get-deposit__currencies {
  display: flex;
  flex-wrap: wrap;

  @include media-lg() {
    justify-content: center;
  }
}
.get-deposit__currency {
  margin-right: 20px;
  margin-top: 12px;

  @include media-lg() {
    margin-right: 12px;
  }

  &:last-of-type {
    margin-right: 0;
    margin-bottom: 0;
  }
}
.get-deposit__picture {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  z-index: 2;

  @include media-xl() {
    right: -15%;
  }
  @include media-lg() {
    width: 570px;
    height: 316px;
    right: auto;
    left: calc(50% - 100px);
    transform: translateX(-50%);
    max-width: none;
    z-index: 2;
  }
}
</style>
