<script>
import GameCardLayout from "@/components/casino_redesign/game-card/GameCardLayout.vue"
export default {
  name: "CasinoCard",
  components: { GameCardLayout },
  props: ["game"],
  data() {
    return {
      fiatCurrency: ["usd", "eur"],
      currencyBalance: '',
      currencyPlay: '',
    }
  },
  created() {
    this.currencyBalance = this.selectCurr

    if (this.currencyList.includes(this.currencyBalance)) {
      this.currencyPlay = this.currencyBalance
    } else {
      this.currencyPlay = this.currencyList[0]
    }
  },
  watch: {
    selectCurr: function(value) {
      this.currencyBalance = value
    }
  },
  computed: {
    selectCurr: function () {
      return this.$store.getters.getSelectCurrReal
    },
    getBalance: function() {
      return this.$store.getters.getBalanceReal
    },
    currencyList: function() {
      let data = []
      for (let i=0; i<this.game.currency.length; i++) {
        if (this.fiatCurrency.includes(this.game.currency[i]) || this.game.currency[i] === this.currencyBalance) {
          data.push(this.game.currency[i])
        }
      }
      return data
    },
    countryBlock: function() {
      if (this.game.blacklist.includes(this.$store.getters.getCountry)) {
        return true
      }
      return false
    }
  },
  methods: {
    changeCurrencyBalance(value) {
      this.currencyBalance = value

      this.$store.commit('changeSelectCurr', this.currencyBalance)

      if (!this.currencyList.includes(this.currencyPlay)) {
        this.currencyPlay = this.currencyList[0]
      }
    },
    changeCurrencyPlay(value) {
      this.currencyPlay = value
    }
  }
}
</script>


<template>
  <GameCardLayout
      :isAuth="$store.getters.isAuth"
      :currencyList="currencyList"
      :countryBlock="countryBlock"
      :demo="game.demo"
      :provider="game.provider"
      :name="game.identifier"
      :provider_name="game.provider_name"
      :title="game.title"
      :currencyBalance="currencyBalance"
      :currencyPlay="currencyPlay"
      :selectCurr="selectCurr"
      :getBalance="getBalance"
      class="card"
      @changeCurrencyBalance="changeCurrencyBalance"
      @changeCurrencyPlay="changeCurrencyPlay"
  />
</template>


<style lang="scss" scoped>
  .card {
    margin: auto;
  }
</style>
