<script>
import OurGameCard from './OurGameCard.vue'

export default {
  name: "OurGames",
  components: {
    OurGameCard,
  }
}
</script>

<template>
  <section class="our-games">
    <header class="our-games__header">
      <h2 class="our-games__title">👍 {{ $t('home.our_games') }}</h2>
    </header>
    <div class="our-games__scroll">
      <div class="our-games__scroll-content">
        <OurGameCard class="our-games__card" game="dice" />
        <OurGameCard class="our-games__card" game="plinko" />
        <OurGameCard class="our-games__card" game="roulette" />
        <OurGameCard class="our-games__card" game="wheel" />
        <OurGameCard class="our-games__card" game="crash" />
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
$card-height-mobile: 213px;
$bottom-offset-for-shadow: 0px;

.our-games__scroll {
  @include media-lg() {
    margin-bottom: 24px;
    overflow: hidden;
    height: $card-height-mobile + $bottom-offset-for-shadow;
  }
}
.our-games__scroll-content {
  display: flex;
  justify-content: center;

  @include media-xl() {
    flex-wrap: wrap;
    justify-content: center;
  }

  @include media-lg() {
    text-align: left;
    display: inline-block;
    white-space: nowrap;
    overflow-x: scroll;
    vertical-align: top;
    width: 100vw;
    padding: 0 var(--mobile-content-padding);
    margin-bottom: 0;
    padding-bottom: calc(var(--mobile-content-padding) + #{$bottom-offset-for-shadow});
  }
}

.our-games__header {
  text-align: left;
  margin-bottom: 20px;
  @include media-lg() {
    padding-left: var(--mobile-content-padding);
    padding-right: var(--mobile-content-padding);
  }
}
.our-games__title {
  color: var(--white);
  font-family: var(--secondary-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @include media-lg() {
    font-size: 14px;
  }
}
.our-games__card {
  @include media-xl() {
    margin-bottom: 10px;
    overflow: hidden;
  }
  @include media-lg() {
    margin-bottom: 0;
  }
}
</style>
