<script>
import BaseButton from '@/components/base/BaseButton.vue';


export default {
  name: "SectionSlider",
  components: { BaseButton },
  props: {
    title: { type: String },
    viewAllLink: { type: [String, Object] },
    list: { type: Array, default: () => [] },
  },
  slots: ["title", "headerLeft", "headerCenter", "footer"],
}
</script>

<script setup>
import { ref, watch, onBeforeUnmount } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Mousewheel } from 'swiper/modules';
import 'swiper/scss';


const swiperRef = ref(null);
const currentSlideIndex = ref(0);
const disablePrevButton = ref(true);
const disableNextButton = ref(false);
const numVisibleSlides = ref(1);

watch(currentSlideIndex, () => {
  if (swiperRef.value) {
    const isBeginning = swiperRef.value.isBeginning;
    const isEnd = swiperRef.value.isEnd;
    const isSingleSlide = swiperRef.value.slides.length <= 1;

    disablePrevButton.value = isBeginning || isSingleSlide;
    disableNextButton.value = isEnd || isSingleSlide;
  }
});
const calculateNumVisibleSlides = () => {
  if (swiperRef.value) {
    numVisibleSlides.value = Math.floor(
        swiperRef.value.width / swiperRef.value.slides[0].offsetWidth
    );
  }
};

window.addEventListener('resize', calculateNumVisibleSlides);

onBeforeUnmount(() => {
  window.removeEventListener('resize', calculateNumVisibleSlides);
});

const onSwiperInit = (swiper) => {
  swiperRef.value = swiper;

  swiper.on('slideChange', () => {
    currentSlideIndex.value = swiper.activeIndex;
  });

  swiper.on('update', () => {
    calculateNumVisibleSlides();
  });

  calculateNumVisibleSlides();
};
const prevSlide = () => {
  if (swiperRef.value && !swiperRef.value.isBeginning) {
    swiperRef.value.slidePrev();
    let nextSlide = swiperRef.value.activeIndex - numVisibleSlides.value
    if (nextSlide < 0) {
      nextSlide = 0
    }
    swiperRef.value.slideTo(nextSlide);
  }
};
const nextSlide = () => {
  if (swiperRef.value && !swiperRef.value.isEnd) {
    const lastIndex = swiperRef.value.slides.length - 1
    let nextSlide = swiperRef.value.activeIndex + numVisibleSlides.value
    if (nextSlide > lastIndex) {
      nextSlide = lastIndex
      disableNextButton.value = true
    }
    swiperRef.value.slideTo(nextSlide);
  }
};
</script>

<template>
  <section class="section-slider">
    <header class="section-slider__header">
      <slot name="headerLeft">
        <h2 class="section-slider__title">
          <slot name="title">{{ title }}</slot>
        </h2>
      </slot>

      <slot name="headerCenter">
        <BaseButton
          variant="outlined-gray"
          :to="viewAllLink"
          class="section-slider__button section-slider__view_all"
        >
          {{ $t('home.view_all') }}
        </BaseButton>
      </slot>

      <BaseButton
          variant="outlined-gray"
          iconName="navigate_before"
          iconSize="20"
          class="section-slider__button section-slider__prev"
          :class="{disabled: disablePrevButton}"
          :disabled="disablePrevButton"
          @click="prevSlide"
      />
      <BaseButton
          variant="outlined-gray"
          iconName="navigate_before"
          iconSize="20"
          class="section-slider__button section-slider__next"
          :class="{disabled: disableNextButton}"
          :disabled="disableNextButton"
          @click="nextSlide"
      />
    </header>

    <div class="slider-wrap">
      <swiper
          resizeObserver
          slidesPerView="auto"
          :modules="[Navigation, Mousewheel]"
          :mousewheel="{ forceToAxis: true }"
          @swiper="onSwiperInit"
      >
        <swiper-slide v-for="(item, key) in list" :key="key">
          <slot :item="item" />
        </swiper-slide>
      </swiper>
    </div>

    <slot name="footer" />
  </section>
</template>

<style lang="scss" scoped>
.swiper-slide {
  width: auto;
}

.slider-wrap {
  &:deep {
    .swiper {
      @include media-lg() {
        padding-left: var(--mobile-content-padding);
        padding-right: var(--mobile-content-padding);
      }
    }
  }
}

.section-slider__header {
  display: flex;
  align-items: center;
  margin-bottom: 14px;

  @include media-lg() {
    padding-left: var(--mobile-content-padding);
    padding-right: var(--mobile-content-padding);
  }
}
.section-slider__title {
  margin-right: auto;
  color: var(--white);
  font-family: var(--secondary-font);
  font-size: 16px;
  font-weight: 600;
  line-height: normal;

  @include media-lg() {
    font-size: 14px;
  }
}
.section-slider__button {
  min-width: 50px;
  height: 32px;
  padding: 6px 12px;
}
.section-slider__view_all {
  --color: var(--gray4);
  font-size: 14px;
  margin-left: 12px;
  min-width: 110px;
}
.section-slider__prev {
  color: var(--gray4);
  margin-left: 20px;

  @include media-lg() {
    display: none;
  }
}
.section-slider__next {
  color: var(--gray4);
  margin-left: 12px;

  &:deep {
    .base-button__icon {
      transform: rotate(180deg);
    }
  }

  @include media-lg() {
    display: none;
  }
}
</style>
