<script>
import OurGames from './our-games/OurGames.vue'
import GetDeposit from './get-deposit/GetDeposit.vue'
import Tourneys from './tourneys/Tourneys.vue'
import Bonuses from './bonuses/Bonuses.vue'
import Providers from './providers/Providers.vue'
import Games from './games/Games.vue'
import LastWins from './last-wins/LastWins.vue'

export default {
  name: "HomePage",
  components: { OurGames, GetDeposit, Tourneys, Bonuses, Providers, Games, LastWins },
  data() {
    return {
      loaderMain: true,
      favoriteCount: 0,
      searchTop: [],
      gamesMain: {},
    }
  },
  created() {
    this.loadMainList()
  },
  methods: {
    loadMainList: function() {
      this.loaderMain = true

      this.axios.get('/casino/list/main?' + new Date().getTime())
          .then(response => {
            if (response.data.status === 'success') {
              this.gamesMain = response.data.data.list
              this.searchTop = response.data.data.search
              this.favoriteCount = response.data.data.favorite

              this.loaderMain = false
            } else {
              this.$store.dispatch('addNotif', {type: 'danger', text: response.data.message, isClose: false, timer: 2000}).then()
            }
          })
          .catch(() => {
            this.loaderMain = false
            this.$store.dispatch('addNotif', {type: 'danger', text: 'Server error', isClose: false, timer: 2000}).then()
          })
    },
  }
}
</script>

<template>
  <Logo v-if="loaderMain" />
  <main v-else class="main-page">
    <GetDeposit />
    <Tourneys class="mt" />
    <LastWins class="mt" />
    <OurGames class="mt mb" />
    <Games :games="gamesMain" />
    <Bonuses  class="mt" />
    <Providers class="mt" />
  </main>
</template>

<style lang="scss" scoped>
.main-page {
  padding-top: 15px;

  @include media-lg() {
    padding-top: 0;
  }
}

.mt {
  margin-top: 20px;
}
.mb {
  margin-bottom: 20px;
}
</style>
