const moduleLive = {
  state: {
    liveStats: {
      stats: {},
      graph: {update: 0},
      update: 0
    },
    liveCurrLoaded: []
  },
  getters: {
    getLiveStats: state => state.liveStats.stats,
    getLiveGraph: (state, getters, rootState, rootGetters) => {
      return state.liveStats.graph[rootGetters.getSelectCurr]
    },
    getLiveUpdate: state => state.liveStats.update,
    getCurrLoaded: state => state.liveCurrLoaded,
  },
  mutations: {
    addLoadedCurr: (state, curr) => {
      if (state.liveCurrLoaded.indexOf(curr) === -1) {
        state.liveCurrLoaded.push(curr)
      }
    },
    addLiveUpdate: state => state.liveStats.update++,
    addLiveStats: (state, data) => {
      state.liveStats.stats[data.curr] = data
    },
    updateLiveStats: (state, data) => {
      for(let key in state.liveStats.stats) {
        if (state.liveStats.stats.hasOwnProperty(key) && key === data.curr) {
          state.liveStats.stats[key] = data
        }
      }
      state.liveStats.update++
    },
    clearLiveStatsAll: state => {
      state.liveStats.stats = {}
      state.liveStats.update = 0
    },

    setLiveGraph: (state, data) => {
      state.liveStats.graph = data
    },
    pushLiveGraph: (state, data) => {
      //console.log('push: ', data)
      state.liveStats.graph[data.curr].push(data)
    },
    clearLiveGraphAll: state => {
      state.liveStats.graph = []
    },
    clearLiveGraph: (state, curr) => {
      if (state.liveStats.graph.hasOwnProperty(curr)) {
        state.liveStats.graph[curr] = []
      }
    }

  },
  actions: {
    createLive: context => {
      let graph = {update: 0}
      for (let key in context.getters.getAuthData.balance) {
        if (context.getters.getAuthData.balance.hasOwnProperty(key)) {
          context.commit('addLiveStats', {curr: key, wins: 0, loses: 0, wager: 0, profit: 0})
          graph[key] = []
        }
      }
      context.commit('setLiveGraph', graph)
      //console.log('created live:', context.getters.getLiveStats)
    },
    updateDatabaseStats: (context, data) => {
      //console.log('up')
      //console.log(data)
      if (context.getters.getCurrLoaded.indexOf(data.curr) === -1) {
        return
      }

      let wins = 0
      let loses = 0
      let profit = data.win - data.bet + data.jackpot
      if (profit < 0) {
        loses = 1
      } else if (profit > 0) {
        wins = 1
      }

      const request = context.getters.getLiveStats[data.curr]
      //console.log("data: ", request)

      const ret = {
        curr: data.curr,
        wins: request.wins + wins,
        loses: request.loses + loses,
        wager: parseFloat((request.wager + data.bet).toFixed(5)),
        profit: parseFloat((request.profit + data.jackpot + data.win - data.bet).toFixed(5)),
      }

      context.commit('pushLiveGraph', {curr: data.curr, profit: parseFloat((ret.profit / 1000).toFixed(8))})
      context.commit('updateLiveStats', ret)
    },
    clearDatabaseStats: (context, curr) => {
      const ret = {curr: curr, wins: 0, loses: 0, wager: 0, profit: 0}

      context.commit('clearLiveGraph', curr)
      context.commit('updateLiveStats', ret)
    },
    clearDatabaseAll: context => {

      context.commit('clearLiveGraphAll')
      context.commit('clearLiveStatsAll')
    }
  },
}

export default moduleLive
