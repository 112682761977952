<script>
import BaseTabs from '@/components/base/tabs/BaseTabs.vue'
import TabItem from '@/components/base/tabs/TabItem.vue'
import {tabs, defaultActiveTab} from "@/components/pages/home_redesign/last-wins/tabs";

export default {
  name: "TabNav",
  emits: ['change'],
  components: { BaseTabs, TabItem },
  props: {
    activeTab: { type: String, default: defaultActiveTab }
  },
  computed: {
    tabs() {
      return tabs
    }
  }
}
</script>

<template>
  <BaseTabs>
    <TabItem :active="activeTab === tabs.top" @onClick="$emit('change', tabs.top)" class="tab-nav-item">
      {{ $t('home.top_wins') }}
    </TabItem>
    <TabItem :active="activeTab === tabs.latest" @onClick="$emit('change', tabs.latest)" class="tab-nav-item">
      {{ $t('home.latest_wins') }}
    </TabItem>
  </BaseTabs>
</template>

<style lang="scss" scoped>
.tab-nav-item {
  --gap: 20px;
  --padding-x: 0;
}
</style>
