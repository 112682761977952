<script>
export default {
  name: "PersonalBonus",
  props: {
    name: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    showTimer: { type: Boolean, default: false },
    timer: { type: String, default: '' },
    timerPercent: { type: Number, default: 0 },
    tag: { type: String, default: 'a' },
    isShowLevelDisabled: { type: Boolean, default: false },
    level: { type: Number, default: 0 },
  },
  computed: {
    componentTag() {
      return this.isShowDisabled ? 'div' : this.tag
    },
    isShowDisabled() {
      return this.disabled || this.showTimer
    }
  },
  methods: {
    clickHandler(event) {
      event.preventDefault()
      if (!this.disabled) this.$emit('onclick', event)
    }
  }
}
</script>

<template>
  <component :is="componentTag"
             class="bonus"
             :class="[`bonus-${name}`, (showTimer || isShowLevelDisabled) ? 'bonus-timer' : '']"
             @click="clickHandler"
  >
    <SvgIcon name="personal_bonus_stars_polygon" width="35" height="66" class="bonus__stars-bg" />
    <SvgIcon name="personal_bonus_stars_bg" width="35" height="66" class="bonus__stars-bg" />
    <div class="bonus__text">{{ $t(`personal_bonus.${name}`) }}</div>
    <SvgIcon :name="`personal_bonuses_${name}`" width="38" height="40" class="bonus__icon" :class="`bonus__icon-${name}`" />

    <div v-if="isShowLevelDisabled" class="bonus__disable-block bonus__disable-block-level">
      <div class="bonus__bg" :class="`bonus__bg-${name}`"></div>
      <div class="timer" :class="`timer-${name}`">
        <span class="timer__text">{{ $t('personal_bonus.from_level', { level }) }}</span>
      </div>
    </div>

    <div v-else-if="isShowDisabled" class="bonus__disable-block">
      <div class="bonus__bg" :class="`bonus__bg-${name}`"></div>
      <div v-if="showTimer" class="timer" :class="`timer-${name}`">
        <SvgIcon name="watch" size="16" class="timer__icon" />
        <span class="timer__text">{{ timer }}</span>
      </div>
      <div v-if="showTimer" class="timer__progress" :style="{width: timerPercent + '%'}" />
    </div>
  </component>
</template>

<style lang="scss" scoped>
.bonus {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  min-height: 40px;
  border-radius: 8px;
  padding: 3px 38px 3px 6px;

  &.bonus-timer {
    align-items: start;
    padding-top: 3px;
    padding-bottom: 3px;
  }

  &-rakeback {
    background: linear-gradient(180deg, rgba(22, 150, 125, 0.20) 0%, #0EAD8E 100%);
  }
  &-everyday_bonus {
    background: linear-gradient(180deg, rgba(207, 46, 49, 0.20) 0%, #E82225 100%);
  }
  &-faucet {
    background: linear-gradient(180deg, rgba(37, 99, 203, 0.20) 0%, #1063EF 100%);
  }
  &-bonus_wheel {
    background: linear-gradient(180deg, rgba(104, 44, 198, 0.20) 0%, #742DE3 100%);
  }
  &-coupon {
    background: linear-gradient(180deg, rgba(255, 148, 87, 0.20) 0%, #FFB342 100%);
  }
  &-cashback {
    background: linear-gradient(180deg, rgba(228, 75, 75, 0.00) 0%, #D9155C 100%);
  }

  &__disable-block {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &-level {
      background-color: var(--gray3);
    }
  }

  &__icon {
    position: absolute;
    right: -2px;
    bottom: -1px;

    &-coupon {
      bottom: -4px;
    }
  }
  &__bg {
    opacity: 0.5;
    background-color: #33353D;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  &__bg-everyday_bonus {
    background-color: rgba(207, 46, 49, 0.70);
  }
  &__bg-faucet {
    background-color: rgba(29, 88, 188, 0.70);
  }
  &__bg-bonus_wheel {
    background: rgba(104, 44, 198, 0.70);
  }
  &__text {
    color: var(--white);
    font-family: var(--secondary-font);
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    text-align: left;
  }
  &__stars-bg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.timer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  color: var(--white);
  &__icon {
    --color: var(--white);
    margin-right: 2px;
  }
  &__text {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    user-select: none;
  }
  &__progress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 90%;
    height: 2px;
    background-color: var(--white);
  }
}
</style>
