  <template>
  <transition name="slide-fade">
    <div class="page-loader">
      <div class="page-loader-block">
        <div class="page-loader-box">
          <div class="page-title" :class="{'main-title': type === 'loader'}">{{title}}</div>
          <Logo></Logo>
          <div class="text">{{text}}</div>
        </div>
      </div>


    </div>
  </transition>
</template>

<script>
//import Logo from './Logo'
export default {
  //components: {Logo},
  data () {
    return {
      title: 'Server connection error',
      text: 'Please wait...'
    }
  },
  props: {
    type: {
      type: String,
      default: 'reconnect'
    }
  },
  mounted () {
    this.$nextTick(function () {
      const self = this
      if (this.type === 'loader') {
        this.title = 'WINDICE'
        this.text = ''
      } else if (this.type === 'reconnect') {
        setTimeout(function () {
          self.$router.go('/')
        }, 30000)
      } else if (this.type === 'socket') {
        this.title = 'Establishing connection'
      }
    })
  }
}
</script>

<style scoped>
  .page-loader{
    position: fixed;
    overflow-x: hidden;
    overflow-y: hidden;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    z-index: 9999;
    background-color: #222222;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h16v2h-6v6h6v8H8v-6H2v6H0V0zm4 4h2v2H4V4zm8 8h2v2h-2v-2zm-8 0h2v2H4v-2zm8-8h2v2h-2V4z' fill='%23000000' fill-opacity='0.47' fill-rule='evenodd'/%3E%3C/svg%3E");
    box-shadow: inset 0 -100px 300px -20px rgba(0, 0, 0, 0.5);
  }
  .page-loader-block{
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .page-loader-box{
    /*height: 100%;
    padding-top: 40px;*/
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
  }
  .page-loader .page-title{
    font-weight: bold;
    font-size: 32px;
    color: #FFF;
  }
  .page-loader .text{
    font-weight: 900;
    font-size: 24px;
    color: #FFF;
  }

  .page-loader.slide-fade-enter-active,
  .page-loader.slide-fade-leave-active {
    transition: all 1.5s ease;
  }

  .page-loader.slide-fade-enter,
  .page-loader.slide-fade-leave-to {
    transform: translateY(-100vh);
    opacity: 0;
  }
  .page-loader .page-title.main-title{
    font-size: 42px;
    font-weight: bold;
  }
  @media (max-width: 767px) {
    .page-loader-box{
      margin-top: -60px;
    }
  }
</style>
