<script>
import BaseButton from '@/components/base/BaseButton.vue';

export default {
  name: "BonusCard",
  components: { BaseButton },
  props: {
    title: { type: String },
    text: { type: String },
    link: { type: String },
  }
}
</script>

<template>
  <div class="bonus-card">
    <div class="bonus-card__content">
      <h3 class="bonus-card__title">{{ title }}</h3>
      <p class="bonus-card__text">{{ text }}</p>
      <BaseButton :to="link" variant="white" class="bonus-card__learn-more">
        {{ $t('bonuses.learn_more') }}
      </BaseButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.bonus-card {
  width: 100%;
  min-height: 257px;
  border-radius: 8px;
  color: var(--white);
  padding: 60px 32px;
  text-align: left;
  display: flex;

  @include media-lg() {
    padding: 12px;
    min-height: 170px;
  }
}
.bonus-card__content {
  max-width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;

  @include media-lg() {
    max-width: 70%;
  }
}
.bonus-card__title {
  font-family: var(--secondary-font);
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 12px;

  @include media-lg() {
    margin-bottom: 8px;
    font-size: 16px;
  }
}
.bonus-card__text {
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  margin-bottom: 20px;

  @include media-lg() {
    margin-bottom: 16px;
  }
}
.bonus-card__learn-more {
  min-width: 110px;
  height: 40px;
  padding: 10px 20px;
  font-size: 14px;
  margin-top: auto;
}
</style>
