import { type ComponentOptionsMixin } from 'vue'
//
import { VIEWPORT } from '@/constants/theme'
import { debounce } from '@/utils/functions'

const globalResizeMixin: ComponentOptionsMixin = {
    data() {
        return {
            isDesktop: true,
            isMobile: false,
            cb: {
                debouncedUpdateWindowSize: debounce(this.updateWindowSize)
            }
        }
    },
    mounted() {
        // Если код будет исполняться на сервере
        if (!window) return;
        //
        this.cb.debouncedUpdateWindowSize();
        //
        window.addEventListener('resize', this.cb.debouncedUpdateWindowSize);
    },
    destroyed() {
        // Если код будет исполняться на сервере
        if (!window) return;
        //
        window.removeEventListener('resize', this.cb.debouncedUpdateWindowSize);
    },
    methods: {
        updateWindowSize() {
            //
            this.isDesktop = window.matchMedia(VIEWPORT.desktop).matches;
            //
            this.isMobile = window.matchMedia(VIEWPORT.mobile).matches;
        }
    }
};

export {
    globalResizeMixin
}

