const Xrp = {
  symbol: 'xrp',
  color: '#000',
  plainIcon: c => {
    return `<path fill="${
      c ? c : this.color
    }" d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm7.07-24l-4.574 4.523a3.556 3.556 0 0 1-4.996 0L8.93 8H6.035l6.02 5.957a5.621 5.621 0 0 0 7.89 0L25.961 8h-2.89zM8.895 24.563L13.504 20a3.556 3.556 0 0 1 4.996 0l4.605 4.563H26l-6.055-5.993a5.621 5.621 0 0 0-7.89 0L6 24.562h2.895z"/>`;
  },
  colorIcon() {
    return `<g fill="none"><circle cx="16" cy="16" r="16" fill="#3c3d41"/><path d="M23.07 8h2.89l-6.015 5.957a5.621 5.621 0 0 1-7.89 0L6.035 8H8.93l4.57 4.523a3.556 3.556 0 0 0 4.996 0L23.07 8zM8.895 24.563H6l6.055-5.993a5.621 5.621 0 0 1 7.89 0L26 24.562h-2.895L18.5 20a3.556 3.556 0 0 0-4.996 0l-4.61 4.563z" fill="#FFF"/></g>`;
  }
};
export default Xrp;
