<script>
import Switcher from './Switcher.vue'

export default {
  name: "LangSwitcher",
  components: { Switcher },
  data () {
    return {
      currentUrl: this.$router.currentRoute.value.fullPath,
      links: [],
    }
  },
  watch: {
    $route (to) {
      this.currentUrl = to.fullPath
      this.generateLinks()
    }
  },
  created() {
    this.generateLinks()
  },
  computed: {
    currentLang: function() {
      for (let i=0; i < this.links.length; i++) {
        if (this.links[i].active === true) {
          return this.links[i]
        }
      }

      return null
    }
  },
  methods: {
    generateLinks () {
      let links = []
      const tr = this._langRouter.translations

      for (let lang in tr) {
        const shortName = tr[lang].name ? tr[lang].name.substring(0, 3) : lang
        links.push({
          active: (lang === this.$i18n.locale),
          langIndex: lang,
          langName: shortName,
          url: lang !== this.$i18n.fallbackLocale ? '/' + lang : '/',
        })
      }
      this.links = links
    },
    setLanguage: function (lang, url) {
      this.$i18n.locale = lang
      document.querySelector('html').setAttribute('lang', lang)
      localStorage.setItem('VueAppLanguage', lang)
      window.location.replace(url)
    }
  }
}
</script>

<template>
  <Switcher
      :currentLang="currentLang"
      :links="links"
      @setLanguage="setLanguage"
  />
</template>


<style lang="scss" scoped>
</style>

