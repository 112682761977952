const moduleMessages = {
  state: {
    unread: 0,
    hash: null,
    subscribe: null,
    dialog: null, //подпись на сообщения в диалоге


    subscribeNotif: null,
  },
  getters: {
    getMessagesUnread: state => state.unread,
    getMessagesHash: state => state.hash,

    //это подписка на собитие открытия приватных сообщений из вне
    getMessagesSubscribe: state => state.subscribe,

    // подпись на новые нотификационные сообщения
    // чтоб определить на вкладке диалогов в вкаой именно пришло
    // и выставить ему статус не прочитанного
    getMessagesSubscribeNotif: state => state.subscribeNotif,

    getMessagesDialog: state => state.dialog,
  },
  mutations: {

    messagesOpenHash: (state, hash) => state.hash = hash,
    messagesCloseHash: state => state.hash = null,
    messagesUnreadMinus: state => state.unread--,

    messagesSubscribeOpen: (state, hash) => state.subscribe = hash,
    messagesSubscribeNull: state => state.subscribe = null,

    messagesSubscribeNotif: (state, hash) => state.subscribeNotif = hash,
    messagesSubscribeNotifNull: state => state.subscribeNotif = null,

    messagesDialog: (state, data) => state.dialog = data,

    // приходит сообщение о количестве не прочитаных
    messagesUnreadSet: (state, count) => state.unread = count,
  },
  actions: {

    socket_messages_unread: (context, data) => {
      //console.log('messages unread', data)
      if (data.dialog !== "" && context.getters.getMessagesHash === data.dialog) return
      context.commit("messagesUnreadSet", data.count)
    },

    // уведомление о новом сообщении в личку
    socket_messages_notification: (context, data) => {
      //console.log("New message", data)

      if (context.getters.getMessagesHash !== data.dialog) {
        // диалог не открыт
        //context.commit("messagesUnreadPlus")

        data.isMessage = true
        data.text = data.data.text

        //console.log(data)

        context.dispatch('addNotif', {type: 'info', text: data, isClose: true, timer: 5000}).then()

        context.commit("messagesSubscribeNotif", data.dialog)

        //playSound
        context.commit('playSound', 'notif')
      } else {
        // диалог открыт, засылаем сообщение в него

        //context.commit("messagesUnreadMinus")
        context.commit("messagesDialog", data)
      }
    }
  }
}

export default moduleMessages
