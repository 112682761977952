<script>
export default {
  name: "SideBarHeader",
  emits: ['closeSideBar'],
  methods: {
    closeSideBar() {
      this.$emit('closeSideBar')
    },
  }
}
</script>

<template>
  <div class="side-bar__mobile-header">
    <div class="side-bar__logo">
      <localized-link class="logo" to="/">
        <img class="logo__img" src="../../../assets/logo_.svg" alt="windice" height="32" width="30">
        <img class="logo__name" src="../../../assets/site_logo_name.svg" alt="windice" height="13" width="78">
      </localized-link>
    </div>
    <button class="show-on-mobile side-bar__btn side-bar__close" @click.prevent="closeSideBar">
      <SvgIcon name="shape" size="20" class="side-bar__close-icon"/>
    </button>
  </div>
</template>

<style lang="scss" scoped>
.side-bar__mobile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.side-bar__logo {
  display: flex;
}
.side-bar__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--gray);
  border: none;
  cursor: pointer;
  color: var(--gray5);
  margin-left: 6px;
  font-weight: 700;
  font-size: 14px;
  transition: background .3s ease;
  &:hover {
    background: var(--gray2);
  }
}
.side-bar__close {
  height: 32px;
}
.show-on-mobile {
  display: none;

  @include media-lg() {
    display: flex;
  }
}
.logo {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.logo__img {
  margin-right: 8px;

  height: 43px;
  width: 40px;
  @include media-lg() {
    height: 32px;
    width: 30px;
  }
}
.logo__name {
  height: 17px;
  width: 103px;
  @include media-lg() {
    height: 13px;
    width: 78px;
  }
}
</style>
