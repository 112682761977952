<script>
export default {
  name: "ModalLogout",
  emits: ['close', 'logoutSend'],
  data () {
    return {
      passwordNewText: '',
      passwdNewPasswordError: '',
      passwordConfirmText: '',
      passwdConfirmPasswordError: '',
      passwordLoader: false,
      passwordSuccess: false,
    }
  },
  methods: {
    setPassword: function () {
      this.passwordLoader = true
      this.passwdNewPasswordError = ''
      this.passwdConfirmPasswordError = ''

      this.axios.post('/user/changePasswd', {
        password: '', newPassword: this.passwordNewText, confirmPassword: this.passwordConfirmText
      })
          .then(response => {
            this.passwordLoader = false
            if (response.data.status === 'success') {
              this.passwordSuccess = true
              this.$store.commit('setUserData', { key: 'is_password', value: true})
            } else {
              if (response.data.message !== '') {
                this.$store.dispatch('addNotif', {type: 'danger', text: response.data.message, isClose: false, timer: 2000}).then()
              }
              if (response.data.data.newPassword !== undefined) {
                this.passwdNewPasswordError = response.data.data.newPassword
              }
              if (response.data.data.confirmPassword !== undefined) {
                this.passwdConfirmPasswordError = response.data.data.confirmPassword
              }
            }
          })
          .catch(e => {
            this.passwordLoader = false
            this.$store.dispatch('addNotif', {type: 'danger', text: e.response.data, isClose: false, timer: 2000}).then()
          })
    },
    closeModalLogout: function () {
      this.$store.commit('changeScroll', true)
      this.$emit('close')
    },
  }
}
</script>

<template>
  <div class="modal modal-bet is-active">
    <div class="modal-background" @click="closeModalLogout"></div>
    <div class="modal-card" :class="{shake: passwdNewPasswordError || passwdConfirmPasswordError}">
      <section class="modal-card-body">
        <button class="delete close-button" aria-label="close" @click="closeModalLogout"></button>
        <div>
          <div v-show="passwordSuccess">
            <div class="notification is-success">{{ $t('modals.login.password_success') }}</div>
          </div>
          <div v-show="!passwordSuccess" class="password-set-block">
            <div>{{ $t('modals.login.password_text[0]') }}</div>
            <div>{{ $t('modals.login.password_text[1]') }}</div>
            <div class="input-block">

              <div class="field-body field-login" :class="{'is-danger': passwdNewPasswordError}">
                <div class="field is-expanded">
                  <div class="field has-addons">
                    <p class="control">
                      <label for="inputSetPassword" class="button input-label is-uppercase">{{ $t('modals.login.password') }}</label>
                    </p>
                    <p class="control">
                      <input id="inputSetPassword" class="input" type="password" v-model.trim="passwordNewText" :disabled="passwordLoader">
                    </p>
                  </div>
                  <p class="help is-danger">{{passwdNewPasswordError}}</p>
                </div>
              </div>

              <div class="field-body field-login" :class="{'is-danger': passwdConfirmPasswordError}">
                <div class="field is-expanded">
                  <div class="field has-addons">
                    <p class="control">
                      <label for="inputSetPasswordTwo" class="button input-label is-uppercase">{{ $t('modals.login.password_confirm') }}</label>
                    </p>
                    <p class="control">
                      <input id="inputSetPasswordTwo" class="input" type="password" v-model.trim="passwordConfirmText" :disabled="passwordLoader">
                    </p>
                  </div>
                  <p class="help is-danger">{{passwdConfirmPasswordError}}</p>
                </div>
              </div>

              <button class="button is-success" :class="{'is-loading':passwordLoader}" @click="setPassword">{{ $t('modals.login.password_set') }}</button>
            </div>
          </div>
          <div class="confirm-block">
            <button class="button is-danger is-outlined" @click="$emit('logoutSend')">{{ $t('menu.logout') }}</button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>


<style lang="scss" scoped>
.password-set-block{
  font-size: 14px;
}
.modal-card{
  max-width: 390px;
}
.close-button{
  position: absolute;
  top: 10px;
  right: 10px;
}
.modal-card-body{
  min-height: 150px;
  padding: 35px 30px 5px 30px;
  color: #DED3D3;
  font-weight: 700;
}
.input-block{
  margin-top: 10px;
}
.input-block .input-label{
  min-width: 140px;
}

.confirm-block{
  margin-top: 20px;
  border-top: 1px solid #DED3D3;
  padding: 10px 0;
}
</style>

