// debounce функция - вызов только при окончании таймаута
const debounce = (fn: Function, ms = 300) => {
    //
    let timeoutId: ReturnType<typeof setTimeout>;
    //
    return function (this: any, ...args: any[]) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), ms);
    }
}

export {
    debounce
}