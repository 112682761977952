
const GAME_POPULAR_CATEGORY_ICON = "🔥";
const GAME_NEW_CATEGORY_ICON = "🎰";
const GAME_SLOTS_CATEGORY_ICON = "👑";
const GAME_LIVE_CATEGORY_ICON = "🎲";
const GAME_CARD_CATEGORY_ICON = "❤️";

const GAME_CATEGORY_ICONS = {
  popular: GAME_POPULAR_CATEGORY_ICON,
  new: GAME_NEW_CATEGORY_ICON,
  slots: GAME_SLOTS_CATEGORY_ICON,
  live: GAME_LIVE_CATEGORY_ICON,
  card: GAME_CARD_CATEGORY_ICON,
}

export {
  GAME_CATEGORY_ICONS,
}
