import { RouteRecordRaw } from 'vue-router'
//
const CabinetRoutes: RouteRecordRaw[] = [
  {
    path: '/cabinet',
    name: 'Cabinet',
    props: true,
    component: () => import('@/components/cabinet/layouts/MainLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'profile',
        name: 'CabinetProfile',
        props: true,
        component: () => import('@/components/cabinet/pages/Profile.vue'),
      },
      {
        path: 'history',
        component: () => import('@/components/cabinet/pages/history/HistoryRouter.vue'),
        children: [
          {
            path: '',
            name: 'History',
            component: () => import('@/components/cabinet/pages/history/CabinetHistory.vue'),
          },
          {
            path: 'archive',
            name: 'HistoryArchive',
            component: () => import('@/components/cabinet/pages/history/ArchiveHistory.vue'),
          },
        ]
      },
      {
        path: "fair",
        component: () => import("@/components/pages/fair_redesign/FairLayout.vue"),
        meta: {
          title: "WINDICE - Fairness",
          description: "Windice fairness check and rules",
        },
        children: [
          {
            path: "",
            name: "CabinetFair",
            component: () => import("@/components/pages/fair_redesign/FairSession.vue"),
          },
          {
            path: "verification",
            component: () => import("@/components/pages/fair_redesign/FairVerifLayout.vue"),
            children: [
              {
                path: "",
                name: "CabinetVerification",
                component: () => import("@/components/pages/fair_redesign/games/FairDice.vue"),
              },
              {
                path: "dice",
                component: () => import("@/components/pages/fair_redesign/games/FairDice.vue"),
              },
              {
                path: "crash",
                component: () => import("@/components/pages/fair_redesign/games/FairCrash.vue"),
              },
              {
                path: "plinko",
                component: () => import("@/components/pages/fair_redesign/games/FairPlinko.vue"),
              },
              {
                path: "wheel",
                component: () => import("@/components/pages/fair_redesign/games/FairWheel.vue"),
              },
              {
                path: "roulette",
                component: () => import("@/components/pages/fair_redesign/games/FairRoulette.vue"),
              },
            ],
          },
        ],
      },
      {
        path: 'finances',
        name: 'Finances',
        component: () => import('@/components/cabinet/pages/Finances.vue'),
        // component: () => import('@/components/user/Finances.vue'),
      },
      {
        path: 'bank',
        name: 'Bank',
        component: () => import('@/components/user/Bank.vue'),
      },
      /*
      {
      path: '/cabinet/affiliate',
      component: () => import('@/components/user/Affiliate'),
      meta: { requiresAuth: true }
      },
      */
      {
        path: 'invest',
        component: () => import('@/components/invest/LayoutUser.vue'),
        children: [
          {
            path: '',
            name: 'Invest',
            component: () => import('@/components/invest/Dashboard.vue'),
          },
          {
            path: 'list',
            name: 'InvestList',
            component: () => import('@/components/invest/List.vue'),
          },
        ]
      },
      {
        path: 'shop',
        component: () => import('@/components/cabinet/pages/shop/ShopLayout.vue'),
        children: [
          {
            path: '',
            name: 'Shop',
            component: () => import('@/components/cabinet/pages/shop/ShopItems.vue'),
          },
          {
            path: ':category',
            name: 'ShopBoosters',
            props: true,
            component: () => import('@/components/cabinet/pages/shop/ShopItems.vue'),
          },
        ]
      },
      {
        path: 'partner',
        component: () => import('@/components/cabinet/layouts/PartnerLayout.vue'),
        children: [
          {
            path: '',
            name: 'CabinetPartnership',
            props: true,
            component: () => import('@/components/cabinet/pages/partner/dashboard/Dashboard.vue'),
          },
          {
            path: 'balances',
            component: () => import('@/components/cabinet/pages/partner/balances/Balances.vue'),
          },
          {
            path: 'campaigns',
            component: () => import('@/components/cabinet/pages/partner/campaigns/Campaigns.vue'),
          },
          {
            path: 'banners',
            name: 'PartnerBanners',
            props: true,
            component: () => import('@/components/cabinet/pages/partner/banners/Banners.vue'),
          },
          {
            path: 'logs',
            component: () => import('@/components/cabinet/pages/partner/logs/Logs.vue'),
          },
          {
            path: 'users',
            component: () => import('@/components/cabinet/pages/partner/users/Users.vue'),
          },
          {
            path: 'hits',
            component: () => import('@/components/cabinet/pages/partner/hits/Hits.vue'),
          },
        ]
      },
      {
        path: 'settings',
        name: 'Settings',
        component: () => import('@/components/cabinet/pages/settings/SettingsPage.vue'),
      },
      {
        path: 'vip',
        component: () => import('@/components/vip_redesign/LayoutVip.vue'),
        children: [
          {
            path: '',
            name: 'Vip',
            component: () => import('@/components/vip_redesign/Dashboard.vue'),
          },
          {
            path: 'rakeback',
            name: 'VipRakeback',
            component: () => import('@/components/vip_redesign/VipBack.vue'),
          },
          {
            path: 'cashback',
            name: 'VipCashback',
            component: () => import('@/components/vip_redesign/VipBack.vue'),
          },
        ]
      },
      {
        path: 'bonuses',
        name: 'CabinetBonuses',
        props: true,
        component: () => import('@/components/cabinet/layouts/BonusesLayout.vue'),
        children: [
          {
            path: '',
            name: 'CabinetBonusesMain',
            props: true,
            component: () => import('@/components/cabinet/pages/Bonuses.vue'),
          },
          {
            path: 'history',
            name: 'CabinetBonusesHistory',
            props: true,
            component: () => import('@/components/cabinet/pages/BonusesHistory.vue'),
          },
        ]
      },
    ]
  },
];

export {
  CabinetRoutes
}
