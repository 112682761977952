<script>
import SearchData from "@/components/navbars_redesign/search/results/SearchData.vue"
import SearchLoader from "@/components/navbars_redesign/search/SearchLoader.vue";
import SearchResults from "@/components/navbars_redesign/search/results/SearchResults.vue";
import EmptySearch from "@/components/navbars_redesign/search/results/EmptySearch.vue";
import SearchError from "@/components/navbars_redesign/search/results/SearchError.vue";
import {onClickOutside} from "@vueuse/core/index";
import { debounce } from '@/utils/functions'


const MIN_LENGTH_FOR_SEARCH = 3

export default {
  name: "SearchGames",
  components: {SearchError, EmptySearch, SearchResults, SearchLoader, SearchData},
  emits: ['result', 'result-load', 'close', 'change-search', 'changeSort', 'openNextSearchPage', 'onChangeIsOpenSearch'],
  props: {
    searchTop: { type: Array },
    sortType: { type: String},
    page: { type: Number, default: 1},
    isLoadingPopularSearch: { type: Boolean, default: false },
    popularSearchList: { type: Array, default: () => [] },
    popularSearchError: { type: String, default: '' },
    isLoadingTopGame: { type: Boolean, default: false },
    topGameList: { type: Array, default: () => [] },
    topGameError: { type: String, default: '' },
    openSearchResult: { type: Boolean, default: false },
    loaderPage: { type: Boolean, default: false },
    sortLoader: { type: Boolean, default: false },
    searchGames: { type: Array, default: () => [] },
    searchPages: { type: Number, default: 0},
    searchPerPage: { type: Number, default: 0},
    searchCurrentPage: { type: Number, default: 1},
    hideOnOutsideClick: { type: Boolean, default: false},
  },
  data() {
    return {
      searchText: "",
      openSearchClick: false,
      searchLoader: false,
      searchTimeout: null,
      searchError: "",
      searchHistory: [],
      searchWidth: 0,
    }
  },

  created() {
    this.setHistoryFromLocalStorage()
  },
  mounted() {
    this.searchWidth = document.getElementById('casinoSearchWrap').clientWidth + 20

    if (this.hideOnOutsideClick) this.enableHiddenOnClickOutside()
  },
  computed: {
    isOpenSearch: function () {
      const isShowResults = this.openSearchResult
      const isShowEmpty = this.openSearchClick && this.isEmptySearch
      const isShowLoader = this.searchLoader
      const isShowError = this.searchError !== ""

      return isShowResults || isShowEmpty || isShowLoader || isShowError
    },
    isEmptySearch() {
      return this.searchText.length < MIN_LENGTH_FOR_SEARCH
    }
  },
  watch: {
    searchText: function(newText, oldText) {
      if (newText.length >= MIN_LENGTH_FOR_SEARCH) {
        this.$emit('change-search')
        this.searchLoader = true
        this.searchError = ""

        clearTimeout(this.searchTimeout)

        // without timeout
        if (oldText === "" || (newText.substring(0, oldText.length) !== oldText && oldText.substring(0, newText.length) !== newText)) {
          this.sendSearch(false)
          return
        }

        // with timeout
        this.searchTimeout = setTimeout(()=> {
          this.sendSearch(false)
        }, 1000)
      }
    },
    sortType: function(type) {
      this.$emit('change-search')
      this.sendSearch(false)
    },
    page: function(value) {
      if (value === 1) return
      this.sendSearch(true)
    },
    '$route'() {
      this.closeSearchMenu()
    },
    isOpenSearch(value) {
      this.$emit('onChangeIsOpenSearch', value)
    }
  },
  methods: {
    enableHiddenOnClickOutside() {
      const search = this.$refs.search
      onClickOutside(search, () => this.clearSearch())
    },
    setHistoryFromLocalStorage() {
      let history = localStorage.getItem('search-history')
      if (history !== null) history = JSON.parse(history)
      else history = []

      this.searchHistory = history
    },
    sendSearch: debounce(function(nextPage) {
      if (!this.sortType || !this.searchText) return;

      let page = this.page
      if (nextPage === false) page = 1

      this.axios.get( `/casino/search/${this.searchText}/${this.sortType}/${page}`)
          .then(response => {
            if (response.data.status === "success") {
              if (nextPage === false) {
                this.resultSearch(response.data.data.transactions, response.data.data.pages, response.data.data.per_page)
              } else {
                this.addResultSearch(response.data.data.transactions)
              }

              if (response.data.data.transactions.length > 5 && nextPage === false) {
                let history = localStorage.getItem('search-history')
                if (history === null) history = []
                else history = JSON.parse(history)

                let isFind = -1
                for (let i=0; i<history.length; i++) {
                  if (history[i] === this.searchText) isFind = i
                }

                if (isFind === -1) {
                  if (history.length > 5) {
                    history.pop()
                  }
                  history.unshift(this.searchText)
                } else if (history[0] !== this.searchText && history.length > 1) {
                  history.unshift(...history.splice(isFind, 1));
                }

                this.searchHistory = history
                localStorage.setItem('search-history', JSON.stringify(history))
              }
            } else if (response.data.status === 'error' && response.data.message === '') {
              if (response.data.data.text !== undefined) {
                this.searchError = response.data.data.text
              }
            } else {
              this.$store.dispatch('addNotif', {type: 'danger', text: response.data.message, isClose: false, timer: 2000}).then()
            }
            this.searchLoader = false
          })
    }),
    openSearchMenu() {
      this.openSearchClick = true
    },
    closeSearchMenu() {
      this.openSearchClick = false
    },
    toggleFavorite: function() {
      this.searchText = 'favorite'
    },
    changeSearchText: function(text) {
      this.searchText = text
    },
    clearSearch: function() {
      this.searchText = ""
      this.searchError = ""
      this.openSearchClick = false
      this.$emit('close')
    },
    resultSearch: function(data, pages, perPage) {
      this.$emit('result', data, pages, perPage)
    },
    addResultSearch: function(data) {
      this.$emit('result-load', data)
    },
    changeSort: function(type) {
      this.$emit("changeSort", type)
    },
  }
}
</script>



<template>
  <div class="casino-search-block search-games" ref="search">
    <div class="casino-search-wrap casino-search-wrap-input" id="casinoSearchWrap">
      <div class="casino-search search__inner" @click="openSearchMenu" :class="{active: isOpenSearch}" id="searchMenuBlock">
        <input v-model.trim="searchText" type="text" class="search__input" :placeholder="$t('casino.search')">
        <font-awesome-icon icon="search" class="search__icon"/>
      </div>
    </div>


    <transition name="fade">
      <SearchData
          v-if="isOpenSearch"
          id="searchMenu"
          class="search__results"
          :class="{active: isOpenSearch}"
      >
        <SearchError
            v-if="searchError !== ''"
            :searchError="searchError"
            :isLoadingPopularSearch="isLoadingPopularSearch"
            :popularSearchList="popularSearchList"
            :popularSearchError="popularSearchError"
            :text="searchText"
        />

        <SearchLoader
            v-else-if="searchLoader"
            :text="$t('casino.searching')"
        />

        <EmptySearch
            v-else-if="isEmptySearch"
            :searchHistory="searchHistory"
            :top="searchTop"
            :isLoadingPopularSearch="isLoadingPopularSearch"
            :popularSearchList="popularSearchList"
            :popularSearchError="popularSearchError"
            :isLoadingTopGame="isLoadingTopGame"
            :topGameList="topGameList"
            :topGameError="topGameError"
            @text="changeSearchText"
        />

        <SearchResults
            v-else-if="openSearchResult"
            :loaderPage="loaderPage"
            :sortLoader="sortLoader"
            :searchGames="searchGames"
            :searchPages="searchPages"
            :searchPerPage="searchPerPage"
            :searchCurrentPage="searchCurrentPage"
            @changeSort="changeSort"
            @openNextSearchPage="$emit('openNextSearchPage')"
        />
      </SearchData>
    </transition>

  </div>
</template>


<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all .5s ease;
}
.fade-enter,
.fade-leave-to {
  transform: none;
  opacity: 0;
}

.search__results {
  &:deep {
    .search-cards {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
      grid-row-gap: 12px;

      @include media-xxs() {
        grid-template-columns: repeat(auto-fill, minmax(168px, 1fr));
        grid-row-gap: 8px;
      }
    }
    .search__result-title {
      color: var(--white);
      font-family: var(--secondary-font);
      font-size: 16px;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 12px;
      display: inline-block;

      @include media-lg() {
        font-size: 14px;
        margin-bottom: 17px;
      }
    }
  }
}

.search__inner {
  display: flex;
  align-items: center;
  position: relative;
}
.search__icon {
  opacity: 0.5;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.search__input {
  padding: 8px 24px 8px 4px;
  background-color: transparent;
  outline: 0;
  border: 0;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  color: var(--white);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;

  &::placeholder {
    color: var(--gray4);
  }
}

  .casino-search-block {
    display: flex;
    position: relative;
    flex-direction: column;

    .casino-search-wrap {
      display: flex;
      &.casino-search-wrap-input {
        flex: 1;
      }
      .casino-search {
        height: 36px;
        flex: 1;
        position: relative;
        border-radius: 8px;
        padding: 0 10px;
        transition: .3s ease;
        background-color: var(--dark);

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        &:hover {
          border-color: #00bfff;
        }
      }

      .casino-search-button {
        height: 36px;
        margin-left: 10px;
        position: relative;

        > button {
          width: 150px;
          height: 100%;
          border-radius: 8px;
          border: 1px solid #0075b1;
          background-color: #28292d;
          box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2);
          transition: .3s ease;
          color: #FFF;
          text-transform: uppercase;
          font-size: 15px;
          font-weight: bold;
          cursor: pointer;
          position: relative;

          &:hover {
            border-color: #00bfff;
            box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
          }
        }

        &:after {
          visibility: hidden;
          opacity: 0;
          transition: .1s ease;
          position: absolute;
          content: ' ';
          left: 0;
          right: 0;
          top: 34px;
          height: 7px;
          background-color: #28292d;
          border-left: 1px solid #00bfff;
          border-right: 1px solid #00bfff;
          z-index: 11;
        }

        &.active {
          > button {
            border-color: #00bfff;
            box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
            font-size: 18px;
            border-bottom-color: #28292d;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
          }

          &:after {
            visibility: visible;
            opacity: 1;
          }
        }
      }

      .casino-search-favorite {
        height: 35px;
        width: 35px;
        margin-left: 10px;

        > button {
          height: 100%;
          width: 100%;
          border-radius: 8px;
          border: 1px solid #0075b1;
          background-color: #28292d;
          box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2);
          transition: .3s ease;
          color: #FFF;
          font-size: 15px;

        }

        &.active, &:hover {
          > button {
            border-color: #00bfff;
            box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
    .search__results {
      right: 0;
      z-index: 10;
      position: absolute;
      top: calc(100% + 16px);
      left: 0;
      width: 830px;


      .casino-category {
        cursor: pointer;

        .casino-category-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          transition: .3s ease;
        }

        .casino-category-text {
          font-size: 18px;
          font-weight: bold;
          text-transform: capitalize;
          transition: .3s ease;
        }
        &:hover {
          .casino-category-icon {
            transform: scale(1.3);
          }
          .casino-category-text {
            transform: scale(1.3);
          }
        }
      }
      &.casino-provider {
        flex-wrap: wrap;

        .casino-category {
          width: 20%;
          padding: 10px;

          .casino-category-icon {
            background-color: rgba(21, 22, 25, 0.5);
            box-shadow: 0 13px 13px rgba(0, 0, 0, 0.25);
            border-radius: 8px;
            border: 0.5px solid #464646;
          }

          .casino-category-text {
            font-size: 16px;
          }

          &:hover {
            .casino-category-icon {
              transform: scale(1.2);
            }

            .casino-category-text {
              transform: scale(1.1);
            }
          }
        }
      }

      &.casino-search-result {
        right: unset;
      }
    }
  }
  @include media-md() {
    .casino-search-block {
      padding: 0;
      flex-direction: column;

      .casino-search-wrap {
        .casino-search {
          &.active {
            > input {
              border: 0;
            }
          }
        }
        &.casino-search-wrap-input {
          margin-bottom: 10px;
        }
        .casino-search-button {
          width: 100%;
          > button {
            width: 100%;
          }

          &:first-of-type {
            margin-left: 0;
          }
          &.active {
            > button {
              font-size: 15px;
            }
          }
        }
        .casino-search-favorite {
          > button {
            width: 35px;
            padding: 0;
          }
        }
      }

      .casino-search-data {
        top: 85px;
        padding: 10px 5px;
        flex-wrap: wrap;

        &.casino-search-category {
          border-top-left-radius: 0;
        }

        &.casino-search-result {
          top: 40px;
          border-top-right-radius: 0;
          border-top-left-radius: 0;
        }

        .casino-category {

          .casino-category-icon {
            > img {
              height: 52px;
            }
          }
          .casino-category-text {
            font-size: 14px;
          }
        }
        &.casino-provider {
          .casino-category {
            width: unset;
            .casino-category-text {
              font-size: 14px;
            }
          }
        }
        &.casino-search-result {
          min-width: unset !important;
          width: 100%;
        }
      }

    }
  }

  @include media-xxs() {
    .casino-search-block .casino-search-data.casino-provider .casino-category {
      width: 50%;
    }

  }
</style>
