<script>
import GameImage from "@/components/casino_redesign/game-card/GameImage.vue"

export default {
  name: "GameCardLayout",
  components: {GameImage},
  emits: ['changeCurrencyBalance', 'changeCurrencyPlay'],
  props: {
    isAuth: { type: Boolean },
    currencyList: { type: Array },
    countryBlock: { type: Boolean },
    demo: { type: Boolean },
    provider: { type: String },
    name: { type: String },
    provider_name: { type: String },
    title: { type: String },
    currencyBalance: { type: String }, // 'btc'
    currencyPlay: { type: String }, // 'btc'
    selectCurr: { type: String }, // 'btc'
    getBalance: { type: Object }, // { 'btc': 0, ... }
  },
  methods: {
    changeCurrencyBalance: function(e) {
      this.$emit('changeCurrencyBalance', e.target.value)
    },
    changeCurrencyPlay: function(e) {
      this.$emit('changeCurrencyPlay', e.target.value)
    }
  }
}
</script>


<template>
  <div class="casino-card" :class="{block: countryBlock}">
    <div class="casino-card-image">
      <GameImage
        :file-name="`/static/games/${provider}/${name}.png`"
        :game-name="title"
        width="102"
        height="102"
        class="casino-card-image-img"
      />
    </div>

    <!-- Game unavailable in your region -->
    <div v-if="countryBlock" class="casino-country-block">
      <div>{{ $t('casino.region') }}</div>
    </div>


    <div v-if="!countryBlock" class="game-card-content">

      <div class="casino-card-title">
        <div class="casino-provider">{{ provider_name }}</div>
        <div class="casino-game-name">{{ title }}</div>
      </div>

      <div class="casino-select-block">

        <div v-if="isAuth" class="casino-currency">
          <label for="selectCurrency">{{ $t('casino.balance') }}</label>
          <select id="selectCurrency" @change="changeCurrencyBalance($event)">
            <option
                v-for="(balance, bKey) in getBalance"
                :key="bKey"
                :selected="currencyBalance === bKey"
                :value="bKey"
            >
              {{ bKey.toUpperCase() }}
            </option>
          </select>
        </div>

        <div v-if="isAuth" class="casino-currency">
          <label for="selectCurrencyPlay">{{ $t('casino.in_game') }}</label>
          <select id="selectCurrencyPlay" @change="changeCurrencyPlay($event)">
            <option
                v-for="(currency, cKey) in currencyList"
                :key="cKey"
                :selected="currencyPlay === currency"
                :value="currency"
            >
              {{ currency.toUpperCase() }}
            </option>
          </select>
        </div>

        <div class="casino-buttons" :class="{live: !demo}">
          <localized-link
              v-if="demo"
              :to="{name: 'CasinoGameDemo', params: {provider, name}}"
              class="casino-button-demo">
            {{ $t('casino.demo') }}
          </localized-link>

          <localized-link
              v-if="isAuth"
              :to="{
                name: 'CasinoGame',
                params: {
                  provider,
                  name,
                  currency: currencyBalance,
                  currencyPlay: currencyPlay
                }}"
              class="casino-button-real"
          >
            <font-awesome-icon icon="bolt" />
            {{ $t('casino.real') }}
          </localized-link>
        </div>
      </div>

    </div>
  </div>
</template>


<style lang="scss" scoped>
  .casino-card {
    --card-width: 185px;
    --scale-on-hover: 1.05;

    @include media-xxs() {
      --card-width: 168px;
    }
  }


  .casino-card {
    transition: .3s ease;
    width: var(--card-width);
    position: relative;
    box-sizing: border-box;

    .casino-card-image {
      transition: .3s ease;
      display: flex;
      height: var(--card-width);
      width: var(--card-width);
      border-radius: 8px;
      background-color: var(--dark5);

      &:before {
        display: block;
        position: absolute;
        content: ' ';
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background:  url("../../../assets/logo_.svg") no-repeat 50% 50%;
        background-size: 50px;
        filter: grayscale(1);
        opacity: .1;
        pointer-events: none;
      }
      > img {
        border-radius: 8px;
        z-index: 1;
      }
    }

    .casino-card-image-img {
      object-fit: cover;
      width: 100%;
    }

    .casino-country-block {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(21, 25, 22, 0.6);
      border-radius: 8px;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      font-weight: bold;
      padding: 10px;
    }

    .game-card-content {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      top: 50%;
      bottom: 0;
      left: 50%;
      right: 0;
      z-index: 2;
      transition: .3s ease;
      transform: translate(-50%, -50%);
      text-align: left;
      color: var(--white);
      background-color: rgba(56, 57, 71, 0.9);
      border-radius: 8px;
      padding: 10px;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;

      .casino-card-title {
        text-align: center;
        border-bottom: 2px solid var(--dark3);
        height: 57px;
        display: flex;
        flex-direction: column;
        .casino-provider {
          font-size: 12px;
          text-transform: capitalize;
        }

        .casino-game-name {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          font-size: 16px;
          line-height: 1em;
        }
      }
      .casino-select-block {
        font-size: 12px;
        padding-top: 10px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .casino-currency {
          display: flex;
          justify-content: space-between;
          margin-bottom: 5px;
          > label {
            margin-left: 3px;
            font-size: 14px;
          }
          > select {
            width: 75px;
            padding: 0 5px;
            font-weight: bold;
            font-size: 14px;

            background-color: var(--dark6);
            color: var(--white);
            border: 0;
            outline: 0;
            border-radius: 4px;

            margin: 0;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-appearance: none;
            -moz-appearance: none;

            background-image:
                linear-gradient(45deg, transparent 50%, #707380 50%),
                linear-gradient(135deg, #707380 50%, transparent 50%),
                linear-gradient(to right, #292A33, #292A33);
            background-position: calc(100% - 10px) calc(0.5em + 2px), calc(100% - 5px) calc(0.5em + 2px), calc(100% - 2.5em) 0.5em;
            background-size: 5px 5px, 5px 5px, 1px 1.5em;
            background-repeat: no-repeat;
          }
        }

        .casino-buttons {
          margin-top: auto;
          display: flex;
          justify-content: space-between;
          > a {
            width: calc(50% - 10px);
            text-align: center;
            font-weight: bold;
            border-radius: 4px;
            padding: 3px 0;
            color: var(--white);
            border: 1px solid var(--gold);
            transition: .3s ease;
            display: block;
            margin-top: 4px;
          }
          .casino-button-demo {
            text-transform: uppercase;
            &:hover {
              border-color: var(--primary);
            }
          }
          .casino-button-real {
            text-transform: uppercase;
            background-color: var(--primary);
            border-color: var(--primary-light);
            > svg {
              margin-right: 5px;
            }
            &:hover {
              background-color: var(--primary-light);
            }
          }
          &.live {
            justify-content: flex-end;
          }
        }
      }
    }

    &:hover {
      transform: scale(var(--scale-on-hover));

      z-index: 2;
      .game-card-content {
        visibility: visible;
        opacity: 1;
      }
    }

    &.block {
      &:hover {
        transform: none;
        z-index: unset;
        .game-card-content {
          visibility: hidden;
          opacity: 0;
        }
      }
    }

    @include media-xxs() {
      width: var(--card-width);

      .game-card-content {
        padding: 0 5px 5px 5px;
        .casino-card-title {
          height: 40px;
          .casino-provider {
            font-size: 10px;
          }
          .casino-game-name {
            font-size: 12px;
          }
        }
        .casino-select-block {
          padding-top: 5px;
        }
      }
    }
  }
</style>
