<template>
  <div id="wrapD3Cube">

      <div id="D3Cube">
        <!--
        <div id="side1center" :style="centerStyle" class="main-back-color">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110 150" enable-background="new 0 0 110 150">
            <path d="M0,16 Q0,0,16,0 H94 Q110,0,110,16 V134 Q110,150,94,150 H16 Q0,150,0,134"  fill="#121212"></path>
          </svg>
        </div>
        <div id="side2center" :style="centerStyle" class="main-back-color">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110 150" enable-background="new 0 0 110 150">
            <path d="M0,16 Q0,0,16,0 H94 Q110,0,110,16 V134 Q110,150,94,150 H16 Q0,150,0,134"  fill="#121212"></path>
          </svg>
        </div>
        <div id="side3center" :style="centerStyle" class="main-back-color">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110 150" enable-background="new 0 0 110 150">
            <path d="M0,16 Q0,0,16,0 H94 Q110,0,110,16 V134 Q110,150,94,150 H16 Q0,150,0,134"  fill="#121212"></path>
          </svg>
        </div>
        <div id="side4center" :style="centerStyle" class="main-back-color">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110 150" enable-background="new 0 0 110 150">
            <path d="M0,16 Q0,0,16,0 H94 Q110,0,110,16 V134 Q110,150,94,150 H16 Q0,150,0,134"  fill="#121212"></path>
          </svg>
        </div>
        <div id="side5center" :style="centerStyle2" class="main-back-color">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 110" enable-background="new 0 0 150 110">
            <path d="M0,16 Q0,0,16,0 H134 Q150,0,150,16 V94 Q150,110,134,110 H16 Q0,110,0,94"  fill="#121212"></path>
          </svg>
        </div>
        <div id="side6center" :style="centerStyle2" class="main-back-color">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 110" enable-background="new 0 0 150 110">
            <path d="M0,16 Q0,0,16,0 H134 Q150,0,150,16 V94 Q150,110,134,110 H16 Q0,110,0,94"  fill="#121212"></path>
          </svg>
        </div>
        -->

        <div id="side1center" :style="centerStyle" class="main-back-color"></div>
        <div id="side2center" :style="centerStyle" class="main-back-color"></div>
        <div id="side3center" :style="centerStyle" class="main-back-color"></div>
        <div id="side4center" :style="centerStyle" class="main-back-color"></div>
        <div id="side5center" :style="centerStyle2" class="main-back-color"></div>
        <div id="side6center" :style="centerStyle2" class="main-back-color"></div>


        <div id="side1back" class="main-back-color"></div>
        <div id="side1">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" enable-background="new 0 0 512 512">
              <linearGradient :id="gradSvgId" x1="0" x2="0" y1="0" y2="512" gradientUnits="userSpaceOnUse">
                <stop offset="20%"  stop-color="#ff9c47"/>
                <stop offset="80%" stop-color="#ffdd47"/>
              </linearGradient>
              <rect x="0" y="0" width="512" height="512" :fill="'url(#' + gradSvgId + ')'"></rect>
              <path d="M83 433v-43.5h43.5V433H83zm52.2 0v-43.5h43.5V433h-43.5zm52.2 0v-43.5h43.5V433h-43.5zm52.2 0v-43.5h43.5V433h-43.5zm52.2 0v-43.5h43.5V433h-43.5zm52.2 0v-43.5h43.5V433H344zM83 380.8v-43.5h43.5v43.5H83zm52.2 0v-43.5h43.5v43.5h-43.5zm52.2 0v-43.5h43.5v43.5h-43.5zm52.2 0v-43.5h43.5v43.5h-43.5zm52.2 0v-43.5h43.5v43.5h-43.5zm52.2 0v-43.5h43.5v43.5H344zm52.2 0v-43.5h43.5v43.5h-43.5zm0-52.2v-43.5h43.5v43.5h-43.5zm-208.8-52.2v-43.5h43.5v43.5h-43.5zm52.2 0v-43.5h43.5v43.5h-43.5zm52.2 0v-43.5h43.5v43.5h-43.5zm52.2 0v-43.5h43.5v43.5H344zm52.2-52.2v-43.5h43.5v43.5h-43.5zM83 172v-43.5h43.5V172H83zm52.2 0v-43.5h43.5V172h-43.5zm52.2 0v-43.5h43.5V172h-43.5zm52.2 0v-43.5h43.5V172h-43.5zm52.2 0v-43.5h43.5V172h-43.5zm52.2 0v-43.5h43.5V172H344zm52.2 0v-43.5h43.5V172h-43.5zM83 119.8V76.3h43.5v43.5H83zm52.2 0V76.3h43.5v43.5h-43.5zm52.2 0V76.3h43.5v43.5h-43.5zm52.2 0V76.3h43.5v43.5h-43.5zm52.2 0V76.3h43.5v43.5h-43.5zm52.2 0V76.3h43.5v43.5H344z"/>
          </svg>
          </span>
        </div>
        <div id="side2back" class="main-back-color"></div>
        <div id="side2">
          <span class="dice-new">
            <svg width="100" height="100" version="1.1" viewBox="0 0 100 100" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <rect x="0" y="0" width="100" height="100" fill="#FFFFFF"></rect>
              <circle cx="50%" cy="50%" r="10" fill="#000000"></circle>
            </svg>
          </span>
        </div>
        <div id="side3back" class="main-back-color"></div>
        <div id="side3">
          <span class="dice-new">
            <svg width="100" height="100" version="1.1" viewBox="0 0 100 100" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <rect x="0" y="0" width="100" height="100" fill="#06FF00"></rect>
              <circle cx="25%" cy="25%" r="10" fill="#000000"></circle>
              <circle cx="75%" cy="75%" r="10" fill="#000000"></circle>
            </svg>
          </span>
        </div>
        <div id="side4back" class="main-back-color"></div>
        <div id="side4">
          <span class="dice-new">
            <svg width="100" height="100" version="1.1" viewBox="0 0 100 100" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <rect x="0" y="0" width="100" height="100" fill="#f2f215"></rect>
              <circle cx="25%" cy="25%" r="10" fill="#000000"></circle>
              <circle cx="25%" cy="50%" r="10" fill="#000000"></circle>
              <circle cx="25%" cy="75%" r="10" fill="#000000"></circle>
              <circle cx="75%" cy="25%" r="10" fill="#000000"></circle>
              <circle cx="75%" cy="50%" r="10" fill="#000000"></circle>
              <circle cx="75%" cy="75%" r="10" fill="#000000"></circle>
            </svg>
          </span>
        </div>
        <div id="side5back" class="main-back-color"></div>
        <div id="side5">
          <span class="dice-new">
            <svg width="100" height="100" version="1.1" viewBox="0 0 100 100" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <rect x="0" y="0" width="100" height="100" fill="#00aaff"></rect>
              <circle cx="25%" cy="25%" r="10" fill="#000000"></circle>
              <circle cx="50%" cy="50%" r="10" fill="#000000"></circle>
              <circle cx="25%" cy="75%" r="10" fill="#000000"></circle>
              <circle cx="75%" cy="25%" r="10" fill="#000000"></circle>
              <circle cx="75%" cy="75%" r="10" fill="#000000"></circle>
            </svg>
          </span>
        </div>
        <div id="side6back" class="main-back-color"></div>
        <div id="side6">
          <span class="dice-new">
            <svg width="100" height="100" version="1.1" viewBox="0 0 100 100" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <rect x="0" y="0" width="100" height="100" fill="#ed3030"></rect>
              <circle cx="25%" cy="25%" r="10" fill="#000000"></circle>
              <circle cx="25%" cy="75%" r="10" fill="#000000"></circle>
              <circle cx="75%" cy="25%" r="10" fill="#000000"></circle>
              <circle cx="75%" cy="75%" r="10" fill="#000000"></circle>
            </svg>
          </span>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  data () {
    return {
      centerStyle: {width: '110px', height: '150px', top: '-25.5px', left: '-5px'},
      centerStyle2: {width: '150px', height: '110px', top: '-5px', left: '-25.5px'},
      gradSvgIdData: 0
    }
  },
  computed: {
    gradSvgId: function () {
      if (this.gradSvgIdData === 0) {
        this.setSvgId()
      }
      return 'gradSvg' + this.gradSvgIdData
    }
  },
  methods: {
    setSvgId: function () {
      this.gradSvgIdData = this.getRandomInt(11111111, 999999999)
    },
    getRandomInt: function (min, max) {
      //return 1111111
      return Math.floor(Math.random() * (max - min + 1)) + min
    }
  }
}
</script>

<style scoped>
  #wrapD3Cube{
    margin: 40px 0 40px 0;
  }
  #D3Cube {
    width: 100px;
    height: 100px;


    transform: rotateX(-22deg) rotateY(-38deg) rotateZ(0deg) translate3d(0, 0, 0);
    -moz-transform: rotateX(-22deg) rotateY(-38deg) rotateZ(0deg);
    -webkit-transform: rotateX(-22deg) rotateY(-38deg) rotateZ(0deg);

    margin: auto;
    position: relative;

    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition: all 0.55s ease-in-out;
    transition: all 0.55s ease-in-out;

  }
  #D3Cube > div {
    position: absolute;
    width: 100px;
    height: 100px;
    float: left;
    overflow: hidden;
    border-radius: 16px;
  }

  #D3Cube > div > span.dice-new {
    display: block;
  }

  .main-back-color {
    background-color: #121212;
  }



  #side1center{
    transform: rotateX(45deg);
    -moz-transform: rotateX(45deg);
    -webkit-transform:  rotateX(45deg);
  }
  #side2center{
    transform: rotateX(135deg);
    -moz-transform: rotateX(135deg);
    -webkit-transform:  rotateX(135deg);
  }
  #side3center{
    transform: rotateY(90deg) rotateX(45deg);
    -moz-transform: rotateY(90deg) rotateX(45deg);
    -webkit-transform:  rotateY(90deg) rotateX(45deg);
  }
  #side4center{
    transform: rotateY(90deg) rotateX(135deg);
    -moz-transform: rotateY(90deg) rotateX(135deg);
    -webkit-transform:  rotateY(90deg) rotateX(135deg);
  }
  #side5center{
    transform: rotateY(45deg);
    -moz-transform: rotateY(45deg);
    -webkit-transform:  rotateY(45deg);
  }
  #side6center{
    transform: rotateY(135deg);
    -moz-transform: rotateY(135deg);
    -webkit-transform:  rotateY(135deg);
  }

  #side1 {
    transform: rotateX(90deg) translateX(0px) translateY(0px) translateZ(56px);
    -moz-transform: rotateX(90deg) translateX(0px) translateY(0px) translateZ(56px);
    -webkit-transform:  rotateX(90deg) translateX(0px) translateY(0px) translateZ(56px);
  }
  #side1back{
    transform: rotateX(90deg) translateX(0px) translateY(0px) translateZ(55px);
    -moz-transform: rotateX(90deg) translateX(0px) translateY(0px) translateZ(55px);
    -webkit-transform:  rotateX(90deg) translateX(0px) translateY(0px) translateZ(55px);
  }

  #side2 {
    transform: rotateY(-90deg) translateX(0px) translateY(0px) translateZ(56px);
    -moz-transform: rotateY(-90deg) translateX(0px) translateY(0px) translateZ(56px);
    -webkit-transform: rotateY(-90deg) translateX(0px) translateY(0px) translateZ(56px);
    color: #FFFFFF;
  }
  #side2back{
    transform: rotateY(-90deg) translateX(0px) translateY(0px) translateZ(55px);
    -moz-transform: rotateY(-90deg) translateX(0px) translateY(0px) translateZ(55px);
    -webkit-transform: rotateY(-90deg) translateX(0px) translateY(0px) translateZ(55px);
  }

  #side3 {
    transform: translateX(0px) translateY(0px) translateZ(56px);
    -moz-transform: translateX(0px) translateY(0px) translateZ(56px);
    -webkit-transform: translateX(0px) translateY(0px) translateZ(56px);
    /*background-color: #06ff00;*/
    color: #06ff00;
  }
  #side3back{
    transform: translateX(0px) translateY(0px) translateZ(55px);
    -moz-transform: translateX(0px) translateY(0px) translateZ(55px);
    -webkit-transform: translateX(0px) translateY(0px) translateZ(55px);
  }

  #side4 {
    transform: rotateY(90deg) translateX(0px) translateY(0px) translateZ(56px);
    -moz-transform: rotateY(90deg) translateX(0px) translateY(0px) translateZ(56px);
    -webkit-transform: rotateY(90deg) translateX(0px) translateY(0px) translateZ(56px);
    color: #ed3030;
  }
  #side4back{
    transform: rotateY(90deg) translateX(0px) translateY(0px) translateZ(55px);
    -moz-transform: rotateY(90deg) translateX(0px) translateY(0px) translateZ(55px);
    -webkit-transform: rotateY(90deg) translateX(0px) translateY(0px) translateZ(55px);
  }
  #side5 {
    transform: rotateY(180deg) translateX(0px) translateY(0px) translateZ(56px);
    -moz-transform: rotateY(180deg) translateX(0px) translateY(0px) translateZ(56px);
    -webkit-transform: rotateY(180deg) translateX(0px) translateY(0px) translateZ(56px);
    color: #00aaff;
  }
  #side5back{
    transform: rotateY(180deg) translateX(0px) translateY(0px) translateZ(55px);
    -moz-transform: rotateY(180deg) translateX(0px) translateY(0px) translateZ(55px);
    -webkit-transform: rotateY(180deg) translateX(0px) translateY(0px) translateZ(55px);
  }
  #side6 {
    transform: rotateX(-90deg) translateX(0px) translateY(0px) translateZ(56px);
    -moz-transform: rotateX(-90deg) translateX(0px) translateY(0px) translateZ(56px);
    -webkit-transform: rotateX(-90deg) translateX(0px) translateY(0px) translateZ(56px);
    color: #f2f215;
  }
  #side6back{
    transform: rotateX(-90deg) translateX(0px) translateY(0px) translateZ(55px);
    -moz-transform: rotateX(-90deg) translateX(0px) translateY(0px) translateZ(55px);
    -webkit-transform: rotateX(-90deg) translateX(0px) translateY(0px) translateZ(55px);
  }

  #D3Cube {


    -moz-animation: drift 3s infinite ease;
    -webkit-animation: drift 3s infinite ease;
    animation: drift 3s infinite ease;


  }

  @keyframes drift {
    0% {
      transform: rotateX(22deg) rotateY(38deg);
      -moz-transform: rotateX(22deg) rotateY(38deg);
      -webkit-transform: rotateX(22deg) rotateY(38deg);
    }
    50% {
      transform: rotateX(322deg) rotateY(338deg);
      -moz-transform: rotateX(322deg) rotateY(338deg);
      -webkit-transform: rotateX(322deg) rotateY(338deg);
    }
    100% {
      transform: rotateX(22deg) rotateY(38deg);
      -moz-transform: rotateX(22deg) rotateY(38deg);
      -webkit-transform: rotateX(22deg) rotateY(38deg);
    }
  }
</style>
