<template>
  <div class="chat-avatar" :class="levelName" :style="{width: sizeText, height: sizeText}">
    <div v-if="isOnline === true" class="avatar-online tooltip" :class="{online: online, 'online-gift': gift}"  :style="{width: sizeOnline, height: sizeOnline, 'border-radius': sizeOnline}" :data-tooltip="online ? 'online' : 'offline'"><div :style="{'border-radius': radiusOnline}"></div></div>
    <img class="avatar-image" v-if="avatar !== ''" :src="$filters.printAvatar(avatar)" :style="{'border-radius': sizeText}" alt="" />

    <div v-if="progress" class="chat-avatar__progress-bar">
      <AvatarProgressBar :value="getWidthBar(userXP)" class="chat-avatar__progress-bar-svg" :class="levelName" />
    </div>

    <div v-if="decoration" class="avatar-decoration">
      <img :src="getShopAvatarImage(decoration)" alt="" />
    </div>

    <div v-if="gift" class="avatar-gift" :style="{width: heightLevel, height: heightLevel, bottom: offsetLevel, left: offsetLevel}">
      <img :src="getShopGiftImage(gift)" alt="" />
    </div>

    <UserLevel
        v-if="showLevel"
        :width="widthLevel"
        :height="heightLevel"
        :levelName="levelName"
        :leveNumber="getLevel(xp)"
        :style="{ bottom: offsetLevel, right: offsetLevel}"
        class="level"
    />
  </div>
</template>

<script>
import vip from "../vip/vip"
import AvatarProgressBar from "@/components/user_redesign/AvatarProgressBar.vue";
import UserLevel from "@/components/user_redesign/UserLevel.vue";

  export default {
    name: "Avatar",
    props: {
      size: {type: [Number, String], default: 48},
      avatar: {type: String},
      xp: {type: [String, Number], default: 0},
      online: Boolean,
      isOnline: {type: Boolean, default: false},
      decoration: {type: String},
      gift: {type: String},
      progress: {type: Boolean, default: false},
      showLevel: {type: Boolean, default: true},
    },
    components: {
      AvatarProgressBar,
      UserLevel,
    },
    mixins: [vip],
    data() {
      return {
        levelName: null,
      }
    },
    created() {
      this.levelName = this.getLevelName(this.xp).toLowerCase()
    },
    computed: {
      userXP: function() {
        return this.$store.getters.getAuthData.xp
      },
      sizeText: function() {
        return this.size.toString() + 'px'
      },
      widthLevel: function() {
        return (18 / 50 * this.size) + 'px'
      },
      heightLevel: function() {
        return (20 / 50 * this.size) + 'px'
      },
      offsetLevel: function() {
        return (-3 / 50 * this.size) + 'px'
      },
      sizeOnline: function() {
        return (10 / 50 * this.size) + 'px'
      },
      radiusOnline: function() {
        return (10 / 50 * this.size) + 'px'
      }
    },
    methods: {
      getShopAvatarImage: function(name) {
        return new URL(`/src/assets/vip/shop_avatar_${name}.svg`, import.meta.url).href
      },
      getShopGiftImage: function(name) {
        return new URL(`../../assets/vip/shop_gift_${name}.svg`, import.meta.url).href
      },
    }
  }
</script>

<style lang="scss" scoped>
  .chat-avatar__progress-bar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100% + 5%);
    width: calc(100% + 5%);
  }

  .chat-avatar__progress-bar-svg {
    height: 100%;
    width: 100%;
  }

  .level {
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;
  }
  .chat-avatar {
    .avatar-image {
      border: 3px solid transparent;
    }
    .a { fill: #393939; }
    .b { fill: #ffffff; }
    .c { fill: url(#gradient-newbie); }
  }

  .chat-avatar {
    margin: 0 auto;
    background: url('../../assets/avatar_default.svg') no-repeat;
    position: relative;

    .avatar-image {
      z-index: 1;
      position: relative;
      max-height: unset;
    }
    .avatar-decoration {
      z-index: 2;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      > img {
        height: 100%;
        width: 100%;
        max-height: 100% !important;
      }
    }
    .avatar-gift {
      z-index: 3;
      position: absolute;
      display: flex;
      > img {
        height: 100%;
        width: 100%;
        max-height: 100% !important;
      }
    }
    .chat-text-nick{
      cursor: pointer;
    }

    .avatar-online {
      position: absolute;
      bottom: 0;
      left: 8%;
      content: ' ';
      border: 2px solid #28292d;
      z-index: 10;
      &.online-gift {
        left: 22%;
        bottom: -5%;
      }
    }

    .avatar-online > div {
      background-color: red;
      height: 100%;
      width: 100%;
    }

    .avatar-online.online > div {
      background-color: #8bbb4c;

      animation-name: pulse_online;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: ease;
    }

    @keyframes pulse_online {
      from {
        box-shadow: 0 0 10px 0 #a3d751;
      }
      to {
        box-shadow: 0 0 0 0 #a3d751;
      }
    }

    .avatar-background {
      position: absolute;
      top: -5px;
      left: -5px;
      z-index: 0;
      width: 55px;
      height: 55px;
      > img {
        width: 55px;
        height: 55px;
      }
    }
  }
</style>
