<script>
import SectionSlider from '@/components/pages/home_redesign/SectionSlider.vue'
import GameCard from "@/components/casino_redesign/game-card/GameCard.vue"
import { GAME_CATEGORY_ICONS } from '@/constants/game-category-icons.ts'

export default {
  name: "Games",
  components: {
    SectionSlider,
    GameCard,
  },
  props: {
    games: { type: Object }
  },
  data() {
    return {
      GAME_CATEGORY_ICONS,
    }
  },
}
</script>

<template>
  <SectionSlider
      v-for="(gameCategory, categoryKey) in games" :key="categoryKey"
      :viewAllLink="{name: 'CasinoCategory', params: {category: gameCategory.category}}"
      :list="gameCategory.list"
      class="games"
  >
    <template #title>
      <span>
        {{ GAME_CATEGORY_ICONS[gameCategory.category] }}
        {{ $t(`casino.category.${gameCategory.category}`) }}
      </span>
    </template>
    <template #default="{ item }">
      <GameCard :game="item" class="games__card" />
    </template>

  </SectionSlider>
</template>

<style lang="scss" scoped>
$sm-breakpoint: 1087px;

.games {
  $padding: 12px;

  & + & {
    margin-top: 20px - $padding;
  }

  &:deep {
    .swiper-slide {
      padding: $padding;
    }
  }
}
</style>
