<script>
export default {
  name: "GameSort",
  props: {
    hideText: { type: Boolean }
  },
  computed: {
    sorting: {
      get: function () {
        return this.$store.getters.getSorting
      },
      set: function (value) {
        this.$store.commit('setSorting', value)
      }
    }
  },
  methods: {
    changeSorting: function(type) {
      this.sorting = type
      this.$emit('sorting', type)
    }
  }
}
</script>


<template>
  <div class="casino-search-top">
    <div v-if="!hideText" class="casino-search-left">
      <div class="search__result-title">{{ $t('casino.search_results') }}</div>
    </div>
    <div class="casino-search-right">
      <div>{{ $t('casino.search_sort') }}</div>
      <div><a @click="changeSorting('az')" :class="{active: sorting === 'az'}">A-Z</a></div>
      <div><a @click="changeSorting('za')" :class="{active: sorting === 'za'}">Z-A</a></div>
      <div><a @click="changeSorting('popular')" :class="{active: sorting === 'popular'}">{{ $t(`casino.category.popular`) }}</a></div>
      <div><a @click="changeSorting('new')" :class="{active: sorting === 'new'}">{{ $t(`casino.category.new`) }}</a></div>
    </div>
  </div>
</template>


<style lang="scss" scoped>
  .casino-search-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    font-weight: bold;
    color: var(--gray4);

    .casino-search-right {
      display: flex;
      margin-bottom: 12px;
      > div {
        margin-left: 10px;
      }

      a {
        color: rgba(#3F7FFA, 0.6);
        &:hover, &.active {
          color: var(--primary);
        }
      }
    }
    @include media-md() {
      .casino-search-left {
        display: none;
      }
      .casino-search-right {
        margin-bottom: 20px;
        > div {
          &:first-of-type {
            margin-left: 0;
          }
        }
      }
    }
  }
</style>
