const top = 'TOP'
const latest = 'LATEST'

const tabs = {
  top,
  latest
}

const defaultActiveTab = top

export {
  tabs,
  defaultActiveTab
}
