<template>
  <div v-if="!loaderRandom && !isMobile && gamesRandom.length > 0" class="game-random-box">
    <transition-group tag="div" name="game-random-row" mode="ease-in-out">
      <div
              v-for="game in gamesRandom"
              :key="game.key"
              @click="openRandomGame(game.provider, game.identifier, game.currency)"
              class="game-random"
              :class="{block: game.blacklist.includes($store.getters.getCountry)}"
      >

        <div class="game-random-icon">

          <CasinoGameImage
              :file-name="`/static/games/${game.provider}/${game.identifier}.png`"
              :game-name="game.title"
          />

          <div v-if="game.blacklist.includes($store.getters.getCountry)" class="game-country-block">
            <div>Game unavailable in your region</div>
          </div>
        </div>
        <div class="game-random-name">{{ game.title }}</div>

      </div>
    </transition-group>
  </div>
</template>

<script>
import CasinoGameImage from "@/components/casino/CasinoGameImage.vue"
export default {
  name: "GameRandomList",
  components: {CasinoGameImage},
  props: {
    gameType: {type: String, default: 'real'},
    currencySelect: {type: String, default: ''},
    currencyPlay: {type: String, default: ''},
  },
  data() {
    return {
      loaderRandom: true,
      gamesRandom: [],
      gamesRandomInterval: null,
    }
  },
  beforeUnmount() {
    clearInterval(this.gamesRandomInterval)
  },
  created() {
    this.loadRandom()
  },
  computed: {
    isMobile: function() {
      return this.$store.getters.getDeviceType === 'mobile'
    },
  },
  methods: {
    loadRandom: function() {
      this.loaderRandom = true

      this.axios.get(`/casino/list/random/${this.gameType}/8`)
          .then(response => {
            if (response.data.status === "success") {
              const key = new Date().getTime()
              this.gamesRandom = response.data.data.map(el => ({
                ...el, key: `${el.identifier}_${key}`
              }))

              if (this.gamesRandom.length > 0) {
                this.loaderRandom = false
                this.gamesRandomInterval = setInterval(() => {
                  this.loadRandomNew()
                }, 8000)
              }
            } else {
              this.$store.dispatch('addNotif', {type: 'danger', text: response.data.message, isClose: false, timer: 2000}).then()
            }
          })
    },
    loadRandomNew: function() {

      this.axios.get(`/casino/list/random/${this.gameType}`)
          .then(response => {
            console.log(response.data)
            if (response.data.status === "success") {
              console.log(response.data.data)

              const key = new Date().getTime()
              const game = response.data.data[0]
              game.key = `${game.identifier}_${key}`
              this.gamesRandom.unshift(game)
              this.gamesRandom.pop()

            } else {
              this.$store.dispatch('addNotif', {type: 'danger', text: response.data.message, isClose: false, timer: 2000}).then()
            }
          })
    },
    openRandomGame: function(provider, name, currency) {
      if (this.gameType === 'demo') {
        this.$router.replace({name: 'CasinoGameDemo', params: {provider: provider, name: name}})
      } else {
        let playCurrency = this.currencyPlay
        if (!currency.includes(playCurrency)) {
          playCurrency = currency[0]
        }

        this.$router.replace({name: 'CasinoGame', params: {provider: provider, name: name, currency: this.currencySelect, currencyPlay: playCurrency}})
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .game-random-box {
    --card-margin-x: 5px;

    margin: 20px auto 0 auto;
    padding: 0 5px;
    background-color: var(--dark5);
    border-radius: 8px;
    width: 100%;
    max-width: 100%;
    position: relative;
    overflow: hidden;

    @include media-lg() {
      padding-left: calc(var(--mobile-content-padding) - var(--card-margin-x));
      padding-right: 0;
      border-radius: 0;
    }

    .game-random-row-move {
      transition: transform 300ms ease-in-out;
    }

    > div {
      display: flex;
      overflow-y: hidden;
      overflow-x: hidden;
      white-space: nowrap;
      scrollbar-width: none;
    }

    .game-random {
      margin: 15px var(--card-margin-x) 3px var(--card-margin-x);
      cursor: pointer;
      max-width: 185px;

      .game-random-icon {
        display: flex;
        transition: .3s ease;
        position: relative;
        > img {
          border-radius: 8px;
          height: 185px;
          min-width: 185px;
          box-shadow: 0 0 5px 5px rgba(17, 36, 53, 0.5);
        }
        .game-country-block {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: rgba(21, 25, 22, 0.6);
          border-radius: 8px;
          z-index: 2;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          font-weight: bold;
          padding: 10px;
          white-space: normal;
        }
        &:after {
          content: ' ';
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          position: absolute;
          background: radial-gradient( circle, transparent 60%, black 150%);
          border-radius: 8px;
          z-index: 1;
          pointer-events: none;
        }
      }
      .game-random-name {
        height: 24px;
        font-size: 12px;
        line-height: 12px;
        font-weight: bold;
        white-space: normal;

        display: inline-flex;
        align-items: center;
      }
      &:hover {
        .game-random-icon {
          transform: scale(1.1);
        }
      }
      &.block {
        &:hover {
          .game-random-icon {
            transform: none;
          }
        }
      }
    }

    &:before, &:after {
      content: ' ';
      position: absolute;
      top: 10px;
      bottom: 10px;
      width: 10px;
      z-index: 30;
    }
    &:before {
      left: 5px;
      background-image: linear-gradient(to left, rgba(0, 0, 0, 0), var(--dark5));
    }
    &:after {
      right: 5px;
      background-image: linear-gradient(to left, var(--dark5), rgba(0, 0, 0, 0));
    }
  }
</style>
