<template>
 <div class="maintenance">
   <article>
     <img src="./../assets/logo_.svg">
     <h1>We&rsquo;ll be back soon!</h1>
     <div>
       <p>Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment. If you need to you can always <a href="mailto:admin@windice.io">contact us</a>, otherwise we&rsquo;ll be back online shortly!</p>
       <p>&mdash; WINDICE.io</p>
     </div>
   </article>
 </div>
</template>

<script>
  export default {
    name: "Maintenance"
  }
</script>

<style scoped>
  .maintenance {
    text-align: center;
    background-color: #222222;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h16v2h-6v6h6v8H8v-6H2v6H0V0zm4 4h2v2H4V4zm8 8h2v2h-2v-2zm-8 0h2v2H4v-2zm8-8h2v2h-2V4z' fill='%23000000' fill-opacity='0.41' fill-rule='evenodd'/%3E%3C/svg%3E");
    font-size: 18px;
    color: #333;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .maintenance img {
    width: 128px;
    height: 128px;
    display: block;
    margin: -40vh auto 20px auto;
  }
  .maintenance h1 { font-size: 50px; }
  .maintenance article { color: #FFFC; display: block; text-align: left; margin: 0 auto; padding: 20px; max-width: 640px;}
  .maintenance a { color: #dc8100; text-decoration: none; }
  .maintenance a:hover { color: #333; text-decoration: none; }

  @media (max-width: 767px) {
    .maintenance { font-size: 16px; }
    .maintenance h1 { font-size: 30px; }
  }
</style>
